/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RideList from "../../components/RideList";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { searchRides } from "../../redux/actions";
import SearchForm from "../../components/SearchForm";
import NoData from "../../components/NoData";
import moment from "moment";
import { COLORS } from "../../assets/colors";
import { GlobalContext } from "../../contexts/globalContext";
import Filters from "../../components/Filters";
import { FilterListOutlined, SearchRounded } from "@material-ui/icons";
import MyModal from "../Ask/Modal";
import FilterModal from "../../components/models/FilterModal";
import voidImg from "../../assets/images/void.svg";

export default function RideSharing() {
  const dispatch = useDispatch();
  const { searchFields, setSearchFields } = useContext(GlobalContext);
  const location = useLocation();

  const [offset, setOffset] = useState(0);
  const [departure_date, setDepartureDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [amenity, setAmenity] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const { data: rides, count, isLoading } = useSelector((state) => state.rides);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const leavingFrom = queryParams.get("leavingFrom");
    const goingTo = queryParams.get("goingTo");
    const seats = queryParams.get("seats");
    const date = queryParams.get("date");
    const searchType = queryParams.get("searchType");
    if (leavingFrom) {
      setSearchFields({
        ...searchFields,
        leavingFrom: leavingFrom,
      });
    }
    if (goingTo) {
      setSearchFields({
        ...searchFields,
        goingTo: goingTo,
      });
    }
    if (seats) {
      setSearchFields({
        ...searchFields,
        seats: seats,
      });
    }
    if (date && date != "null") {
      setSearchFields({
        ...searchFields,
        date: date,
      });
    }
    let params = { ...searchFields, offset };
    if (sortBy) {
      params = { ...params, sortBy };
    }
    if (departureTime) {
      params = { ...params, departureTime };
    }
    if (amenity) {
      params = { ...params, amenity };
    }
    if (searchType) {
      params = { ...params, searchType };
    }
    params = {
      ...params,
      originCity: searchFields.originCity,
      destinationCity: searchFields.destinationCity,
    };

    const searchParams = new URLSearchParams(params);
    const queryString = searchParams.toString();
    dispatch(searchRides(queryString));
  }, [location.search, offset, sortBy, departureTime, amenity]);

  useEffect(() => {
    let offsetNum = 5 * (currentPage - 1);
    setOffset(offsetNum);
  }, [currentPage]);

  useEffect(() => {
    if (searchFields.date && searchFields.date != "null") {
      const year = new Date(searchFields.date).getFullYear();
      const month = (new Date(searchFields.date).getUTCMonth() + 1)
        .toString()
        .padStart(2, "0");
      const day = new Date(searchFields.date).getDate();
      const depDate = month + "/" + day + "/" + year;
      setDepartureDate(depDate);
    }
  }, [searchFields.date]);

  const handleSort = (sorter) => {
    if (sorter == sortBy) {
      setSortBy(null);
    } else {
      setSortBy(sorter);
    }
  };
  const handleDeparturetime = (dptime) => {
    if (departureTime == dptime) {
      setDepartureTime(null);
    } else {
      setDepartureTime(dptime);
    }
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleAmenity = (_amenty) => {
    if (amenity == _amenty) {
      setAmenity(null);
    } else {
      setAmenity(_amenty);
    }
  };
  const clearAll = () => {
    setSortBy(null);
    setDepartureTime(null);
    setAmenity(null);
  };
  return (
    <StyledRideSharing>
      {window.innerWidth < 546 ? (
        <div className="mobile-search">
          <div className="field" onClick={() => setIsOpen(true)}>
            <span>Search...</span>
          </div>
          <div className="icon" onClick={() => setIsOpen(true)}>
            <SearchRounded fontSize="2rem" color="secondary" />
          </div>
          <IconButton onClick={() => setShowFilters(true)}>
            <FilterListOutlined />
          </IconButton>
        </div>
      ) : (
        <div
          className="search-box"
          style={{
            backgroundColor: "#054752d1",
            borderBottom: "1px solid lightgrey",
          }}
        >
          <SearchForm
            searchFields={searchFields}
            setSearchFields={setSearchFields}
            handleCloseModal={handleCloseModal}
          />
        </div>
      )}

      <div className="content">
        {window.innerWidth > 546 && (
          <div className="filter">
            <Filters
              handleSort={handleSort}
              sortBy={sortBy}
              handleDeparturetime={handleDeparturetime}
              departureTime={departureTime}
              handleAmenity={handleAmenity}
              amenity={amenity}
              clearAll={clearAll}
            />
          </div>
        )}

        {rides?.length == 0 && !isLoading ? (
          <NoRidesContainer className="no-rides">
            {
              <>
                <NoData
                  imgSrc={voidImg}
                  title={`There are no rides yet for ${moment(
                    searchFields?.date || new Date()
                  ).format("MMM Do")}`}
                />
              </>
            }
          </NoRidesContainer>
        ) : (
          <RidesListWrapper>
            <div>
              <RideList
                count={count}
                setCurrentPage={setCurrentPage}
                rides={rides}
                leavingFrom={searchFields.leavingFrom}
                goingTo={searchFields.goingTo}
                isLoading={isLoading}
                currentPage={currentPage}
              />
            </div>
          </RidesListWrapper>
        )}
      </div>
      {isOpen && <MyModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      {showFilters && (
        <FilterModal
          isOpen={showFilters}
          setIsOpen={setShowFilters}
          handleSort={handleSort}
          sortBy={sortBy}
          handleDeparturetime={handleDeparturetime}
          departureTime={departureTime}
          handleAmenity={handleAmenity}
          amenity={amenity}
          clearAll={clearAll}
        />
      )}
    </StyledRideSharing>
  );
}

const StyledRideSharing = styled.div`
  postition: relative;
  .mobile-search {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    display: flex;
    padding: 0 1rem;
    position: relative;
    .field {
      width: 100%;
      height: 100%;
      background: #c3d1cc;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 1rem;
      color: darkslategray;
      cursor: pointer;
    }
    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 18%;
      top: 15%;
      svg {
        font-size: 2rem;
        @media (max-width: 320px) {
          font-size: 1.2rem;
        }
      }
    }
  }
  .search-box {
    width: 100%;
    position: fixed;
    z-index: 1;
    background-color: rgb(239 244 245) !important;
    border-bottom: 1px solid ${COLORS.borderColor};
  }
  .content {
    display: flex;
    postition: relative;
    @media (min-width: 546px) {
      padding-top: 8rem;
    }

    justify-content: center;
    .filter {
      position: fixed;
      left: 12%;
      padding-bottom: 2rem;
      @media (min-width: 660px) and (max-width: 767px) {
        left: 0;
      }
      @media (min-width: 601px) and (max-width: 650px) {
        display: none;
      }
      @media (min-width: 1442px) {
        position: fixed;
      }
    }
  }
`;
const RidesListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  @media (min-width: 601px) and (max-width: 649px) {
    justify-content: center;
  }
  @media (min-width: 601px) and (max-width: 767px) {
    width: 100%;
    justify-content: flex-end;
  }
  @media (min-width: 768px) and (max-width: 950px) {
    width: 100%;
    justify-content: flex-end;
  }
  @media (min-width: 951px) and (max-width: 1000px) {
    width: 100%;
    justify-content: flex-end;
    padding-right: 2rem;
  }
  @media (min-width: 1001px) and (max-width: 1365px) {
    width: 100%;
    justify-content: flex-end;
    padding-right: 10rem;
  }
`;
const NoRidesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
  min-height: 10rem;
  flex-direction: column;
  align-items: center;

  p {
    font-size: larger;
    color: ${COLORS.primaryColor};
    font-weight: 600;
  }
  .locations {
    display: flex;
    align-items: center;
    color: ${COLORS.secondaryTextColor};
    span {
      svg {
        fill: ${COLORS.secondaryTextColor};
        height: 24px;
        width: 24px;
      }
    }
  }
`;
