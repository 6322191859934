import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import HeroSection from "../HeroSection";
import ServicesSection from "../ServicesSection";
import PopularRides from "../PopularRides";
import ServiceSectionDetails from "../ServiceSectionDetails";
import SilkCabsFooter from "../SilkCabsFooter";
import HelpSection from "../HelpSection";
import HowItsWork from "../../pages/HowItWork";
import ReactGA from "react-ga4";
import HowItWorksForDriver from "../../pages/HowItWorkforDriver";
import styled from "styled-components";
import mobile from "../../assets/images/mobile.png";
import { Helmet } from "react-helmet";
import FemaleOnly from "../FemaleOnly";
const Home = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
  }, []);
  return (
    <Mainwrapper>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>
          GoSawari - Carpooling Platform for Pakistan | Find & Book Rides
        </title>
        <meta
          name="title"
          content="GoSawari - Carpooling Platform for Pakistan | Find & Book Rides"
        />
        <meta
          name="description"
          content="GoSawari is a carpooling platform in Pakistan where drivers can post their future rides and passengers can book seats in advance. Save on fuel costs and find convenient travel options across cities."
        />
        <meta
          name="keywords"
          content="carpooling, ride-sharing, car rides, book rides, Pakistan, travel, GoSawari, drivers, passengers, intercity travel, save fuel"
        />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="GoSawari Team" />

        {/* Dynamic Meta Description for the City */}

        {/* Canonical Link */}
        <link rel="canonical" href={`https://gosawari.com`} />

        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gosawari.com/" />
        <meta
          property="og:title"
          content="GoSawari - Carpooling Platform for Pakistan"
        />
        <meta
          property="og:description"
          content="GoSawari is a carpooling platform in Pakistan where drivers can post their future rides and passengers can book seats in advance. Save on fuel costs and find convenient travel options across cities."
        />
        <meta
          property="og:image"
          content="https://gosawari.com/gosawari_social.jpg"
        />
        <meta property="og:site_name" content="GoSawari" />

        {/* Twitter Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://gosawari.com/" />
        <meta
          property="twitter:title"
          content="GoSawari - Carpooling Platform for Pakistan"
        />
        <meta
          property="twitter:description"
          content="GoSawari is a carpooling platform in Pakistan where drivers can post their future rides and passengers can book seats in advance."
        />
        <meta
          property="twitter:image"
          content="https://gosawari.com/gosawari_social.jpg"
        />
      </Helmet>
      <Container disableGutters maxWidth="xl">
        <Grid item>
          <HeroSection />
        </Grid>
        <Grid item>
          <ServicesSection />
        </Grid>
        <Grid item>
          <PopularRides />
        </Grid>
        {/* <Grid item>
          <NoChargesOffer />
        </Grid> */}
        <Grid item>
          <FemaleOnly />
        </Grid>
        <Grid item>
          <ServiceSectionDetails />
        </Grid>
      </Container>
      <Wrapper>
        <ContentWrapper>
          <div className="wrapper-item">
            <HowItWorksForDriver />
            <HowItsWork />
          </div>
        </ContentWrapper>
        <ContentWrapper>
          <ComingSoonSection />
        </ContentWrapper>
      </Wrapper>
      <HelpSection />
      <SilkCabsFooter />
    </Mainwrapper>
  );
};
export default Home;

const ComingSoonSection = () => {
  return (
    <ContainerStyled>
      <Title>Mobile App Coming Soon</Title>
      <Subtitle>
        We're working hard to bring you the best experience on mobile!
      </Subtitle>
      <Image
        src={mobile || "https://via.placeholder.com/300"}
        alt="Coming Soon"
      />
      <Message>Stay tuned for updates.</Message>
    </ContainerStyled>
  );
};

// Styled Components
const ContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f5f5f5;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: rgb(5, 71, 82);
  font-weight: 500;
  line-height: 1.06;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
  color: rgb(5, 71, 82);
  font-weight: 500;
  line-height: 1.06;
  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const Message = styled.p`
  font-size: 1rem;
  color: #888;
  text-align: center;
`;

const Mainwrapper = styled.div``;
const Wrapper = styled.div`
  position: relative;
  width: auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  top: 0;
  left: 0;
  max-width: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;
const MobileAppSection = styled.div`
  height: 400px;
  width: 100%;
  background: whitesmoke;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;
const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0.5, 0, 0.7);
`;
const ContentWrapper = styled.div`
  position: relative;
  /* @media (max-width: 600px) { */
  background: teal;
  /* } */
`;
const Playstore = styled.div`
  padding-left: 10rem;
  background-color: #f8f8f8;
  border: 1px solid red;
  width: 100%;
  height: 524px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5rem 0 0;

  @media (max-width: 768px) {
    display: flex;
    padding: 2rem;
  }
`;

const NoChargesOffer = () => {
  return (
    <NoChargesSection>
      <NoChargesTitle>Special Offer</NoChargesTitle>
      <NoChargesText>
        For a limited time, the GoSawari platform will charge{" "}
        <strong>nothing</strong> from drivers and passengers!
      </NoChargesText>
      <NoChargesSubText>Enjoy this benefit while it lasts!</NoChargesSubText>
    </NoChargesSection>
  );
};

// Styled Components for NoChargesOffer
const NoChargesSection = styled.div`
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  /* max-width: 500px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const NoChargesTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
  color: #00796b;
  margin-bottom: 10px;
`;

const NoChargesText = styled.p`
  font-size: 1rem;
  color: #004d40;
  margin-bottom: 10px;
`;

const NoChargesSubText = styled.p`
  font-size: 0.9rem;
  color: #004d40;
`;
