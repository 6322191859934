import React, { useState } from "react";
import CustomButton from "../UI/Button";
import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";
import otpLogo from "../../images/otp.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Otp({ verifyOpt, isLoading, otpError }) {
  const [otp, setOtp] = useState("");
  const { currentUser } = useSelector((state) => state.auth);

  const checkOtp = (e) => {
    e.preventDefault();
    localStorage.getItem("");

    if (otp.length > 3) {
      verifyOpt(otp, currentUser.email);
    }
  };

  const handleOtp = (e) => {
    setOtp(e.target.value);
  };

  return (
    <MainContainer>
      <div className="image-container">
        <div className="otp-logo">
          <img src={otpLogo} alt="logo" />
        </div>
        <h4 variant="h5" color="textPrimary">
          OTP Code
        </h4>
        <p>Enter the OTP code sent to you via SMS and email.</p>
      </div>

      <FormContainer>
        <form>
          <TextField
            id="filled-last-name-input"
            label="OTP Code"
            type="text"
            fullWidth
            autoComplete="current-last-name"
            variant="outlined"
            required
            onChange={handleOtp}
          />
          <BtnContainer>
            <CustomButton
              onClick={checkOtp}
              label={isLoading ? "Please wait" : "Verify"}
              type="submit"
              disabled={otp == "" ? true : false}
            />
          </BtnContainer>
          {otpError && otpError.length && <ErrorText>{otpError}</ErrorText>}
          <p>
            Didn't recived Otp?
            <Button component={Link} to="/get-phone-number" color="primary">
              <span>Resend Otp</span>
            </Button>
          </p>
        </form>
      </FormContainer>
    </MainContainer>
  );
}

export default Otp;
const MainContainer = styled.div`
  width: 26rem;
  max-width: 100%;
  height: 25rem;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;

  .image-container {
    h4 {
      font-size: 22px;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 18px;
      color: gray;
      text-align: center;
    }
    .otp-logo {
      display: flex;
      justify-content: center;
      img {
        width: 12rem;
        height: 10rem;
      }
    }
  }
`;

const FormContainer = styled.div`
  padding-top: 1rem;
  border-radius: 6px;
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: center;
  outline: 0;
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  > form > .MuiTextField-root {
    text-align: center;
    color: white;
  }
`;

const BtnContainer = styled.div``;
const ErrorText = styled.p`
  color: red;
  margin-top: 15px;
`;
