export default {
  api: {
    url: "http://localhost:5000/api/v1/",
    baseUrl: "http://localhost:5000",
  },
  featureFlags: {
    freeLaunch: true,
  },
  env: "development",
  log: "debug",
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_TEST,
  fbAppId: process.env.REACT_APP_FB_APP_ID,
  gtmId: process.env.REACT_APP_GTM_ID_DEV,
};
