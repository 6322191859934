import React from "react";
import styled from "styled-components";
import { FaHome, FaFire, FaPen, FaUser, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #023740;
  padding: 4px;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #017589;
  }

  .icon {
    font-size: 22px;
    margin-bottom: 5px;
  }
`;

const BottomNavigation = ({ currentUser }) => {
  const navigate = useNavigate();
  const handleClick = (item) => {
    switch (item) {
      case "home":
        navigate("/");
        break;
      case "carpool":
        navigate("/carpool");
        break;
      case "profile":
        navigate(`/profile/${currentUser?.id}`);
        break;
      case "publish":
        navigate("/publish");
        break;
      case "search":
        navigate("/search-rides");
        break;
    }
  };
  return (
    <Container>
      <Option onClick={() => handleClick("home")}>
        <FaHome className="icon" />
        Home
      </Option>
      <Option onClick={() => handleClick("carpool")}>
        <FaFire className="icon" />
        Trending
      </Option>
      <Option onClick={() => handleClick("search")}>
        <FaSearch className="icon" />
        Search
      </Option>
      <Option onClick={() => handleClick("publish")}>
        <FaPen className="icon" />
        Post
      </Option>
    </Container>
  );
};

export default BottomNavigation;
