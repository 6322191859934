import { FaTicketAlt, FaSearch, FaUserFriends, FaCar } from "react-icons/fa";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Timeline = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <h2>How it works for Passengers</h2>

      <div className="timeline">
        <div className="timeline-item">
          <div className="timeline-content">
            <FaSearch className="timeline-icon" />
            <div className="timeline-number">1</div>
            <h3>Find a ride</h3>
            <p>
              Just enter your departure and arrival points and your travel date,
              then choose a car owner going your way. If you are a female you
              can find <strong>females only </strong>rides as well.
            </p>
          </div>
          <div className="offer-ride" onClick={() => navigate("search-rides")}>
            <h2>Find a Ride</h2>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <FaTicketAlt className="timeline-icon" />
            <div className="timeline-number">2</div>
            <h3>Book online</h3>
            <p>
              Book your seat online.Pay a minor GoSawari commision and You’ll
              instantly get the car owner's phone number to arrange the final
              details or directly chat with the driver using GoSawari's Chat
              system.
            </p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <FaUserFriends className="timeline-icon" />
            <div className="timeline-number">3</div>
            <h3>Travel together</h3>
            <p>
              Bring exact change to pay the car owner the agreed contribution
              during the ride.If you have change of plans you can cancel your
              ride.
            </p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <FaCar className="timeline-icon" />
            <div className="timeline-number">4</div>
            <h3>Enjoy the Ride</h3>
            <p>
              Relax and enjoy the shared ride experience with your fellow
              passengers.You can share your feedback of the ride when the ride
              is complete.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Timeline;

const Container = styled.div`
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    display: inline-block;
    margin-left: 2.9rem;
    color: #f5f5f5;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
    line-height: 1.06;
    font-size: 2.125rem;
    @media (max-width: 546px) {
      margin: 2rem 0;
    }
  }

  .timeline {
    border-radius: 0.5rem;
    background-color: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-around;
    margin: 20px;
    max-width: 80rem;
    padding: 0.5rem;
    margin-bottom: 2rem;
    gap: 2rem;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 600px) {
      margin: 5px;
    }
  }

  .timeline-item {
    max-width: 16rem;
    max-height: auto;
    text-align: center;
    position: relative;
    .offer-ride {
      background: transparent;
      border-radius: 16px;
      display: flex;
      align-items: center;
      border: none;
      justify-content: center;
      height: 4rem;
      cursor: pointer;
      :hover {
        background: teal;
      }
      h2 {
        font-size: 22px;
        margin-left: 0;
      }
    }
    @media (max-width: 1027px) {
      max-width: 100%;
    }
  }

  .timeline-content {
    padding: 10px;
    border-radius: 5px;
    text-align: left;
  }

  .timeline-icon {
    margin-bottom: 10px;
    font-size: 24px;
    color: white;
  }

  .timeline-number {
    border: 2px solid #054752;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: black;
    line-height: 36px;
    text-align: center;
    position: absolute;
    top: -30px;
    font-size: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  h3 {
    margin-bottom: 1rem;
    font-weight: 600;
    width: fit-content;
  }

  p {
    line-height: 1.4;
  }
`;
