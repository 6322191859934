import React, { Fragment } from "react";
import styled from "styled-components";
import PublishRide from "../../components/Publish";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../redux/constants";
import api from "../../services/api";
import Stepper from "../../components/Stepper";
import VehicleInfo from "../VehicleInfo";
import { useState } from "react";
import RidePublishSuccess from "../publishSuccess";
import RideSuccess from "../../components/RideSuccess";
import moment from "moment";
import { useEffect } from "react";
import { toast } from "react-toastify";
import VerifyDrivingLicense from "../../components/DrivingLicense";
import noData from "../../assets/images/noData.svg";
import { Message, Title } from "../../components/NoData/styles";
import CircularLoader from "../../components/UI/Loader";

const steps = ["Create a Ride", "Add Car Details", "Publish"];

function PublishRides() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [licenseStatus, setLicenseStatus] = React.useState(null);
  const [checkingLicense, setCheckingLicense] = React.useState(false);
  const [rideId, setRideId] = useState();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.publish);
  const { currentUser } = useSelector((state) => state.auth);
  useEffect(() => {
    const activeStep = localStorage.getItem("activeStep");
    const currentRideId = localStorage.getItem("rideId");
    if (activeStep && currentRideId) {
      setActiveStep(parseInt(activeStep));
      setRideId(currentRideId);
    }
    // check license
    checkLicense();
  }, []);

  const checkLicense = async () => {
    try {
      setCheckingLicense(true);
      const response = await api.get("/user/checkLicense");
      if (response.status == 200) {
        setLicenseStatus(response.data.data.license_status);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCheckingLicense(false);
    }
  };
  const ridesSubmitFrom = async (data) => {
    setSubmitting(true);
    // add to gtm
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "submit_ride", // Custom event name
      category: "Button", // Category of the event
      action: "Click", // Action (what user did)
      label: "Submit ride form", // Label to identify the element
      userId: currentUser.id,
      userName: currentUser.first_name,
      userEmail: currentUser.email,
    });
    dispatch({
      type: types.PUBLISH_RIDE_REQUEST,
    });
    if (!data.headingTo || !data.leavingFrom) {
      toast.error("Please enter valid info");
      return;
    }

    await api
      .post("/ride/create", {
        leaving_from: data.leavingFrom,
        heading_to: data.headingTo,
        route: data.route,
        departure_date: moment(data.date).format("YYYY-MM-DD"),
        departure_time: data.time,
        user_id: currentUser.id,
        ticket: data.ticket,
        pickup_location: data.pickupLocation,
        drop_location: data.dropLocation,
        arrival_date: moment(data.arrivalDate).format("YYYY-MM-DD"),
        arrival_time: data.arrivalTime,
        total_seats: data.totalSeats,
        start_lat: data.start_lat,
        start_lng: data.start_lng,
        end_lat: data.end_lat,
        end_lng: data.end_lng,
        duration: data.duration,
        distance: data.distance,
        smoking: data.smoking,
        automatic_approval: data.automatic_approval,
        female_only: data.female_only,
      })
      .then((result) => {
        setSubmitting(false);
        window.dataLayer.push({
          event: "submit_ride_success", // Custom event name
          category: "Button", // Category of the event
          action: "Click", // Action (what user did)
          label: "Submit ride form_success", // Label to identify the element
          userId: currentUser.id,
          userName: currentUser.first_name,
          userEmail: currentUser.email,
        });

        setRideId(result.data.data.id);
        handleNext(result.data.data.id);
      })
      .catch((error) => {
        setSubmitting(false);
        window.dataLayer.push({
          event: "submit_ride_failed", // Custom event name
          category: "Button", // Category of the event
          action: "Click", // Action (what user did)
          label: "Submit ride failed", // Label to identify the element
          userId: currentUser.id,
          userName: currentUser.first_name,
          userEmail: currentUser.email,
        });
        if (error.response.data?.message) {
          toast.error(error.response.data.message);
        }
        dispatch({
          type: types.PUBLISH_RIDE_FAILED,
          payload: error.response.data,
        });
      });
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (currentRideId = null) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    localStorage.setItem("activeStep", activeStep + 1);
    if (currentRideId != null) {
      localStorage.setItem("rideId", currentRideId);
    }

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <PublishRide
            ridesSubmitFrom={ridesSubmitFrom}
            isLoading={isLoading}
            currentUser={currentUser}
            submitting={submitting}
            licenseStatus={licenseStatus}
            checkLicense={checkLicense}
          />
        );
      case 2:
        return <VehicleInfo rideId={rideId} handleNext={handleNext} />;
      case 3:
        return (
          <RidePublishSuccess
            ridesSubmitFrom={ridesSubmitFrom}
            isLoading={isLoading}
            rideId={rideId}
            handleNext={handleNext}
          />
        );

      default:
        return null;
    }
  };

  const renderLicenseSection = (status) => {
    if (!status) {
      return (
        <VerifyDrivingLicense
          checkLicense={checkLicense}
          title="Upload your driving license"
          message="Please upload your license to publish your first ride"
        />
      );
    } else if (status == "submitted") {
      return (
        <>
          <div className="license-section">
            <img src={noData} height={"200px"} width={"auto"} alt="No Data" />
            <Title>Thanks for uploading your driving license</Title>
            <Message>
              Please wait for the admin to verify your license within 1 hour.
            </Message>
          </div>
        </>
      );
    } else if (status == "rejected") {
      return (
        <VerifyDrivingLicense
          checkLicense={checkLicense}
          title="Could not verify your driving license"
          message="Please upload your valid license with clear images"
        />
      );
    } else return null;
  };
  if (checkingLicense) {
    return (
      <PageContainer>
        {<CircularLoader isLoading={checkingLicense} />}
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      {/* {renderLicenseSection(licenseStatus)} */}
      {/* {licenseStatus == "verified" && ( */}
      <Fragment>
        <Stepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          skipped={skipped}
          setSkipped={setSkipped}
          isStepOptional={isStepOptional}
          isStepSkipped={isStepSkipped}
          handleNext={handleNext}
          handleBack={handleBack}
          handleSkip={handleSkip}
          handleReset={handleReset}
          steps={steps}
        />
        {renderStep(activeStep + 1)}
        {activeStep === steps.length && <RideSuccess rideId={rideId} />}
      </Fragment>
      {/* )} */}
    </PageContainer>
  );
}

export default PublishRides;
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  .license-section {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;
