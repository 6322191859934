import styled from "styled-components";
import DatePicker from "../UI/DatePicker";
import TimePicker from "../UI/TimePicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import api from "../../services/api";
import { TextField, FormControlLabel, Switch } from "@material-ui/core";

const EditForm = ({ ride, handleClose, handleUserRides, currentUser }) => {
  const [time, setTime] = useState();
  const [departureDate, setDepartureDate] = useState();
  const [arrivalDate, setArrivalDate] = useState();
  const [arrivalTime, setArrivalTime] = useState();
  const [ticket, setTicket] = useState();
  const [publish, setPublish] = useState(true);
  const [smoking, setSmoking] = useState(true);
  const [autoApproval, setAutoApproval] = useState(true);
  const [femaleOnly, setFemaleOnly] = useState(false);
  const [ticketInvalid, setTicketInvalid] = useState(false);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    setDepartureDate(ride?.departure_date);
    setTime(ride?.departure_time);
    setTicket(ride?.ticket);
    setPublish(ride?.published);
    setSmoking(ride?.smoking);
    setAutoApproval(ride?.automatic_approval);
    setFemaleOnly(ride?.female_only);
    setDuration(ride?.duration);
  }, [ride]);

  const handleTimeChange = (time) => {
    const initialTime = moment(time);

    // Updated regex to handle optional hours and only minutes
    const regex = /(?:(\d+)\s*hour[s]?)?\s*(\d+)?\s*(min[s]?|minute[s]?)/;
    const match = duration.match(regex);

    if (match) {
      const hours = match[1] ? parseInt(match[1], 10) : 0;
      if (hours) {
        initialTime.add(hours, "hours");
      }

      // The minute group is in `match[2]` when hours are present, or `match[1]` when only minutes are present
      const minutes = match[2]
        ? parseInt(match[2], 10)
        : match[1] && !hours
        ? parseInt(match[1], 10)
        : 0;
      if (minutes) {
        initialTime.add(minutes, "minutes");
      }
    }

    setTime(time);
    setArrivalTime(initialTime.format("h:mm A"));
  };
  const handleDateChange = (date) => {
    const initialDate = moment(date);
    const durationToAdd = moment.duration(ride.duration);
    const updatedDate = initialDate.add(durationToAdd).toDate();
    setDepartureDate(date);
    setArrivalDate(updatedDate);
  };

  const handleSaveChanges = async () => {
    try {
      const isPositiveNumber =
        /^\d*\.?\d*$/.test(ticket) &&
        parseFloat(ticket) > 0 &&
        parseFloat(ticket) <= 99999;

      if (!isPositiveNumber) {
        setTicketInvalid(true);
        return;
      }
      await api.patch(`/ride/${ride.id}`, {
        departure_date: moment(departureDate).format("YYYY-MM-DD"),
        departure_time: time,
        arrival_time: arrivalTime,
        arrival_date: arrivalDate,
        ticket: ticket,
        published: publish,
        smoking: smoking,
        automatic_approval: autoApproval,
        female_only: femaleOnly,
      });
      toast.success("Updated ride successfully");
      handleUserRides();
      handleClose();
    } catch (e) {
      toast.error("Failed to save changes");
    }
  };

  const handleInputChange = (event) => {
    setTicket(event.target.value);
  };

  const handlePublishRide = (e) => {
    setPublish(e.target.checked);
  };
  const handleSmoking = (e) => {
    setSmoking(e.target.checked);
  };
  const handleAutoApproval = (e) => {
    setAutoApproval(e.target.checked);
  };
  const handleFemaleOnly = (e) => {
    setFemaleOnly(e.target.checked);
  };

  return (
    <EditFormStyled className="edit-form">
      <div className="item">
        <TextField
          InputProps={{ min: 0 }}
          placeholder="per passanger ticket e.g 4000"
          label="Ticket"
          type="text"
          fullWidth
          variant="outlined"
          required
          value={ticket}
          onChange={handleInputChange}
          error={ticketInvalid}
          helperText={ticketInvalid ? "Invalid ticket amount" : ""}
        />
      </div>

      <div className="item">
        <DatePicker
          required={true}
          label={"Departure date"}
          date={departureDate}
          handleDateChange={handleDateChange}
        />
      </div>
      <div className="item">
        <TimePicker
          required={true}
          label={"New Departure time"}
          time={time}
          handleTimeChange={handleTimeChange}
        />
      </div>
      <div className="item">
        <span>
          <FormControlLabel
            value={"start"}
            checked={publish}
            control={<Switch color="primary" />}
            label="Publish ride"
            labelPlacement="start"
            onChange={handlePublishRide}
            style={{ display: "flex", justifyContent: "space-between" }}
          />
        </span>
      </div>
      <div className="item">
        <span>
          <FormControlLabel
            value={"start"}
            checked={smoking}
            control={<Switch color="primary" />}
            label="Allow smoking in the car"
            labelPlacement="start"
            onChange={handleSmoking}
            style={{ display: "flex", justifyContent: "space-between" }}
          />
        </span>
      </div>
      <div className="item">
        <span>
          <FormControlLabel
            value={"start"}
            checked={autoApproval}
            control={<Switch color="primary" />}
            label="Auto approval of booking requests"
            labelPlacement="start"
            onChange={handleAutoApproval}
            style={{ display: "flex", justifyContent: "space-between" }}
          />
        </span>
      </div>
      {currentUser?.gender == "female" && (
        <div className="item">
          <span>
            <FormControlLabel
              value={"start"}
              checked={femaleOnly}
              control={<Switch color="primary" />}
              label="Female Only"
              labelPlacement="start"
              onChange={handleFemaleOnly}
              style={{ display: "flex", justifyContent: "space-between" }}
            />
          </span>
        </div>
      )}

      <div className="btn-container">
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={handleSaveChanges}>
          Save
        </Button>
      </div>
    </EditFormStyled>
  );
};

export default EditForm;

const EditFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: max-content;
  .item {
    display: flex;
    flex-direction: column;
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
