import React, { useEffect } from "react";
import Login from "../../components/login/Login";
import styled from "styled-components";
import { cleanLoginForm, login } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import ReactGA from "react-ga4";
import { device } from "../../Utils";

const LoginPage = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, success, error } = useSelector((state) => state.login);
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const url = window.location;
  const params = new URL(url.href).searchParams;
  const redirectUrl = params.get("redirectUrl");

  const submitLoginForm = (data) => {
    // ReactGA.event({
    //   category: "login",
    //   action: "submit login form",
    // });

    dispatch(login(data));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanLoginForm());
    };
  }, []);
  useEffect(() => {
    if (success === true) {
      if (isAuthenticated === true) {
        if (currentUser.otp_verified) {
          if (currentUser.isAdmin) {
            navigate("/admin");
            return;
          }
          socket.connect();
          socket.emit("userInfo", currentUser);
          if (redirectUrl) navigate(`/${redirectUrl}`);
          else navigate("/search-rides");
        } else navigate("/otp");
      }
    }
  }, [success, redirectUrl]);

  return (
    <LoginWrapper>
      <div className="left">
        <div>
          <h1>Pick rides at low prices</h1>
          <h2>Offer or find a safe and comfortable ride in Pakistan</h2>
          <p>
            Planning a trip? Find a convenient ride or offer your own if you
            have a car. Enjoy a comfortable travel experience tailored to your
            needs.
          </p>
        </div>
      </div>
      <div className="right">
        <Login
          submitLoginForm={submitLoginForm}
          isLoading={isLoading}
          success={success}
          loginError={error}
        />
      </div>
    </LoginWrapper>
  );
};

export default LoginPage;
const LoginWrapper = styled.div`
  padding-top: 5rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    padding-top: 2rem;
  }
  .left {
    max-width: 35rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    @media (max-width: 768px) {
      display: none;
    }

    h1 {
      color: ${COLORS.primaryTextColor};
      margin-bottom: 16px;
      line-height: 0.6;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    h2 {
      line-height: 28.7px;
      color: ${COLORS.secondaryColor};
      margin-bottom: 16px;
    }
    p {
      color: ${COLORS.secondaryTextColor};
    }
  }
  .right {
    align-items: center;
    max-width: 40rem;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 1rem;
    @media (max-width: 546px) {
      width: 100%;
      padding: 1rem;
    }
  }
  @media (max-width: 546px) {
    flex-direction: column-reverse;
  }
`;
