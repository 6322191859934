import React from "react";
import CustomButton from "../UI/Button";
import VehicleItem from "../VehicleItem";
import styled from "styled-components";

function UserVehicles({
  vehicles,
  selectedVehicleId,
  setSelectedVehicle,
  submitExisitingVehicle,
  setShowConfirm,
}) {
  return (
    <React.Fragment>
      {vehicles &&
        vehicles.map((vehicle) => {
          return (
            <VehicleItem
              vehicle={vehicle}
              selectedVehicleId={selectedVehicleId}
              setSelectedVehicle={setSelectedVehicle}
            />
          );
        })}{" "}
      {vehicles.length > 0 ? (
        <div style={{ display: "flex", gap: "1rem", marginBottom: "8rem" }}>
          <div className="delete-ride">
            <StyledDeleteBtn onClick={() => setShowConfirm(true)}>
              Delete Ride
            </StyledDeleteBtn>
          </div>
          <div style={{ width: "30%", marginBottom: "4rem" }}>
            <CustomButton
              type="submit"
              disabled={selectedVehicleId == null ? true : false}
              label={"Next"}
              onClick={() => submitExisitingVehicle()}
            />{" "}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default UserVehicles;

const StyledDeleteBtn = styled.button`
  outline: none;
  border: none;
  padding: 1rem 2rem;
  border: 1px solid orangered;
  color: white;
  font-size: 1rem;
  color: orangered;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
`;
