import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { COLORS } from "../../assets/colors";
import api from "../../services/api";
import { toast } from "react-toastify";
import ConfirmModal from "../models/ConfirmModal";
import EditIcon from "@material-ui/icons/Edit";
import TripTimeline from "../UI/TripTimeline";
import { FaArrowRight } from "react-icons/fa";
import styled from "styled-components";
import EditRideModal from "../models/EditRideModal";
import { Delete, NavigateNext } from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../Utils";
import TripTimelineV2 from "../UI/TripTimelineV2";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  Boxstyles: {
    maxWidth: "445px",
    borderRadius: theme.spacing(2),
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 1pt 4pt, rgba(0, 0, 0, 0.08) 0px 2pt 8pt",
    transition: "box-shadow 200ms ease-in 0s",
    backgroundColor: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      minWidth: "420px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
      minWidth: "338px",
    },
    flexGrow: 1,
    "&:hover": {
      cursor: "pointer",
      boxShadow:
        "rgba(0, 0, 0, 0.08) 0px 2pt 8pt, rgba(0, 0, 0, 0.08) 0px 2pt 16pt",
    },
  },
  CardFooter: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: theme.spacing(2),
  },
  flexItem: {
    margin: theme.spacing(1),
  },
  location: {
    color: `${COLORS.secondaryTextColor}`,
    fontSize: "12px",
  },
  fareStyles: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  toc: {
    display: "flex",
    flex: "auto",
  },
  iconBtnGrp: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "1rem",
    marginBottom: "1rem",
    gap: "0.25rem",
    width: "100%",
    // maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingLeft: "1rem",
    },
  },
}));
const ArrowRightIcon = styled(FaArrowRight)`
  padding-top: 5px;
`;

export default function Ride({ handleUserRides, ride, isMine = false }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [startRide, setStartRide] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const isFull = ride?.total_seats === ride?.seats_booked ? true : false;
  const { currentUser } = useSelector((state) => state.auth);

  const renderTicketPrice = (price) => {
    if (isFull) {
      return <p style={{ color: COLORS.primaryColor }}>FULL</p>;
    } else return <p style={{ fontSize: "1rem" }}>Rs.{price} </p>;
  };
  const handleOnRideClick = () => {
    if (ride.status === "active" || ride.has_departed) {
      toast.info("This ride has already departed");
    } else {
      navigate(`/active-ride/${ride.id}`);
    }
  };
  const onCheckDetails = () => {
    navigate(`/active-ride/${ride.id}`);
  };

  const handleDeleteRide = async (id) => {
    try {
      const response = await api.post("ride/delete", {
        id,
      });
      if (response.data.data) {
        toast.info("Ride Deleted");
        handleUserRides("deleted");
        setShowConfirm(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleStartRide = async (id) => {
    const currentTime = moment();
    const depTime = moment(ride.departur_time, "HH:mm:ss Z (ZZ)");
    const rideStartedAt = currentTime.diff(depTime, "minutes");
    if (rideStartedAt > 30) {
      toast.error("You are late");
      return;
    }
    try {
      const response = await api.patch(`/ride/start-ride/${id}`, {
        has_departed: true,
        status: "active",
      });

      if (response.data.data) {
        toast.success("Ride updated");
        handleUserRides("active");
        setStartRide(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data, {
        autoClose: 8000,
      });
    }
  };

  const handleEditModal = () => {
    setShowEditModal(true);
  };

  const depDate = new Date(ride.departure_date).getDate();

  const currDate = new Date().getDate();
  const diffDepDate = currDate != depDate;

  return (
    <Box
      className={classes.Boxstyles}
      onClick={isMine ? null : handleOnRideClick}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        alignContent="space-between"
      >
        <Grid item container xs={12}>
          <Grid item xs={4} container alignContent="space-around">
            <Box padding={1}>
              <Typography
                variant="body1"
                style={{ fontSize: "14px" }}
                color="textSecondary"
              >
                {moment(ride?.departure_date).format("MMM Do, YYYY")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end">
            <Box padding={1}>
              <Typography
                variant="body1"
                style={{ fontSize: "14px" }}
                color="textSecondary"
              >
                {ride?.leaving_from}{" "}
                <span>
                  <ArrowRightIcon />
                </span>{" "}
                {ride?.heading_to}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        alignContent="space-between"
      >
        <Grid item container xs={12} spacing={4}>
          <Grid
            item
            xs={9}
            container
            justify="flex-start"
            alignContent="space-between"
          >
            <Box pl={1}>
              <TripTimelineV2
                leavingFrom={ride?.leaving_from}
                headingTo={ride?.heading_to}
                drop={ride?.drop_location}
                pick={ride?.pickup_location}
                fromTime={ride?.departure_time}
                toTime={ride?.arrival_time}
                startLat={ride?.start_lat}
                startLng={ride?.start_lng}
                endLat={ride?.end_lat}
                endLng={ride?.end_lng}
                rideId={ride?.id}
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.fareStyles}>
              <Typography variant="h6" component="h1" color="secondary">
                {renderTicketPrice(ride?.ticket)}
              </Typography>

              {/* <p className={classes.location}>&nbsp;{ride?.distance}</p> */}
              {/* <p className={classes.location}>
                &nbsp;{ride?.duration.substring(0, 15)}
              </p> */}
              <p className={classes.location}>
                &nbsp;{ride?.route?.substring(0, 15)}
              </p>
              {ride?.female_only && (
                <Tooltip title={"Only for females"}>
                  <div style={{ display: "flex", width: "max-content" }}>
                    <p
                      style={{
                        color: "hotpink",
                        position: "absolute",
                        fontSize: "small",
                      }}
                    >
                      female only
                    </p>
                  </div>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
        <div style={{ marginTop: "1rem" }}></div>

        <Grid className={classes.wrapper} item container xs={12}>
          {isMine ? (
            <Box className={classes.iconBtnGrp}>
              {!ride.RideRequests ||
                (ride.RideRequests.length == 0 &&
                  ride?.status === "pending" && (
                    <Tooltip title="Delete your ride">
                      <IconButton
                        size="medium"
                        style={{ border: "1px solid gainsboro" }}
                        variant="text"
                        color="primary"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        onClick={() => handleDeleteRide(ride.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ))}
              {!ride.RideRequests ||
                (ride.RideRequests.length == 0 &&
                  ride?.status === "pending" && (
                    <Tooltip title="Edit your ride">
                      <IconButton
                        size="medium"
                        variant="text"
                        color="primary"
                        style={{ border: "1px solid gainsboro" }}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        onClick={handleEditModal}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ))}

              {ride?.has_departed && ride?.status == "completed" && (
                <StyledLink to={`/active-ride/${ride?.id}`}>
                  COMPLETED
                </StyledLink>
              )}
              {ride?.has_departed && ride?.status == "active" ? (
                <StyledLink to={`/active-ride/${ride?.id}`}>
                  END RIDE?
                </StyledLink>
              ) : ride?.status == "pending" && !diffDepDate ? (
                <StyledButton
                  onClick={(event) => {
                    setStartRide(true);
                  }}
                  disabled={ride?.has_departed}
                >
                  {ride?.status == "pending"
                    ? "Start"
                    : ride?.has_departed === true
                    ? "Departed"
                    : null}
                </StyledButton>
              ) : null}

              {ride?.status != "completed" && (
                <Tooltip title="View ride details">
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="delete"
                    onClick={onCheckDetails}
                    style={{
                      border: `1px solid ${COLORS.borderColor}`,
                    }}
                  >
                    <NavigateNext fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ) : (
            <Box display="flex" justifyContent="flex-end" mr={2}>
              {!isFull && (
                <Typography display="inline" color="textSecondary">
                  Seats:{" "}
                  <span style={{ color: COLORS.primaryTextColor }}>
                    {ride?.total_seats - ride?.seats_booked}
                  </span>
                </Typography>
              )}
            </Box>
          )}
          {showConfirm && (
            <ConfirmModal
              title={"Delete Ride"}
              content={
                "Are you sure you want to delete this ride? All the bookings will be cancelled"
              }
              open={showConfirm}
              handleClose={() => setShowConfirm(false)}
              confirmBtnText={"Confirm"}
              cancelBtnText={"Cancel"}
              onConfirm={() => {
                handleDeleteRide(ride.id);
              }}
            />
          )}
          {startRide && (
            <ConfirmModal
              title={"Start your journey?"}
              content={
                "Make sure that you have collected the fare from each passanger.Don't forget to mark the ride as complete once you reach your destination"
              }
              open={startRide}
              handleClose={() => setStartRide(false)}
              confirmBtnText={"Confirm"}
              cancelBtnText={"Cancel"}
              onConfirm={() => {
                handleStartRide(ride.id);
              }}
              type={"startRide"}
            />
          )}
          {showEditModal && (
            <EditRideModal
              open={showEditModal}
              ride={ride}
              handleClose={() => setShowEditModal(false)}
              title={"Edit Ride"}
              cancelBtnText={"Cancel"}
              confirmBtnText={"Save"}
              onConfirm={() => console.log("Edit save")}
              handleUserRides={handleUserRides}
              currentUser={currentUser}
            />
          )}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
}

const StyledButton = styled.button`
  padding: 4px;
  border-radius: 8px;
  background: ${(props) => (props.completed ? "lightgrey" : "#054752")};
  color: white;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  max-width: 150px;
  margin-right: 5px;
  :hover {
    background: ${(props) => (props.completed ? "lightgrey" : "#326871")};
  }
`;

const StyledLink = styled(Link)`
  color: #00b5ff;
  font-weight: "500";
  margin-right: 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  width: 100px;
  font-weight: bold;
  text-decoration: none;
  :hover {
    font-weight: 600;
    text-decoration: underline;
  }
`;

const OverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  border-radius: 8px;
  background: ${COLORS.lightBackgroundColor};
  opacity: 0.6;
  justify-content: end;
  display: flex;
  padding: 1rem;
  color: red;
  align-items: end;
  font-size: large;
`;
