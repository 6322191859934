import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  BOOKING_REQUEST_STATUS,
  RIDE_STATUS_AS_DRIVER,
  device,
} from "../../Utils";
import { COLORS } from "../../assets/colors";
import api from "../../services/api";
import NoData from "../../components/NoData";
import bookings from "../../assets/images/bookings.png";
import { Pagination } from "@material-ui/lab";
import Ride from "../../components/Ride";
import CircularLoader from "../../components/UI/Loader";
function Yourrides() {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [userRides, setUserRides] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [activeTab, setActiveTab] = useState("pending");

  const offset = (page - 1) * pageSize;

  useEffect(() => {
    if (currentUser.id) {
      handleUserRides(activeTab);
    }
  }, [currentUser, activeTab, page]);

  const handleTabs = (activeTab) => {
    setPage(1);
    setActiveTab(activeTab);
  };

  const handleUserRides = async (status) => {
    setLoading(true);
    const params = {
      limit: limit,
      offset: offset,
      status: activeTab,
    };
    const response = await api.get(`ride/getRidesByUserId/${currentUser.id}`, {
      params,
    });
    setLoading(false);
    setUserRides(response.data.data);
    if (status === "active") {
      setActiveTab("active");
    }

    if (response.data.totalRides > 0) {
      setPages(Math.ceil(response.data.totalRides / pageSize));
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  const renderBookingHeader = (bookingStatus) => {
    if (!bookingStatus) {
      return "All";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.PENDING) {
      return "Upcoming";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.COMPLETED) {
      return "Completed";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.ACTIVE) {
      return "Active";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.CANCELLED) {
      return "Cancelled";
    } else {
      return "All";
    }
  };
  const renderBookingDec = (bookingStatus) => {
    if (!bookingStatus) {
      return "All rides details";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.PENDING) {
      return "Following are your upcoming rides. Make sure you don't miss them.";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.COMPLETED) {
      return "Following are the rides which you have completed successfuly.";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.ACTIVE) {
      return "You have started the following ride. Make sure to mark it as completed upon reaching the destination.";
    } else if (bookingStatus === RIDE_STATUS_AS_DRIVER.CANCELLED) {
      return "Following are your cancelled rides";
    } else {
      return "All of your rides";
    }
  };

  return (
    <MainContainer>
      <div className="content">
        <div className="main">
          <div className="main__header">
            <h1>Your Rides as a Driver</h1>

            <div className="tabs">
              <div
                className={activeTab == "all" && "active"}
                onClick={() => handleTabs("all")}
              >
                All
              </div>
              <div
                className={
                  activeTab == RIDE_STATUS_AS_DRIVER.ACTIVE && "active"
                }
                onClick={() => handleTabs(RIDE_STATUS_AS_DRIVER.ACTIVE)}
              >
                Active
              </div>
              <div
                className={
                  activeTab == RIDE_STATUS_AS_DRIVER.PENDING && "active"
                }
                onClick={() => handleTabs(RIDE_STATUS_AS_DRIVER.PENDING)}
              >
                Upcoming
              </div>

              <div
                className={
                  activeTab == RIDE_STATUS_AS_DRIVER.COMPLETED && "active"
                }
                onClick={() => handleTabs(RIDE_STATUS_AS_DRIVER.COMPLETED)}
              >
                Completed
              </div>

              <div
                className={
                  activeTab == RIDE_STATUS_AS_DRIVER.CANCELLED && "active"
                }
                onClick={() => handleTabs(RIDE_STATUS_AS_DRIVER.CANCELLED)}
              >
                Cancelled
              </div>
            </div>
          </div>
          {loading && <CircularLoader />}
          {!loading && userRides?.length === 0 && activeTab !== "all" && (
            <div className="no-data">
              <NoData
                showImage={true}
                button={false}
                message={"No records found"}
              />
            </div>
          )}
          {!loading && userRides?.length == 0 && activeTab == "all" && (
            <div style={{ width: "100%", height: "auto" }}>
              <NoData
                showImage={true}
                imgSrc={bookings}
                title={"Looks like you haven't created a ride yet."}
                message={
                  "By creating a ride, you can save money on your travels. All the information regarding the rides you create will be displayed here"
                }
                buttonText={"Publish your first ride"}
                buttonLink={"/publish"}
                button={true}
              />
            </div>
          )}
          <div className="container">
            {!loading && userRides?.length > 0 && (
              <div className="tab-detail">
                <h1>{renderBookingHeader(activeTab)} Rides</h1>
                <p>{renderBookingDec(activeTab)}</p>
              </div>
            )}
            <div className="rides-container">
              {!loading &&
                userRides?.length > 0 &&
                userRides.map((ride) => (
                  <Ride
                    handleUserRides={handleUserRides}
                    ride={ride}
                    isMine={ride.User.id === currentUser?.id ? true : false}
                  />
                ))}
            </div>
          </div>
          <div className="pagination">
            {!loading && pages > 1 && userRides?.length > 1 && (
              <Pagination
                onChange={handleChange}
                size="large"
                page={page}
                count={pages}
                color="primary"
              />
            )}
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default Yourrides;
const MainContainer = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  @media (max-width: 546px) {
    padding-bottom: 4rem;
  }
  .content {
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;

    .sider {
      padding: 1rem;
      width: 250px;
      background-color: ${COLORS.primaryColor};
      justify-content: flex-start !important;
      color: white;
      height: 100vh;
      @media (max-width: 600px) {
        height: 25vh;
      }
      ul {
        @media (max-width: 475px) {
          display: flex;
        }
      }
      .header {
        height: 4rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        h5 {
          color: white;
          border-bottom: 1px solid;
        }
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        margin-bottom: 1rem;
        border-radius: 5px;
      }
    }
    .main {
      flex-direction: column;
      flex: 4;
      display: flex;
      justify-content: start;
      align-items: start;
      .no-data {
        display: flex;
        padding: 1rem;
        justify-content: center;
        font-size: small;
        font-weight: 200 !important;
        color: gray;
        font-family: system-ui;
        justify-content: center;
        width: 100%;
        align-items: center;
      }

      &__header {
        align: center;
        display: flex;
        justify-content: center;
        padding: 2rem;
        gap: 1rem;
        width: 100%;
        background: ${COLORS.backgroundGradient};
        height: fit-content;
        flex-direction: column;
        align-items: center;
        .tabs {
          width: fit-content;

          display: flex;
          @media (max-width: 546px) {
            max-width: 315px;
            overflow-x: auto;
          }
          @media (min-width: 547px) {
            height: 2rem;
          }

          div {
            cursor: pointer;
            width: fit-content;
            padding: 1rem;
            height: 100%;
            background: #40808b;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            color: white;
            border-radius: 20px;
            margin: 5px;
            color: white;
            :hover {
              background: #054752;
            }
            @media (max-width: 546px) {
              padding: 0.5rem;
            }
          }
          .active {
            background: #054752;
            border: 2px solid;
          }
        }
        h1 {
          color: white;
          margin: auto;
          font-weight: 400;
          @media (max-width: 546px) {
            font-size: larger;
            font-weight: bold;
          }
        }
        p {
          color: ${COLORS.secondaryColor};
        }
      }
      .container {
        max-width: 872px;
        min-width: 872px;
        margin: auto;
        @media (max-width: 880px) {
          min-width: 700px;
        }
        @media (max-width: 600px) {
          min-width: 500px;
        }
        @media (max-width: 500px) {
          min-width: 340px;
        }
        .tab-detail {
          margin-bottom: 1rem;
          padding: 1rem;
          h1 {
            font-size: x-large;
            margin-top: 1rem;
            @media (max-width: 500px) {
              font-size: large;
            }
          }
          p {
            color: gray;
            margin-top: 8px;
          }
        }
      }
      .card {
        height: 36vh;
        width: 50vw;
        display: flex;
        align-content: center;
        justify-content: center;
        border-radius: 25px;
        background-color: smoke-white;

        .delete {
          height: 2rem;
          width: 5rem;
          background-color: #054752;
          border-radius: 8px;

          .buttontext {
            font-size: 14px;
            color: white;
          }
        }
      }
      .rides-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        position: relative;
      }

      .pagination {
        margin: 2rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    @media ${device.mobileL} {
      flex-direction: row;
    }
  }
`;
