import React, { Fragment, useEffect } from "react";
import VerifyDrivingLicense from "../../components/DrivingLicense";
import api from "../../services/api";
import PageContainer from "../../components/UI/PageContainer";
import verifyImg from "../../images/verify_license.svg";
import security from "../../images/security.svg";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CircularLoader from "../../components/UI/Loader";
import { Message, Title } from "../../components/NoData/styles";
import { COLORS } from "../../assets/colors";

const License = () => {
  const navigate = useNavigate();
  const [licenseStatus, setLicenseStatus] = React.useState(null);
  const [checkingLicense, setCheckingLicense] = React.useState(false);

  useEffect(() => {
    checkLicense();
  }, []);
  const checkLicense = async () => {
    try {
      setCheckingLicense(true);
      const response = await api.get("/user/checkLicense");
      if (response.status == 200) {
        setLicenseStatus(response.data.data.license_status);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCheckingLicense(false);
    }
  };
  const goBack = () => navigate(-1);

  if (checkingLicense) {
    return (
      <PageContainer>
        <CircularLoader isLoading={checkingLicense} />
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <Wrapper>
        {licenseStatus == "submitted" && (
          <>
            <div className="license-section">
              <img
                src={verifyImg}
                height={"100px"}
                width={"auto"}
                alt="Verify"
              />
              <Title>Thanks for uploading your driving license</Title>
              <Message>
                Please wait for the admin to verify your license within 1 hour.
              </Message>
            </div>
          </>
        )}
        {licenseStatus == "verified" && (
          <>
            <div className="license-section">
              <img
                src={security}
                height={"100px"}
                width={"auto"}
                alt="Verify"
              />
              <Title>Your license is verified!</Title>
              <Message>
                Congratulations! Your license is now verified by GoSawari Team.
              </Message>
            </div>
          </>
        )}
        {licenseStatus !== "verified" && licenseStatus !== "submitted" && (
          <Fragment>
            {licenseStatus == "rejected" && (
              <p style={{ padding: "10px", color: COLORS.errorColor }}>
                Your license was rejected last time. Please upload your valid
                driving license.
              </p>
            )}
            <VerifyDrivingLicense
              checkLicense={checkLicense}
              title="Upload your driving license"
              message="Please upload your license to publish your ride"
            />
          </Fragment>
        )}

        <button className="back-btn" onClick={goBack}>
          Go Back
        </button>
      </Wrapper>
    </PageContainer>
  );
};

export default License;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .back-btn {
    padding: 0.5rem 1.5rem;
    border-radius: 16px;
    border: none;
    font-size: 1rem;
    background: transparent;
    font-weight: 600;
    color: deepskyblue;
    cursor: pointer;
  }
  .license-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
`;
