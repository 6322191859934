import { IconButton } from "@material-ui/core";
import { EditAttributes, MoreVertRounded } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MdBlock, MdNoAccounts } from "react-icons/md";
import { AiOutlineWarning } from "react-icons/ai";
import ConfirmModal from "../../models/ConfirmModal";
import BanUser from "../../models/AdminPanel/BanUser";
import { FaIdCard } from "react-icons/fa";
import api from "../../../services/api";
import { toast } from "react-toastify";

const tableHeaders = [
  // "ID",
  "avatar",
  "First name",
  "Last name",
  "E-mail",
  "Phone",
  "Dob",
  "join",
  "Gender",
  "CNIC",
  "CNIC Verf.",
  "license",
  "lic.status",
  "Action",
];

const TableContainer = styled.div`
  overflow-x: auto;
  height: 80vh;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  .isActive {
    border: 1px solid #c3c5c9;
  }
  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  font-size: 14px;
  color: grey;
  cursor: pointer;
`;
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 300px;
`;

const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CustomerTable = ({ customers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [showBanModal, setShowBanModal] = useState(false);
  const [action, setAction] = useState(null);
  const ddref = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleOutsideClick = (event) => {
    if (ddref.current && !ddref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = (item) => {
    setIsOpen(!isOpen);
    setActiveItem(item);
  };

  const onBanUser = () => {
    setIsOpen(false);
    setShowBanModal(true);
  };
  const onWarnUser = () => {
    setIsOpen(false);
  };

  const onVerifyLicenseOfUser = async (data) => {
    const res = await api.post(`admin/user/verify-license/${data.id}`);
    if (res.status === 200) {
      toast.info("License marked as verified");
    }
    setIsOpen(false);
  };

  const onRejectLicenseOfUser = async (data) => {
    const res = await api.post(`admin/user/reject-license/${data.id}`);
    if (res.status === 200) {
      toast.info("License marked as rejected");
    }
    setIsOpen(false);
  };
  const onVerifyCnicOfUser = async (data) => {
    const res = await api.post(`admin/user/verify-cnic/${data.id}`);
    if (res.status === 200) {
      toast.info("CNIC marked as verified");
    }
    setIsOpen(false);
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {customers?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={row.id == activeItem?.id && isOpen ? "isActive" : null}
            >
              {/* <TableCell>{row.id}</TableCell> */}
              <TableCell>
                <a
                  href={row.avatar_url}
                  download={row.avatar_url}
                  target="_blank"
                >
                  <img
                    src={
                      row.avatar_url ||
                      `https://ui-avatars.com/api/?name=${row.first_name}`
                    }
                    height={"30px"}
                    width={"30px"}
                  />
                </a>
              </TableCell>
              <TableCell>{row.first_name}</TableCell>
              <TableCell>{row.last_name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.mobile_number}</TableCell>
              <TableCell>{moment(row.dob).format("DD-MM-YYYY")}</TableCell>
              <TableCell>
                {moment(row.created_at).format("MMM Do,YY")}
              </TableCell>
              <TableCell>{row.gender}</TableCell>
              <TableCell>
                {row.cnic_front && (
                  <a
                    href={row.cnic_front}
                    download={row.cnic_front}
                    target="_blank"
                  >
                    <img
                      src={row.cnic_front}
                      height={"60px"}
                      width={"60px"}
                      alt="cnic front"
                    />
                  </a>
                )}

                {row.cnic_back && (
                  <a
                    href={row.cnic_back}
                    download={row.cnic_back}
                    target="_blank"
                  >
                    <img
                      src={row.cnic_back}
                      height={"60px"}
                      width={"60px"}
                      alt="cnic back"
                    />
                  </a>
                )}
              </TableCell>
              <TableCell>
                {row.cnic_verified ? "Verified" : "Not verified"}
              </TableCell>
              <TableCell>
                {row.license_front && (
                  <a
                    href={row.license_front}
                    download={row.license_front}
                    target="_blank"
                  >
                    <img
                      src={row.license_front}
                      height={"60px"}
                      width={"60px"}
                      alt="license front"
                    />
                  </a>
                )}

                {row.license_back && (
                  <a
                    href={row.license_back}
                    download={row.license_back}
                    target="_blank"
                  >
                    <img
                      src={row.license_back}
                      height={"60px"}
                      width={"60px"}
                      alt="cnic back"
                    />
                  </a>
                )}
              </TableCell>
              <TableCell>{row.license_status}</TableCell>

              <TableCell>
                <IconButton onClick={() => toggleDropdown(row)}>
                  <MoreVertRounded />
                </IconButton>
              </TableCell>
              {activeItem?.id === row.id && (
                <DropdownWrapper ref={ddref}>
                  <DropdownMenu isOpen={isOpen}>
                    <MenuItem onClick={() => onBanUser(row)}>
                      <span>
                        {" "}
                        <MdNoAccounts size={24} color="red" />
                      </span>{" "}
                      Ban {row.first_name} for 30 days
                    </MenuItem>
                    <MenuItem onClick={() => onWarnUser(row)}>
                      <span>
                        <AiOutlineWarning size={24} color="orange" />
                      </span>
                      Warn {row.first_name} to follow GoSawari guidelines
                    </MenuItem>
                    <MenuItem onClick={() => onVerifyCnicOfUser(row)}>
                      <span>
                        <FaIdCard size={24} color="green" />
                      </span>
                      Mark {row.first_name + "'s"} CNIC as verified
                    </MenuItem>
                    <MenuItem onClick={() => onVerifyLicenseOfUser(row)}>
                      <span>
                        <FaIdCard size={24} color="green" />
                      </span>
                      Mark {row.first_name + "'s"} License as verified
                    </MenuItem>
                    <MenuItem onClick={() => onRejectLicenseOfUser(row)}>
                      <span>
                        <FaIdCard size={24} color="green" />
                      </span>
                      Mark {row.first_name + "'s"} License as rejected
                    </MenuItem>
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </TableRow>
          ))}
          {showBanModal && (
            <BanUser
              title={`Ban ${activeItem?.first_name}`}
              cancelBtnText={"Cancel"}
              handleClose={() => setShowBanModal(false)}
              confirmBtnText={"Submit"}
              open={showBanModal}
              data={activeItem}
            />
          )}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default CustomerTable;
