import { Avatar, IconButton } from "@material-ui/core";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  ChatHeader,
  ChatInput,
  ChatLog,
  ChatMessage,
  ChatMessageAuthor,
  ChatMessageHeader,
  ChatMessageText,
  ChatMessageTimestamp,
  ChatTitle,
  ChatWrapper,
  EndSection,
  LeftSection,
  RightSection,
  Sections,
} from "./styles";
import {
  ArrowBack,
  Call,
  DateRangeRounded,
  InfoOutlined,
  InfoRounded,
  MailOutlineRounded,
  MobileOff,
  PhoneAndroidOutlined,
  RefreshRounded,
  SendRounded,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import chatIcon from "../../assets/images/chat.png";
import api from "../../services/api";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import CircularLoader from "../UI/Loader";
import NoData from "../NoData";
import UnreadMessageCount from "./UnreadMessageCount";
import { GlobalContext } from "../../contexts/globalContext";
import SearchField from "../SilkPort/Search";
import ChatRideDetails from "../ChatRideDetails";
import { FontWeight } from "@cloudinary/url-gen/qualifiers";
import { COLORS } from "../../assets/colors";
import { toast } from "react-toastify";

const Chat = ({ socket }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [contacts, setContacts] = useState(null);
  const [showInfo, setShowInfo] = useState(true);
  const [contactsCount, setContactsCount] = useState(0);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const [currentOpenedChat, setCurrentOpenedChat] = useState(null);
  const chatContainerRef = useRef(null);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [showContent, setShowContent] = useState("messages");

  const [hideRightSection, setHideRightSection] = useState(false);
  const [hideLeftSection, setHideLeftSection] = useState(false);
  const [senderMessagesCleared, setSenederMessagesCleared] = useState(null);
  const [loadingChat, setLoadingChat] = useState(false);

  const { setUnreadMessageCount } = useContext(GlobalContext);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const contact = parseInt(queryParams.get("user"));

    if (!isNaN(contact)) {
      getUser(contact);
    }
  }, [location.search]);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    getMyContacts(currentUser);

    const intervalId = setInterval(async () => {
      try {
        const response = await api.get("/chat/contacts");
        const filtered = response.data.data.rows.map((item) => {
          if (item.requested_by == currentUser?.id) {
            return item.requestedTo;
          }
          if (item.requested_to == currentUser?.id) {
            return item.requestedBy;
          }
        });
        let jsonObject = filtered.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setLoadingContacts(false);
        setContacts(uniqueArray);
      } catch (e) {
        console.log(e);
      }
    }, 1 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentUser]);

  useEffect(() => {
    if (window.innerWidth < 846) {
      setShowInfo(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    socket.on("messageResponse", (message) => {
      if (
        message.receiverInfo?.id === currentOpenedChat?.id ||
        message.senderInfo?.id === currentOpenedChat?.id
      ) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });
    return () => socket.off("messageResponse");
  }, [socket, currentOpenedChat]);

  const getMyChats = async (userId) => {
    try {
      setLoadingChat(true);
      const response = await api.get(`/chat/${userId}`);
      if (response.status == 200) {
        setMessages(response.data.data.rows);
        setLoadingChat(false);
      }
    } catch (e) {
      setLoadingChat(false);
    }
  };
  const getUser = async (id) => {
    try {
      const response = await api.get(`/user/contact/${id}`);
      if (window.innerWidth > 846) {
        setCurrentOpenedChat(response.data.data);
      }
      getMyChats(response.data.data.id);
    } catch (e) {
      console.log("error", e);
    }
  };
  const getMyContacts = async (currentUser) => {
    try {
      setLoadingContacts(true);
      const response = await api.get("/chat/contacts");
      if (response.status == 200) {
        const mycontactsCount = response.data.data.count;
        if (mycontactsCount > 0) {
          const filtered = response.data.data.rows.map((item) => {
            if (item.requested_by == currentUser?.id) {
              return item.requestedTo;
            }
            if (item.requested_to == currentUser?.id) {
              return item.requestedBy;
            }
          });
          let jsonObject = filtered.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
          setLoadingContacts(false);
          setContacts(uniqueArray);
          toast.info("Refresh the page if chat does not work correctly");
        }
        setLoadingContacts(false);
        setContactsCount(mycontactsCount);
      }
    } catch (e) {
      setLoadingContacts(false);
    }
  };
  const sendMessage = () => {
    if (message) {
      socket.emit("message", {
        message,
        sender: currentUser?.id,
        receiver: currentOpenedChat.id,
      });
      setMessage("");
    }
  };
  const onOpenChat = (user) => {
    setMessages([]);
    if (isMobileScreen) {
      setHideLeftSection(true);
      setHideRightSection(false);
    }

    setShowContent("messages");

    setCurrentOpenedChat(user);
    getMyChats(user.id);
    markMessagesAsRead(user.id);
  };
  const markMessagesAsRead = async (userId) => {
    try {
      const response = await api.patch("/chat/senderMessagesAsRead", {
        userId,
      });
      if (response.status == 200) {
        setSenederMessagesCleared(userId);

        const resp = await api.get("/chat/unread");
        setUnreadMessageCount(resp.data.count);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const isSmallScreen = window.innerWidth < 846;
  const isMobileScreen = window.innerWidth < 546;
  if (loadingContacts) {
    return <CircularLoader isLoading={loadingContacts} />;
  }
  if (!loadingContacts && contactsCount == 0) {
    return (
      <NoData
        title={"No contacts found"}
        message={"You have not published or booked a ride yet"}
        button={true}
        buttonLink={"/publish"}
        buttonText={"Publish your ride"}
      />
    );
  }
  const handleCloseChat = () => {
    setHideRightSection(true);
    setHideLeftSection(false);
  };
  const onHandleSearch = async (event) => {
    const searchText = event.target.value;
    if (searchText === "") {
      setSearchTerm(searchText);
      const response = await api.get("/chat/contacts");
      const filtered = response.data.data.rows.map((item) => {
        if (item.requested_by == currentUser?.id) {
          return item.requestedTo;
        }
        if (item.requested_to == currentUser?.id) {
          return item.requestedBy;
        }
      });
      let jsonObject = filtered.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      setLoadingContacts(false);
      setContacts(uniqueArray);
    } else {
      setSearchTerm(searchText);
      const filteredResults = contacts.filter((item) =>
        item.first_name.toLowerCase().includes(searchText.toLowerCase())
      );
      setContacts(filteredResults);
    }
  };
  const clearSearch = async () => {
    if (searchTerm === "") return;
    setSearchTerm("");
    const response = await api.get("/chat/contacts");
    const filtered = response.data.data.rows.map((item) => {
      if (item.requested_by == currentUser?.id) {
        return item.requestedTo;
      }
      if (item.requested_to == currentUser?.id) {
        return item.requestedBy;
      }
    });
    let jsonObject = filtered.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    setLoadingContacts(false);
    setContacts(uniqueArray);
  };

  const handleItem = (contentType) => {
    if (contentType == "messages") {
      setShowContent("messages");
    } else {
      setShowContent("rideDetails");
    }
  };
  return (
    <ChatWrapper>
      <Sections>
        <LeftSection hide={hideLeftSection}>
          <div className="contacts">
            <div className="me">
              <Avatar
                src={
                  currentUser?.avatar_url ||
                  `https://ui-avatars.com/api/?name=${currentUser?.first_name}`
                }
              />
              <div className="name-wrapper">
                <h3 style={{ textTransform: "capitalize" }}>
                  {currentUser?.first_name}
                </h3>
                {currentUser?.email && <medium>{currentUser?.email}</medium>}{" "}
                {currentUser?.mobile_number && (
                  <medium>{currentUser?.mobile_number}</medium>
                )}
              </div>
            </div>

            <div className="search">
              <SearchField
                clearSearch={clearSearch}
                onChange={onHandleSearch}
                placeholder={"Search contacts..."}
                value={searchTerm}
                style={{ background: "whitesmoke" }}
              />
            </div>
          </div>
          {contacts?.length > 0 &&
            contacts.map((item) => (
              <div
                className={`item ${
                  item?.id == currentOpenedChat?.id ? "active" : null
                }`}
                onClick={() => onOpenChat(item)}
              >
                <span>
                  <Avatar
                    src={
                      item?.avatar_url ||
                      `https://ui-avatars.com/api/?name=${item?.first_name}`
                    }
                  />
                </span>

                <span>
                  <h3 style={{ textTransform: "capitalize" }}>
                    {item?.first_name}
                  </h3>
                  <p>{item?.mobile_number}</p>
                  <p>
                    {item?.is_online ? (
                      <p style={{ color: "green" }}>Online</p>
                    ) : (
                      <p>Offline</p>
                    )}
                  </p>
                </span>
                <UnreadMessageCount
                  userId={item?.id}
                  senderMessagesCleared={senderMessagesCleared}
                />
              </div>
            ))}
        </LeftSection>
        <RightSection hide={hideRightSection} chatOpen={currentOpenedChat}>
          {!currentOpenedChat ? (
            <div className="no-chat">
              <svg
                aria-label=""
                class="x1lliihq x1n2onr6 x5n08af"
                fill="currentColor"
                height="96"
                role="img"
                viewBox="0 0 96 96"
                width="96"
              >
                <title></title>
                <path d="M48 0C21.532 0 0 21.533 0 48s21.532 48 48 48 48-21.532 48-48S74.468 0 48 0Zm0 94C22.636 94 2 73.364 2 48S22.636 2 48 2s46 20.636 46 46-20.636 46-46 46Zm12.227-53.284-7.257 5.507c-.49.37-1.166.375-1.661.005l-5.373-4.031a3.453 3.453 0 0 0-4.989.921l-6.756 10.718c-.653 1.027.615 2.189 1.582 1.453l7.257-5.507a1.382 1.382 0 0 1 1.661-.005l5.373 4.031a3.453 3.453 0 0 0 4.989-.92l6.756-10.719c.653-1.027-.615-2.189-1.582-1.453ZM48 25c-12.958 0-23 9.492-23 22.31 0 6.706 2.749 12.5 7.224 16.503.375.338.602.806.62 1.31l.125 4.091a1.845 1.845 0 0 0 2.582 1.629l4.563-2.013a1.844 1.844 0 0 1 1.227-.093c2.096.579 4.331.884 6.659.884 12.958 0 23-9.491 23-22.31S60.958 25 48 25Zm0 42.621c-2.114 0-4.175-.273-6.133-.813a3.834 3.834 0 0 0-2.56.192l-4.346 1.917-.118-3.867a3.833 3.833 0 0 0-1.286-2.727C29.33 58.54 27 53.209 27 47.31 27 35.73 36.028 27 48 27s21 8.73 21 20.31-9.028 20.31-21 20.31Z"></path>
              </svg>
              <h3>Select a chat</h3>
              <p> Select a contact and start talking</p>
            </div>
          ) : (
            <>
              <div className="chat-head">
                <div className="left">
                  <span className="magic">
                    {isMobileScreen && (
                      <span>
                        <IconButton size="small" onClick={handleCloseChat}>
                          <ArrowBack />
                        </IconButton>
                      </span>
                    )}

                    <span>
                      <Link to={`/profile/${currentOpenedChat?.id}`}>
                        <Avatar
                          src={
                            currentOpenedChat?.avatar_url ||
                            `https://ui-avatars.com/api/?name=${currentOpenedChat.first_name}`
                          }
                        />
                      </Link>
                    </span>
                  </span>

                  <span>
                    <h2 style={{ textTransform: "capitalize" }}>
                      {currentOpenedChat?.first_name}&nbsp;
                      {!isSmallScreen && currentOpenedChat?.last_name}
                    </h2>
                    <p>
                      {currentOpenedChat?.is_online ? (
                        <p style={{ color: "green" }}>Online</p>
                      ) : (
                        <p>Offline</p>
                      )}
                    </p>{" "}
                  </span>
                </div>
                <div className={`right`}>
                  {window.innerWidth < 546 && (
                    <a href={`tel:${currentOpenedChat?.mobile_number}`}>
                      <IconButton>
                        <Call />
                      </IconButton>
                    </a>
                  )}
                  <IconButton
                    size="medium"
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    {showInfo && <InfoRounded fontSize="medium" />}
                    {!showInfo && <InfoOutlined fontSize="medium" />}
                  </IconButton>
                </div>
              </div>
              <div className="content-type">
                <div className="item" onClick={() => handleItem("messages")}>
                  <p
                    className={showContent === "messages" ? "activeTab" : null}
                  >
                    Messages
                  </p>
                </div>
                <div className="vert-divider"></div>
                <div className="item" onClick={() => handleItem("rideDetails")}>
                  <p
                    className={
                      showContent === "rideDetails" ? "activeTab" : null
                    }
                  >
                    Ride History
                  </p>
                </div>
              </div>
              {showContent == "messages" ? (
                <div className="chat-body">
                  <ChatLog ref={chatContainerRef}>
                    {loadingChat && <CircularLoader isLoading={loadingChat} />}
                    {messages.map((message) => (
                      <ChatMessage
                        key={message.id}
                        isMe={message?.senderInfo?.id === currentUser?.id}
                      >
                        <Link to={`/profile/${message?.senderInfo?.id}`}>
                          <Avatar
                            src={
                              message?.senderInfo.avatar_url ||
                              `https://ui-avatars.com/api/?name=${message?.senderInfo.first_name}`
                            }
                          />
                        </Link>
                        <div className="message-content">
                          <ChatMessageHeader>
                            <ChatMessageAuthor>
                              {message?.senderInfo?.first_name}
                            </ChatMessageAuthor>
                            <ChatMessageTimestamp>
                              {moment(message?.createdAt).format(
                                "hh:mma, MMM D, YY"
                              )}
                            </ChatMessageTimestamp>
                          </ChatMessageHeader>
                          <ChatMessageText className="message">
                            {message.message}
                          </ChatMessageText>
                        </div>
                      </ChatMessage>
                    ))}
                  </ChatLog>
                  <div className="chat-footer">
                    <ChatInput
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      type="text"
                      placeholder="Type here..."
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) sendMessage();
                      }}
                    />
                    <IconButton
                      size={window.screen.width < 600 ? "small" : "medium"}
                      onClick={sendMessage}
                      className="icon-btn"
                      style={{
                        backgroundColor: "darkslategray",
                        color: "#fff",
                        padding: window.screen.width < 600 ? "5px" : "10px",
                      }}
                    >
                      <SendRounded color="#fff" />
                    </IconButton>
                    <IconButton
                      size="medium"
                      style={{
                        backgroundColor: "darkgray",
                        color: "#fff",
                        padding: window.screen.width < 600 ? "5px" : "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                      onClick={() => getMyChats(currentOpenedChat?.id)}
                    >
                      <RefreshRounded />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <ChatRideDetails
                  currentUserId={currentUser?.id}
                  chatUserId={currentOpenedChat?.id}
                />
              )}
            </>
          )}
        </RightSection>
        {currentOpenedChat && showInfo && (
          <EndSection>
            <div className="top">
              <Avatar
                src={
                  currentOpenedChat?.avatar_url ||
                  `https://ui-avatars.com/api/?name=${currentOpenedChat?.first_name}`
                }
              />
              <h3 style={{ textTransform: "capitalize", fontSize: "1.5rem" }}>
                {currentOpenedChat?.first_name}&nbsp;
                {currentOpenedChat?.last_name}
              </h3>
              <div>
                <span>
                  <Link
                    className="link"
                    to={`/profile/${currentOpenedChat?.id}`}
                  >
                    Visit profile
                  </Link>
                </span>
              </div>
            </div>
            <div className="mid">
              <div>
                <span>
                  <PhoneAndroidOutlined />
                </span>
                {currentOpenedChat?.mobile_number}
              </div>
              <div>
                <span>
                  <MailOutlineRounded />
                </span>
                {currentOpenedChat?.email}
              </div>
              <div>
                <span>
                  <DateRangeRounded />
                </span>
                Joined {moment(currentOpenedChat.createdAt).format("MMMM YYYY")}
              </div>
            </div>
            <div className="bottom"></div>
          </EndSection>
        )}
      </Sections>
    </ChatWrapper>
  );
};

export default Chat;
