import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { validateSignupForm } from "../../Utils";
import styled from "styled-components";
import CustomButton from "../UI/Button";
import { COLORS } from "../../assets/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "@material-ui/lab";
import FacebookLogin from "react-facebook-login";

import {
  ArrowForward,
  ArrowForwardIos,
  Email,
  MailOutline,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import api from "../../services/api";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import configMerged from "../../config";

function SignUp({ submitRegisterForm, isLoading, signupError }) {
  const recaptchaRef = React.createRef();
  const dateInputRef = React.useRef(null);

  const maxDigits = 11;
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [withEmail, setWithEmail] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    selectedGender: "",
    dob: null,
    showPassword: false,
  });

  let errorsObj = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    dob: "",
    selectedGender: "",
  };

  const [error, setError] = useState(errorsObj);

  React.useEffect(() => {
    if (signupError) {
      recaptchaRef.current.reset();
      setRecaptchaToken("");
    }
  }, [signupError]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!recaptchaToken) {
        toast.info("Please complete the reCAPTCHA challange");
        return;
      }

      const signupErrorObj = validateSignupForm(state);
      setError(signupErrorObj.errorObj);
      if (!signupErrorObj.hasError) {
        const response = await api.post("/auth/verify-recaptcha", {
          token: recaptchaToken,
        });
        if (response.data.success) {
          submitRegisterForm(state);
        } else {
          toast.error("reCAPTCHA verification failed. Please try again.");
          setRecaptchaToken("");
          recaptchaRef.current.reset();
        }
      }
    } catch (e) {
      toast.error("Something went wrong");
      setRecaptchaToken("");
      recaptchaRef.current.reset();
    }
  };

  const handleMobileNum = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= maxDigits) {
      setState({
        ...state,
        mobileNumber: input,
      });
    }
  };
  const handlePasswordVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };
  const handleOnchange = (value) => {
    setRecaptchaToken(value);
  };
  //login with google
  const loginwithgoogle = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "login_with_google_start", // Custom event name
      category: "Button", // Category of the event
      action: "Click", // Action (what user did)
      label: "Login with Google init", // Label to identify the element
    });
    window.open(`${configMerged.api.baseUrl}/auth/google`, "_self");
  };
  const handleFacebookCallback = async (response) => {
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with facebook Login.");
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "login_with_facebook_start", // Custom event name
      category: "Button", // Category of the event
      action: "Click", // Action (what user did)
      label: "Login with Facebook init", // Label to identify the element
    });
    try {
      const res = await api.post("/auth/login-with-fb", {
        name: response.name,
        email: response.email,
        picture: response.picture.data.url || "",
        fbUserId: response.userID,
      });
      if (res.status == 200) {
        navigate(`/dob?token=${res.data}`);
        window.dataLayer.push({
          event: "login_with_facebook_success", // Custom event name
          category: "Button", // Category of the event
          action: "Click", // Action (what user did)
          label: "Login with Facebook success", // Label to identify the element
        });
      } else {
        toast.error("Something went wrong");
        window.dataLayer.push({
          event: "login_with_facebook_fail", // Custom event name
          category: "Button", // Category of the event
          action: "Click", // Action (what user did)
          label: "Login with Facebook fail", // Label to identify the element
        });
        return;
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const getMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 17,
      today.getMonth(),
      today.getDate()
    );
    return minDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  };

  return (
    <MainContainer>
      {withEmail && (
        <Fragment>
          <LogoContainer>
            <h2 style={{ color: "#3d485d" }}>Create a new account</h2>
          </LogoContainer>
          <FormContainer>
            <form onSubmit={onSubmit}>
              <Box display="flex">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="filled-first-name-input"
                      label="First name"
                      type="text"
                      name="firstName"
                      value={state.firstName}
                      fullWidth
                      autoComplete="current-first-name"
                      variant="outlined"
                      required
                      onChange={(e) =>
                        setState({
                          ...state,
                          firstName: e.target.value,
                        })
                      }
                      helperText={error.firstName}
                      error={error.firstName != ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="filled-last-name-input"
                      label="Last name"
                      type="text"
                      fullWidth
                      value={state.lastName}
                      autoComplete="current-last-name"
                      variant="outlined"
                      required
                      onChange={(e) =>
                        setState({
                          ...state,
                          lastName: e.target.value,
                        })
                      }
                      helperText={error.lastName}
                      error={error.lastName != ""}
                    />
                  </Grid>
                </Grid>
              </Box>
              <TextField
                label="Mobile number"
                placeholder="03452149821"
                fullWidth
                variant="outlined"
                required
                value={state.mobileNumber}
                inputProps={{ maxLength: maxDigits }}
                onChange={(e) => handleMobileNum(e)}
                helperText={error.mobileNumber}
                error={error.mobileNumber != ""}
              />
              <TextField
                id="filled-last-name-input"
                label="Email"
                required
                type="email"
                fullWidth
                autoComplete="current-last-name"
                variant="outlined"
                onChange={(e) =>
                  setState({
                    ...state,
                    email: e.target.value,
                  })
                }
                error={error.email != ""}
                helperText={error.email}
              />
              <TextField
                id="filled-password-input"
                label="Password"
                type={state.showPassword ? "text" : "password"}
                fullWidth
                autoComplete="current-password"
                variant="outlined"
                required
                onChange={(e) =>
                  setState({
                    ...state,
                    password: e.target.value,
                  })
                }
                error={error.password != ""}
                helperText={error.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="filled-password-input"
                label="Confirm password"
                type="password"
                fullWidth
                autoComplete="current-password"
                variant="outlined"
                required
                onChange={(e) =>
                  setState({
                    ...state,
                    confirmPassword: e.target.value,
                  })
                }
                helperText={error.confirmPassword}
                error={error.confirmPassword}
              />
              <div className="dobcontainer">
                <TextField
                  id="filled-dob-input"
                  label="Date of Birth"
                  type="date"
                  fullWidth
                  value={state.dob}
                  autoComplete="date-of-birth"
                  variant="outlined"
                  required
                  onChange={(e) =>
                    setState({
                      ...state,
                      dob: e.target.value,
                    })
                  }
                  helperText={error.dob}
                  error={error.dob != ""}
                  InputLabelProps={{
                    shrink: true, // Ensures the label stays in the shrunken position
                  }}
                  inputProps={{
                    max: getMinDate(), // Set the maximum date to today minus 16 years
                  }}
                />

                {/* {error.dob && <p>{error.dob}</p>} */}
              </div>
              <div className="radioContainer">
                <p>Gender:</p>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="gender"
                    value="male"
                    checked={state.selectedGender === "male"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        selectedGender: e.target.value,
                      })
                    }
                  />
                  Male
                </RadioLabel>

                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="gender"
                    value="female"
                    checked={state.selectedGender === "female"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        selectedGender: e.target.value,
                      })
                    }
                  />
                  Female
                </RadioLabel>
                {error.selectedGender && (
                  <p className="error">{error.selectedGender}</p>
                )}
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={configMerged.recaptchaKey}
                onChange={handleOnchange}
              />

              <BtnContainer>
                <CustomButton
                  label={isLoading ? "Please wait" : "Register"}
                  type="submit"
                />
              </BtnContainer>
              {signupError && signupError.length && (
                <Alert severity="error" style={{ marginTop: "0.5rem" }}>
                  {signupError}
                </Alert>
              )}
              <LinkContainer>
                <Link
                  to="/login"
                  style={{
                    fontSize: "1rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  Already have an account? <span>Sign in</span>
                </Link>
                <p>
                  By creating an account you agree to our{" "}
                  <Link to="/terms-conditions">Terms & Conditions</Link> and
                  <Link to="/privacy-policy"> Privacy policy </Link>
                </p>
              </LinkContainer>
            </form>
          </FormContainer>
        </Fragment>
      )}
      {!withEmail && (
        <Fragment>
          <h4 className="header">How do you want to Signup?</h4>
          <button className="with-email" onClick={() => setWithEmail(true)}>
            <span>
              <i class="fab fa-regular fa-envelope"></i>{" "}
              <span>Continue with Email</span>
            </span>
            <ArrowForwardIos />
          </button>
        </Fragment>
      )}

      <div>
        <button className="google-login-button" onClick={loginwithgoogle}>
          <span>
            <i class="fab fa-google"></i> <span>Continue with Google</span>
          </span>
          <span>
            <ArrowForwardIos />
          </span>
        </button>
      </div>
      <div>
        <FacebookLogin
          buttonStyle={
            window.innerWidth < 600
              ? {
                  padding: "1rem",
                  marginTop: "1rem",
                  width: "300px",
                  borderRadius: "8px",
                }
              : {
                  padding: "1rem",
                  marginTop: "1rem",
                  width: "400px",
                  borderRadius: "8px",
                }
          }
          appId={configMerged.fbAppId} // we need to get this from facebook developer console by setting the app.
          autoLoad={false}
          fields="name,email,picture"
          scope="public_profile"
          callback={handleFacebookCallback}
        />
      </div>
    </MainContainer>
  );
}

export default SignUp;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
  p {
    text-align: center;
    font-weight: 200;
    font-size: 14px;
    color: gray;
    text-decoration: none;

    a {
      color: ${COLORS.linkColor};
      cursor: pointer;
    }
  }
  .header {
    font-size: 1.7rem;
    margin-bottom: 2rem;
    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
  .with-email {
    padding: 1rem 2rem;
    border: none;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    width: 400px;
    display: flex;
    justify-content: space-between;
    transition: background-color 0.3s, box-shadow 0.3s;
    color: ${COLORS.secondaryColor};
    padding: 1rem;
    cursor: pointer;
    :hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    }
    @media (max-width: 600px) {
      width: 300px;
    }
    i {
      margin-right: 8px;
      font-size: 22px;
    }
  }
  .google-login-button {
    /* border: 1px solid ${COLORS.borderColor}; */
    border: none;
    width: 400px;
    padding: 1rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    /* background-color: white; */
    /* color: gray; */
    color: ${COLORS.secondaryColor};
    /* padding: 5px 10px; */
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    /* font-weight: bold; */
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
      width: 300px;
    }
  }
  .google-login-button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .google-login-button i {
    margin-right: 8px;
    font-size: 22px;
    background: conic-gradient(
        from -45deg,
        #ea4335 110deg,
        #4285f4 90deg 180deg,
        #34a853 180deg 270deg,
        #fbbc05 270deg
      )
      73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  > form > .MuiTextField-root {
    text-align: center;
    color: white;
    margin-bottom: 18px;
  }
  > form > .MuiBox-root {
    margin-bottom: 10px;
    > .MuiTextField-root {
      padding-right: 5px;
    }
  }
  .radioContainer {
   display:flex;
   margin: 1rem 0;
   label {
    cursor: pointer;
    input {
      cursor: pointer;
    }
   }
   .error {
      color:${COLORS.errorColor};
      padding: 5px 0;
      text-align: left;
  }
   p{
    margin-right:1rem;
   }
  }
  .dobcontainer{
    p{
      color:red;
    }

    .datepicker{
      width:100%;
      height: 3rem;
      padding: 0 8px;
      font-size: 16px;
      border: 1px solid lightgray;
      border-radius: 5px;
    }
    .react-datepicker__navigation--next {
      /* background-color: #054752; */
      color: #054752;
    }
    .react-datepicker__navigation--previous {
      /* background-color: #054752; */
      color: #054752;
    }
    .react-datepicker__year-read-view--down-arrow {
      color: #054752;
    }
  
    .datepicker-dropdown::after {
      content: "\f078";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none; 
      font-family: FontAwesome; 
      font-size: 14px;
    }
    .react-datepicker__close-icon::after {
      cursor: pointer;
      background-color: #054752;
      color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 2px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: "×";
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      border-radius: 50%;
      background-color: #054752;
      color: #fff;
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      border-radius: 50%;
      background-color: #054752;
      color: #fff;
    }
 
    .error {
      color:${COLORS.errorColor}
      padding: 5px 0;
      text-align: left;
    }
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  min-height: 50px;
  > a {
    text-decoration: none;
    color: grey;
    span {
      color: ${COLORS.linkColor};
    }
    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin-top: 5px;
  }
`;

const BtnContainer = styled.div`
  margin-top: 10px;
`;
const ErrorText = styled.p`
  color: red !important;
  margin-top: 15px;
`;
const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const RadioInput = styled.input`
  margin-right: 16px;
  margin-top: -6px;

  &:checked {
    &::before {
      background-color: ${COLORS.primaryColor};
    }
  }

  &:disabled {
    &::before {
      background-color: lightgray;
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    /* border-radius: 50%; */
    border: 2px solid ${COLORS.primaryColor};
    background-color: white;
    transition: background-color 0.2s ease-in-out;
  }
`;
