import moment from "moment";
import React from "react";
import styled from "styled-components";

const tableHeaders = [
  // "ID",
  "first name",
  "last name",
  "login count",
  "last login",
  // "Action",
];

const UserRetention = ({ users }) => {
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {users?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>
                <small>{row.first_name}</small>
              </TableCell>
              <TableCell>{row.last_name}</TableCell>
              <TableCell>
                <small>{row.login_count}</small>
              </TableCell>

              <TableCell>
                {row.last_login &&
                  moment(row.last_login).format("MMM Do, YY, hh:mm a")}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default UserRetention;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  .isActive {
    border: 1px solid #c3c5c9;
  }

  th {
    background-color: #054752;
    padding: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  text-align: center;
  font-weight: 100;
  padding: 8px;
  font-size: 14px;
  color: grey;
  cursor: pointer;
`;
