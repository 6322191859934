import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const GTMPageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const pageViewEvent = {
      event: "pageView",
      pagePath: location.pathname,
      pageTitle: location.pathname,
    };

    TagManager.dataLayer({
      dataLayer: pageViewEvent,
    });
  }, [location]);

  return null;
};

export default GTMPageViewTracker;
