import React, { createContext, useState } from "react";
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [searchFields, setSearchFields] = useState({
    leavingFrom: "",
    goingTo: "",
    seats: "1",
    date: "",
    orginCity: "",
    destinationCity: "",
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  return (
    <GlobalContext.Provider
      value={{
        searchFields,
        setSearchFields,
        notificationCount,
        setNotificationCount,
        unreadMessageCount,
        setUnreadMessageCount,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
