import { Divider, IconButton } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import Car from "../../assets/images/xli.png";
import {
  Delete,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import api from "../../services/api";
import { toast } from "react-toastify";

const VehicleItem = ({
  vehicle,
  selectedVehicleId,
  setSelectedVehicle,
  page = "publish",
  fetchVehicles = () => {},
}) => {
  const selected = selectedVehicleId === vehicle.id ? true : false;

  const handleDelete = async (e, vehicleId) => {
    e.stopPropagation();

    try {
      const response = await api.delete(`/vehicle/${vehicleId}`);
      if (response.status == 200) {
        fetchVehicles();
        toast.info("Vehicle deleted");
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  return (
    <CardContainer selected={selected}>
      <div className="card">
        {/* <div className="imge-container">
          {vehicle.picture ? (
            <img src={vehicle.picture} className="car-image" />
          ) : (
            <img src={Car} className="car-image" />
          )}
        </div> */}
        <div className="content">
          <h3>
            <span>{vehicle.vehicle_model}</span>&nbsp;
            {vehicle.vehicle_name}
          </h3>
          <div className="vehicle-detail">
            <span style={{ color: COLORS.secondaryTextColor }}>
              {vehicle.vehicle_type}
            </span>
            <Divider
              variant="middle"
              orientation="vertical"
              style={{ height: "1rem", margin: "0 0.5rem" }}
            />

            <span style={{ color: COLORS.secondaryTextColor }}>
              {vehicle.vehicle_color}
            </span>
          </div>
          {page !== "myvehicles" && (
            <div
              className="lastBox"
              onClick={() => setSelectedVehicle(vehicle)}
            >
              {selectedVehicleId === vehicle.id ? (
                <span className="selected-item">
                  <RadioButtonChecked fontSize="large" color="primary" />
                </span>
              ) : (
                <span className="selected-item">
                  <RadioButtonUnchecked fontSize="large" color="primary" />
                </span>
              )}
            </div>
          )}
          {/* {page == "myvehicles" && (
            <div className="delet">
              <IconButton onClick={(e) => handleDelete(e, vehicle.id)}>
                <Delete />
              </IconButton>
            </div>
          )} */}
        </div>
      </div>
    </CardContainer>
  );
};

export default VehicleItem;
const CardContainer = styled.div`
  max-height: auto;
  border: ${(props) =>
    props.selected
      ? `2px solid ${COLORS.primaryColor}`
      : `1px solid ${COLORS.borderColor}`};
  margin-bottom: 10px;
  border-radius: 8px;
  .card {
    max-height: auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
    border-radius: 4px;

    .imge-container {
      width: 15rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 546px) {
        width: auto;
        height: auto;
      }

      .car-image {
        width: inherit;
        height: inherit;
        border-radius: 8px 0 0 8px;
        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 600px) {
          width: 90px;
        }
      }
    }
  }
  .content {
    background-color: #f7f7f9;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    h3 {
      font-weight: 400;
      color: rgb(5, 70, 82);
      font-size: x-large;
      line-height: normal;
      @media (max-width: 600px) {
        font-size: medium;
      }
    }
    .vehicle-detail {
      display: flex;
      color: gray;
    }
    .card__right {
      height: 100%;
      width: 100%;
    }
    .delet {
      display: flex;
      justify-content: right;
      align-items: end;
      padding-top: 1rem;
      color: #054752;
    }
  }
  // :hover {
  //   cursor: pointer;
  // }
  .lastBox {
    position: relative;
    cursor: pointer;
    @media (max-width: 600px) {
      margin-top: 2rem;
    }
    .selected-item {
      position: absolute;
      right: 0;
      bottom: 70%;
    }
  }
`;
