import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Summary from "./Summary";
import { COLORS } from "../../assets/colors";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GlobalContext } from "../../contexts/globalContext";
import CircularLoader from "../../components/UI/Loader";
import config from "../../config";

function Payment() {
  const [currentRide, setCurrentRide] = useState({});
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const { rideId } = useParams();
  const [loading, setLoading] = useState(false);
  const { searchFields } = useContext(GlobalContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (rideId) {
      getRideById(rideId);
    }
  }, [rideId]);

  async function getRideById(rideId) {
    setLoading(true);
    const response = await api.get(`ride/${rideId}`);
    setCurrentRide(response.data.data);
    setLoading(false);
  }

  const submitPayment = async (totalAmountToPay) => {
    try {
      if (!isAuthenticated) return;
      if (currentRide?.female_only && currentUser.gender != "female") {
        toast.error("This is Female Only ride");
        return;
      }
      setLoading(true);
      const response = await api.post("/payment", {
        ride_id: currentRide?.id,
        paid_by: currentUser?.id,
        paid_to: currentRide?.User.id,
        amount: totalAmountToPay,
        seats: parseInt(searchFields.seats),
      });

      if (response.data.data) {
        setLoading(false);
        window.location.href = response.data.data;
      }
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const sendFreeRequest = async (totalAmountToPay) => {
    try {
      if (!isAuthenticated) return;
      if (currentRide?.female_only && currentUser.gender != "female") {
        toast.error("This is Female Only ride");
        return;
      }
      setLoading(true);
      const response = await api.post("/payment/send-free-request", {
        ride_id: currentRide?.id,
        paid_by: currentUser?.id,
        paid_to: currentRide?.User.id,
        amount: totalAmountToPay,
        seats: parseInt(searchFields.seats),
      });

      if (response.status == 200) {
        setLoading(false);
        navigate(
          `/active-ride/${currentRide.id}/payment/confirm/transactionId=free`
        );
      }
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  return (
    <PageContainer>
      {!loading && (
        <h2>
          {config.featureFlags.freeLaunch
            ? "Payment Info"
            : "Pay Service Charges"}
        </h2>
      )}

      <ContentContainer>
        <div className="right">
          <Summary
            loading={loading}
            ride={currentRide}
            submitPayment={submitPayment}
            sendFreeRequest={sendFreeRequest}
          />
        </div>
      </ContentContainer>
    </PageContainer>
  );
}

export default Payment;

const PageContainer = styled.div`
  padding: 2rem 0 4rem 0;
  display: flex;
  width: 100%;
  max-width: 600px;
  margin: auto;
  height: auto;
  flex-direction: column;
  h2 {
    text-align: left;
    margin-left: 1rem;
    font-size: xx-large;
    @media (max-width: 600px) {
      margin-left: 1rem;
      font-size: xx-large;
    }
    @media (max-width: 360px) {
      margin-left: 1rem;
      font-size: x-large;
    }
  }
  .bottom {
    width: 50%;
    height: auto;
    margin-top: 4px;
  }

  .header {
    align: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    background-color: #054752d1;
    height: 9rem;
    h6 {
      color: white;
      margin: auto;
      font-weight: 400;
    }
    p {
      color: ${COLORS.secondaryColor};
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (max-width: 546px) {
    padding-bottom: 4rem;
  }
  .left {
    width: 100%;
    height: auto;
  }
  .right {
    width: 100%;
    height: auto;
    @media (max-width: 767px) {
      margin-top: 1rem;
    }
  }
`;
