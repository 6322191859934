import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import brand from "../../images/GoSawari_high.png";
import logo2 from "../../images/logoWhiteNoBG.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCircleOutline,
  ArrowBack,
  ArrowDropDown,
  ArrowDropUp,
  MessageRounded,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { LOGOUT_CURRENT_USER } from "../../redux/constants";
import { COLORS } from "../../assets/colors";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Notification from "../Notification";
import MyModal from "../../pages/Ask/Modal";

import Drawer from "./drawer";

import {
  DropdownMenu,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuLink,
} from "./styles";
import api from "../../services/api";
import { GlobalContext } from "../../contexts/globalContext";
import { socket } from "../../socket";
import mixins from "../../mixins";
import { capitalizeFirstLetter } from "../../Utils";

function Navigation() {
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState();

  const {
    notificationCount,
    setNotificationCount,
    unreadMessageCount,
    setUnreadMessageCount,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const renderPage = (pageEndpoint) => {
    navigate(pageEndpoint);
  };

  const logOut = () => {
    socket.emit("logout", currentUser.id);
    dispatch({
      type: LOGOUT_CURRENT_USER,
    });
    localStorage.removeItem("authToken");
    window.location.replace("/");
  };
  const redirectUser = (path) => {
    navigate(path);
  };
  const useStyles = makeStyles(() => ({
    button: {
      margin: "5px",
      color: "white",
      textTransform: "none",
      width: "134px",
      border: "2px solid teal",
      borderRadius: "10px",
    },
    badge: {
      top: 10,
      right: 10,
    },
  }));
  const classes = useStyles();
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);
  const notificationRef = useRef(null);
  const notifIconRef = useRef(null);
  const messagesRef = useRef(null);
  const messageIconRef = useRef(null);

  useEffect(() => {
    if (currentUser?.otp_verified) {
      setIsVerified(true);
    }
  }, [currentUser?.otp_verified]);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    socket.on("newMessageNotification", (count) => {
      setUnreadMessageCount(count.unreadCount);
    });
    socket.on("increment", () => {
      setNotificationCount(notificationCount + 1);
    });

    return () => {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getNotificationCount();
      getUnreadMessageCount();
    }
  }, [isAuthenticated, showMessages]);

  const getNotificationCount = async () => {
    try {
      const response = await api.get("/notifications/count");
      setNotificationCount(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getUnreadMessageCount = async () => {
    try {
      const response = await api.get("/chat/unread");
      setUnreadMessageCount(response.data.count);
      setUnreadMessages(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleMenu = (event) => {
    if (avatarRef.current && avatarRef.current.contains(event.target)) {
      setIsOpen(!isOpen);
    }
  };
  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !avatarRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target) &&
      !notifIconRef.current.contains(event.target)
    ) {
      setShowNotification(false);
    }
    if (
      messagesRef.current &&
      !messagesRef.current.contains(event.target) &&
      !messageIconRef.current.contains(event.target)
    ) {
      setShowMessages(false);
    }
  };
  const handleOnclickNotification = () => {
    setShowNotification(!showNotification);
  };
  const handleOnClickMessage = (event) => {
    if (
      messageIconRef.current &&
      messageIconRef.current.contains(event.target)
    ) {
      setShowMessages(!showMessages);
    }
  };
  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const markAllAsRead = async () => {
    try {
      await api.patch("/chat/markRead");
      setUnreadMessageCount(0);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <HeaderContainer>
      <div className="headercontainer">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            gap: "5px",
            marginRight: "5px",
          }}
        >
          <img src={logo2} height={"60px"} width={"auto"} />
          <img src={brand} height={"20px"} width={"auto"} />
        </Link>

        {/* <p className="beta-tag">beta</p> */}
      </div>

      {!currentUser?.isAdmin && isAuthenticated && isVerified && (
        <div className="mobile-notif">
          <IconButton onClick={() => navigate("inbox")}>
            <Badge
              ref={messageIconRef}
              badgeContent={unreadMessageCount}
              color="error"
            >
              <MessageRounded />
            </Badge>
          </IconButton>

          <IconButton
            aria-label={notificationsLabel(notificationCount)}
            onClick={() => navigate("notifications")}
          >
            <Badge
              ref={notifIconRef}
              badgeContent={notificationCount}
              color="error"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </div>
      )}
      <div className="hamburgerMenu">
        {isDrawerOpen ? (
          <IconButton size="large" onClick={handleDrawerToggle}>
            <ArrowBack
              style={{
                color: "white",
                fontSize: 28,
              }}
            />
          </IconButton>
        ) : (
          <Fragment>
            <IconButton size="large" onClick={handleDrawerToggle}>
              <MenuIcon
                style={{
                  color: "white",
                  fontSize: 28,
                }}
              />
            </IconButton>
          </Fragment>
        )}
        {!isAuthenticated && (
          <div>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                gap: "5px",
                marginRight: "5px",
              }}
            >
              {/* <img src={logo2} height={"60px"} width={"auto"} /> */}
              <img src={brand} height={"20px"} width={"auto"} />
            </Link>
          </div>
        )}
      </div>

      <Drawer
        isAdmin={currentUser?.isAdmin}
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        currentUser={currentUser}
        isAuthenticated={isAuthenticated}
      />

      <div className="headerright">
        {!currentUser?.isAdmin && (
          <>
            {!isAuthenticated ? (
              <>
                {/* <NavLink to={"group-tours"} activeClassName="active" exact>
                  Tours
                </NavLink> */}
                <NavLink to={"carpool"} activeClassName="active" exact>
                  Carpool
                </NavLink>

                <NavLink to={"search-rides"} activeClassName="active" exact>
                  Search
                </NavLink>
              </>
            ) : isVerified ? (
              <>
                {" "}
                <NavLink to={"carpool"} activeClassName="active" exact>
                  Carpool
                </NavLink>
                <NavLink to={"search-rides"} activeClassName="active" exact>
                  Search
                </NavLink>
              </>
            ) : null}
          </>
        )}

        {isAuthenticated ? (
          <>
            {!currentUser?.isAdmin ? (
              <>
                {!isVerified && (
                  <NavLink to={"dob"} activeClassName="active" exact>
                    Complete your onboarding
                  </NavLink>
                )}
                {isVerified && (
                  <>
                    <NavLink
                      to={"ride-requests"}
                      activeClassName="active"
                      exact
                    >
                      Requests
                    </NavLink>
                    <NavLink to={"my-bookings"} activeClassName="active" exact>
                      Bookings
                    </NavLink>
                    <NavLink to={"your-rides"} activeClassName="active" exact>
                      Rides
                    </NavLink>
                    <Button
                      onClick={() => redirectUser("/publish")}
                      variant="text"
                      color="secondary"
                      className={classes.button}
                      startIcon={<AddCircleOutline />}
                    >
                      Offer Ride
                    </Button>
                    <IconButton onClick={handleOnClickMessage}>
                      <Badge
                        ref={messageIconRef}
                        badgeContent={unreadMessageCount}
                        color="error"
                      >
                        <MessageRounded />
                      </Badge>
                    </IconButton>

                    <IconButton
                      aria-label={notificationsLabel(notificationCount)}
                      onClick={handleOnclickNotification}
                    >
                      <Badge
                        ref={notifIconRef}
                        badgeContent={notificationCount}
                        color="error"
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>

                    {showNotification && (
                      <div
                        className="notifcationContainer"
                        ref={notificationRef}
                      >
                        <div className="header">
                          <p className="text">Notifications</p>
                          <p className="mark"> Mark as all read</p>
                        </div>
                        <Notification
                          getNotificationCount={getNotificationCount}
                          currentUser={currentUser}
                        />
                      </div>
                    )}
                    <MessageDropDown isOpen={showMessages} ref={messagesRef}>
                      <div className="header">
                        <Link to="/inbox">
                          <p className="text">Messages</p>
                        </Link>
                        <div>
                          <p className="mark" onClick={markAllAsRead}>
                            {" "}
                            Mark as all read
                          </p>
                        </div>
                      </div>
                      <div className="messagesList">
                        {unreadMessages?.length === 0 && (
                          <>
                            <NoUnreadMessage>
                              <p>No unread messages</p>
                            </NoUnreadMessage>
                            <Link
                              to="/inbox"
                              style={{
                                color: COLORS.linkColor,
                                textAlign: "center",
                              }}
                            >
                              <h4> View Inbox</h4>
                            </Link>
                          </>
                        )}
                        {unreadMessages?.length > 0 &&
                          unreadMessages.map((item, index) => {
                            return (
                              <div className="item">
                                <Avatar src={item?.senderInfo.avatar_url} />
                                <span>{item?.senderInfo.first_name}</span>
                                <Link
                                  key={index}
                                  to={`/inbox/?user=${item.sender}`}
                                >
                                  {item.message}
                                </Link>
                              </div>
                            );
                          })}
                      </div>
                    </MessageDropDown>
                  </>
                )}
              </>
            ) : (
              <>
                <NavLink to={"admin"} activeClassName="active" exact>
                  Dashboard
                </NavLink>
              </>
            )}

            <IconButton
              onClick={handleToggleMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={isOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : undefined}
            >
              <Avatar
                src={currentUser?.avatar_url}
                sx={{ width: 32, height: 32 }}
                ref={avatarRef}
              ></Avatar>
            </IconButton>
            {!isOpen ? (
              <ArrowDropDown htmlColor="white" />
            ) : (
              <ArrowDropUp htmlColor="white" />
            )}

            <DropdownMenu isOpen={isOpen} ref={dropdownRef}>
              <DropdownMenuItem
                onClick={() => redirectUser(`/profile/${currentUser.id}`)}
              >
                <DropdownMenuIcon>
                  <i className="fas fa-user"></i>
                </DropdownMenuIcon>
                <DropdownMenuLink>
                  {currentUser?.first_name
                    ? capitalizeFirstLetter(currentUser?.first_name)
                    : null}
                </DropdownMenuLink>
              </DropdownMenuItem>
              {!currentUser?.isAdmin && isVerified && (
                <>
                  <DropdownMenuItem onClick={() => redirectUser("/inbox")}>
                    <DropdownMenuIcon>
                      <i className="fas fa-inbox"></i>
                    </DropdownMenuIcon>
                    <DropdownMenuLink>Inbox</DropdownMenuLink>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => redirectUser("/my-bookings")}
                  >
                    <DropdownMenuIcon>
                      <i className="fas fa-users"></i>
                    </DropdownMenuIcon>
                    <DropdownMenuLink>Bookings</DropdownMenuLink>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => redirectUser("/your-rides")}>
                    <DropdownMenuIcon>
                      <i className="fas fa-car"></i>
                    </DropdownMenuIcon>
                    <DropdownMenuLink>Rides as driver</DropdownMenuLink>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => redirectUser("/ride-requests")}
                  >
                    <DropdownMenuIcon>
                      <i className="fas fa-user-plus"></i>
                    </DropdownMenuIcon>
                    <DropdownMenuLink>Ride Requests</DropdownMenuLink>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => redirectUser("/my-refunds")}>
                    <DropdownMenuIcon>
                      <i className="fa fa-money-bill"></i>
                    </DropdownMenuIcon>
                    <DropdownMenuLink>Refunds</DropdownMenuLink>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => redirectUser("/my-vehicles")}
                  >
                    <DropdownMenuIcon>
                      <i className="fas fa-car"></i>
                    </DropdownMenuIcon>
                    <DropdownMenuLink>My vehicles</DropdownMenuLink>
                  </DropdownMenuItem>
                </>
              )}

              <DropdownMenuItem onClick={() => redirectUser("/settings")}>
                <DropdownMenuIcon>
                  <i className="fas fa-cog"></i>
                </DropdownMenuIcon>
                <DropdownMenuLink>Settings</DropdownMenuLink>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={logOut}>
                <DropdownMenuIcon>
                  <i class="fa-solid fa-right-from-bracket"></i>
                </DropdownMenuIcon>
                <DropdownMenuLink>Logout</DropdownMenuLink>
              </DropdownMenuItem>
            </DropdownMenu>
          </>
        ) : (
          <>
            <Button onClick={() => renderPage("login")} color="textSecondary">
              <Typography
                variant="subtitle2"
                style={{
                  color: "whitesmoke",
                  textTransform: "capitalize",
                  letterSpacing: "1px",
                  fontSize: "medium",
                }}
              >
                Login
              </Typography>
            </Button>
            <Button onClick={() => renderPage("register")} color="textPriamry">
              <Typography
                variant="subtitle2"
                style={{
                  color: "whitesmoke",
                  textTransform: "capitalize",
                  letterSpacing: "1px",
                  fontSize: "medium",
                }}
              >
                Register
              </Typography>
            </Button>
          </>
        )}
        {isSearchModalOpen && (
          <MyModal
            isOpen={isSearchModalOpen}
            setIsOpen={setIsSearchModalOpen}
          />
        )}
      </div>
    </HeaderContainer>
  );
}

export default Navigation;

const NoUnreadMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  widht: 100%;
  height: 100%;
  margin-top: 1rem;
  p {
    color: ${COLORS.secondaryTextColor};
  }
`;
const HeaderContainer = styled.div`
  background-color: ${COLORS.primaryColor};
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  padding-left: 1rem;

  .beta-tag {
    border: 1px solid white;
    height: max-content;
    border-radius: 32px;
    width: 64px;
    color: white;
    text-align: center;
    margin-top: 5px;
    @media (max-width: 600px) {
      display: none !important;
    }
  }
  @media (max-width: 880px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-notif {
    position: absolute;
    right: 61px;
    @media (max-width: 880px) {
      right: 0px;
    }
    button {
      color: white;
    }
    @media (max-width: 546px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    @media (min-width: 547px) {
      display: none;
    }
  }

  .hamburgerMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      padding-right: 1rem;
      @media (min-width: 599px) {
        display: none;
      }
    }
    @media (min-width: 880px) {
      display: none;
    }
  }

  .headercontainer {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      display: flex;
      align-items: center;
      color: white;
      letter-spacing: 2px;
      @media (max-width: 600px) {
        display: none !important;
      }
      h2 {
        margin-left: 5px;
        @media (max-width: 880px) {
          display: none;
        }
      }
    }
    > .MuiSvgIcon-root {
      margin-left: auto;
      margin-right: 30px;
    }
  }

  .headerright {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    .active {
      color: lightyellow;
      font-weight: 600;
      text-decoration: underline;
    }
    a {
      color: white;
      margin: 0 5px;
      text-decoration: none;
      letter-spacing: 1px;
      :hover {
        color: ${COLORS.lightYellowColor};
        text-decoration: underline;
      }
    }
    button {
      color: white;
      font-weight: 600;
      :hover {
        color: ${COLORS.secondaryTextColor};
      }
    }
    .notifcationContainer {
      position: absolute;
      height: 23.15rem;
      width: 23rem;
      top: 66px;
      right: 61px;
      background: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;
      z-index: 999;

      .header {
        background: ${COLORS.backgroundGradient};
        height: 4rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text {
          font-weight: bold;
          color: white;
        }
        .mark {
          color: white;
          text-align: inherit;
          cursor: pointer;

          :hover {
            content: "read";
            border-bottom: 1px solid ${COLORS.secondaryColor};
            transform: scaleX();
            transition: transform 1s ease-in-out;
          }
        }

        border-bottom: 1px solid lightgray;
      }
      .footer {
        height: 3rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        border-top: 1px solid lightgray;
        background: #f5f5f5;
        color: darkslategray;
        .close {
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    @media (max-width: 880px) {
      display: none;
    }
  }
`;
const MessageDropDown = styled.div`
  position: absolute;
  height: 23.15rem;
  width: 23rem;
  top: 66px;
  right: 61px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  z-index: 999;
  animation: dropdown 0.3s forwards;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  @keyframes dropdown {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .header {
    background: ${COLORS.backgroundGradient};
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .text {
      font-weight: bold;
      color: white;
    }
    .mark {
      color: white;
      text-align: inherit;
      cursor: pointer;

      :hover {
        content: "read";
        border-bottom: 1px solid ${COLORS.secondaryColor};
        transform: scaleX();
        transition: transform 1s ease-in-out;
      }
    }

    border-bottom: 1px solid lightgray;
  }
  .messagesList {
    overflow-y: auto;
    max-height: 19rem;
    .no-notif {
      padding: 1rem;
    }
    .item {
      padding: 0.5rem;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 0.25rem;
      cursor: pointer;
      a {
        color: gray;
      }
      :hover {
        background: #f5f5f5;
      }
      span {
        font-weight: 900;
        text-transform: capitalize;
        color: gray;
      }
    }
    ${mixins.scrollStyle};
  }
  .footer {
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-top: 1px solid lightgray;
    background: #f5f5f5;
    color: darkslategray;
    .close {
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

const HeaderAvatar = styled(Avatar)`
  cursor: pointer;
  h2 {
    text-decoration: none;
  }
  :hover {
    opacity: 0.8;
  }
`;
