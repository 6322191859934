import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { COLORS } from "../../assets/colors";
import { Button } from "../BookingRequest/styles";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import CircularLoader from "../../components/UI/Loader";
import { Alert, AlertTitle } from "@material-ui/lab";
import configMerged from "../../config";

const PaymentConfirmed = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [seatConfirmed, setSeatConfirmed] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!configMerged.featureFlags.freeLaunch) {
      verifyPayment(params.rideId, params.transactionId);
    }
  }, [params, configMerged]);

  const verifyPayment = async (rideId, transactionId) => {
    try {
      setLoading(true);
      const response = await api.post("/payment/verify", {
        ride_id: rideId,
        transaction_id: params.transactionId,
      });
      if (response.status === 200) {
        setSeatConfirmed(response.data.seatConfirmed);
        toast.success("Payment Verified");
      }
    } catch (e) {
      toast.error(e.response.data.message);
      setError(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularLoader isLoading={loading} />;
  }

  if (!loading && error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error} — <strong>Contact GoSawari's for help!</strong>
      </Alert>
    );
  }

  return (
    <Container>
      <div className="payment">
        <div className="header">
          <AnimatedIcon
            className="fa fa-check-circle"
            aria-hidden="true"
          ></AnimatedIcon>
          <h2>
            {configMerged.featureFlags.freeLaunch
              ? "Ride Request Sent"
              : "Payment Successful"}
          </h2>
        </div>
        {configMerged.featureFlags.freeLaunch ? (
          <p>
            Please wait for the driver to confirm your seat.Contact or chat with
            the driver for more details.
          </p>
        ) : (
          <p>
            Thanks for the payment.
            {seatConfirmed
              ? "Your seat is confirmed"
              : "Please wait for the driver to confirm your seat"}
            . Contact or chat with the driver for more details. In case of
            cancellation, your payment will be refunded within 1 to 5 days
            according to the cancellation policy.
          </p>
        )}

        {!configMerged.featureFlags.freeLaunch && (
          <p className="details">
            <strong>Transaction ID</strong>: {transactionId}
          </p>
        )}
        <Button to={"/my-bookings"} animation={true}>
          See your bookings
        </Button>
      </div>
    </Container>
  );
};

export default PaymentConfirmed;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  max-width: 767px;
  margin: auto;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
  line-height: 1;

  .payment {
    max-width: 562px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin-top: 3rem;

    .details {
      margin-top: 1rem;
    }

    .header {
      margin: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    h2 {
      color: ${COLORS.primaryTextColor};
      font-weight: 400;
      margin-top: 1rem;
    }

    p {
      color: gray;
      line-height: 1.2;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const AnimatedIcon = styled.i`
  font-size: 72px;
  color: #054752;
  animation: ${spin} 2s linear infinite, ${bounce} 1s ease-in-out infinite;
`;
