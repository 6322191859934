import React, { useState, useRef } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import { Message, Title } from "../NoData/styles"; // Adjust these imports based on your file structure
import verifyImg from "../../images/verify_license.svg";
import { Button } from "../../pages/BookingRequest/styles"; // Adjust based on your file structure
import { toast } from "react-toastify";
import { uploadToCloudinary } from "../../Utils";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_USER } from "../../redux/constants";
import CustomButton from "../UI/Button";
import { COLORS } from "../../assets/colors";

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  .license-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UploadBox = styled.div`
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
  text-align: center;
  position: relative;
  height: 150px;
  width: 100%;
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "none"};
  background-size: cover;
  background-position: center;
`;

const IconButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #007bff;
`;

// Main component
const VerifyDrivingLicense = ({ title, message, checkLicense }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const frontFileInputRef = useRef(null);
  const backFileInputRef = useRef(null);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    checkLicense();
  };

  const handleUploadFront = () => {
    frontFileInputRef.current.click(); // Open file dialog for front of license
  };

  const handleUploadBack = () => {
    backFileInputRef.current.click(); // Open file dialog for back of license
  };

  const handleFileChange = (event, position) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error("Only image files are allowed.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (position === "front") {
          setFrontImage(reader.result); // Set the front image
        } else if (position === "back") {
          setBackImage(reader.result); // Set the back image
        }
      };
      reader.readAsDataURL(file); // Convert image to base64 for preview
    }
  };

  const onUploadLicense = async () => {
    if (!frontImage || !backImage) {
      toast.error("Please upload both images");
      return;
    }
    try {
      // upload to Clodinary
      setLoading(true);
      const frontImageData = await uploadToCloudinary(
        frontImage,
        `license_fr_${currentUser.id}`
      );
      const backImageData = await uploadToCloudinary(
        backImage,
        `license_ba_${currentUser.id}`
      );
      // Save images in DB
      const response = await api.patch(`user/uploadLicense/${currentUser.id}`, {
        license_front: frontImageData.url,
        license_front_public_id: frontImageData.public_id,
        license_back: backImageData.url,
        license_back_public_id: backImageData.public_id,
      });
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.data.user,
      });
      localStorage.removeItem("authToken");
      localStorage.setItem("authToken", response.data.data.token);
      toast.success("Successfully uploaded your license");
      handleClose();
    } catch (e) {
      console.error("error", e.message);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <div className="license-section">
        <img
          src={verifyImg}
          height={"100px"}
          width={"auto"}
          alt="upload driving license"
        />
        <Title>{title}</Title>
        <Message>{message}</Message>

        <Button onClick={handleOpen}>Upload License</Button>
      </div>

      <Modal open={isOpen} onClose={handleClose}>
        <ModalOverlay>
          <ModalContent>
            <h2>Upload Driving License</h2>
            <p>Please upload the front and back of your driving license:</p>

            <UploadBox image={frontImage}>
              <p>Front of License</p>
              <IconButtonContainer>
                <IconButton
                  color="primary"
                  style={{
                    border: `1px solid ${COLORS.borderColor}`,
                    background: frontImage ? "white" : "transparent",
                  }}
                  onClick={handleUploadFront}
                >
                  <AddIcon />
                </IconButton>
              </IconButtonContainer>
              <input
                type="file"
                ref={frontFileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleFileChange(event, "front")}
              />
            </UploadBox>

            <UploadBox image={backImage}>
              <p>Back of License</p>
              <IconButtonContainer>
                <IconButton
                  color="primary"
                  style={{
                    border: `1px solid ${COLORS.borderColor}`,
                    background: backImage ? "white" : "transparent",
                  }}
                  onClick={handleUploadBack}
                >
                  <AddIcon />
                </IconButton>
              </IconButtonContainer>
              <input
                type="file"
                ref={backFileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleFileChange(event, "back")}
              />
            </UploadBox>

            <CloseButton onClick={handleClose}>Close</CloseButton>
            <CustomButton
              disabled={!frontImage || !backImage}
              onClick={onUploadLicense}
              label={loading ? "Uploading..." : "Upload"}
            />
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Container>
  );
};

export default VerifyDrivingLicense;
