/* eslint-disable no-use-before-define */
import React, { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TripOrigin } from "@material-ui/icons";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete as PlacesAutoComplete } from "@react-google-maps/api";

const styles = makeStyles((theme) => ({
  inputStyles: {
    fontSize: "1rem",
    border: "none",
    height: "56px",
    "& label": {
      [theme.breakpoints.down("sm")]: {
        color: "gray",
        paddingLeft: "1rem",
      },
    },
    "& fieldset": {
      border: "none",
      [theme.breakpoints.down("sm")]: {},
    },
  },
  // inputStyles: {
  //   padding: "9.5px 4px",
  // },
  dropdown: {
    width: "250px !important", // Default for desktop
    [theme.breakpoints.down("md")]: {
      maxWidth: "250px",

      width: "80% !important", // Medium devices like tablets (iPads)
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px",

      width: "95% !important", // Small devices like mobile phones
    },
    "& .MuiAutocomplete-listbox": {
      maxWidth: "250px",
      width: "100%", // Ensure the listbox takes the full width of the dropdown
    },
  },
}));

export default function AutoComplete({
  data,
  label,
  handleOnChange,
  value,
  page = null,
  selectedType,
  placeholder,
}) {
  const classes = styles();
  /** @type React.mutableRefObject<HTMLInputElement>*/
  const originRef = useRef();

  React.useEffect(() => {
    // Inject custom styles for pac-container
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .pac-container {
        background-color: #fff !important;
        border-radius: 8px !important;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4) !important;
        font-family:  Roboto, Helvetica, sans-serif !important;
        font-size: 14px !important;
        width:250px !important;
      }
      .pac-item {
        padding: 8px 12px !important;
        color: #333 !important;
      }
      .pac-item:hover {
        background-color: #e6f7ff !important;
      }
      .pac-item-query {
        font-weight: bold !important;
        color: teal !important;
      }
    `;
    document.head.appendChild(styleElement);

    // Clean up on unmount
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleOriginChange = () => {
    const details = originRef.current.getPlace();

    try {
      if (details) {
        const addressComponents = details?.address_components;
        // Extract components for address formatting
        const sublocality1 =
          addressComponents.find((component) =>
            component.types.includes("sublocality1")
          )?.long_name || "";
        const sublocality =
          addressComponents.find((component) =>
            component.types.includes("sublocality")
          )?.long_name || "";
        const locality =
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "";

        // Formatted address based on selectedType

        const formattedAddress = `${sublocality1 ? sublocality1 + "," : ""} ${
          sublocality ? sublocality + "," : ""
        } ${locality}`;

        // Set the input value to the formatted address
        originRef.current.value = formattedAddress;
        handleOnChange(formattedAddress, locality);
      }
    } catch (error) {
      console.error("Error retrieving address components:", error);
    }
  };

  return (
    <React.Fragment>
      {selectedType ? (
        <PlacesAutoComplete
          className="autocomplete-cs"
          options={{
            componentRestrictions: { country: "pk" },
          }}
          onLoad={(autocomplete) => {
            originRef.current = autocomplete;
          }}
          onPlaceChanged={handleOriginChange}
          value={value}
        >
          <StyledInputWrapper className="searchBox">
            <TripOrigin color="primary" />
            <input
              className={classes.inputStyles}
              style={{ outline: "none" }}
              name="address"
              required
              placeholder={placeholder}
              type="text"
              ref={originRef}
            />
          </StyledInputWrapper>
        </PlacesAutoComplete>
      ) : (
        <Autocomplete
          id="auto-complete"
          options={data}
          getOptionLabel={(option) => option.name}
          fullWidth
          onChange={(event, va) => handleOnChange(va, event)}
          value={value}
          classes={{
            popper: classes.dropdown, // Apply custom styles to the dropdown
          }}
          renderInput={(params) => {
            return (
              <StyledInputWrapper className="searchBox">
                <TripOrigin color="primary" />
                <TextField
                  size="small"
                  {...params}
                  label={label}
                  className={page ? classes.inputStyles : null}
                  variant={window?.innerWidth < 746 ? "outlined" : "outlined"}
                />
              </StyledInputWrapper>
            );
          }}
        />
      )}
    </React.Fragment>
  );
}

const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
