import React, { useEffect, useState } from "react";
import UserProfile from "../../components/ProfileComponents";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import { SET_CURRENT_USER, SET_USER_AVATAR } from "../../redux/constants";
import { getAge } from "../../Utils";
import CircularLoader from "../../components/UI/Loader";

function Profie() {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => {
    return state.auth.currentUser;
  });

  useEffect(() => {
    async function getUserById() {
      try {
        setLoading(true);
        const response = await api.get(`user/${userId}`);
        setUserProfile(response.data.data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
    getUserById();
  }, [userId]);

  const uploadImage = (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "silkcabs");
    data.append("cloud_name", "dqmvr7nnr");

    fetch("https://api.cloudinary.com/v1_1/dqmvr7nnr/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then(async (data) => {
        const { url, public_id } = data;
        const res = await api.patch(`user/uploadAvatar/${userId}`, {
          url,
          public_id,
        });
        setUserProfile({
          ...userProfile,
          avatar_url: res.data.data.user.avatar_url,
        });
        dispatch({
          type: SET_CURRENT_USER,
          payload: res.data.data.user,
        });
        localStorage.removeItem("authToken");
        localStorage.setItem("authToken", res.data.data.token);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadCnicImage = (image, type) => {
    let res;
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "silkcabs");
    data.append("cloud_name", "dqmvr7nnr");

    fetch("https://api.cloudinary.com/v1_1/dqmvr7nnr/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then(async (data) => {
        const { url, public_id } = data;
        res = await api.patch(`user/uploadCnic/${userId}`, {
          url,
          public_id,
          type,
        });
        if (type == "back") {
          setUserProfile({
            ...userProfile,
            cnic_back: res.data.data.user.cnic_back,
          });
        }
        if (type == "front") {
          setUserProfile({
            ...userProfile,
            cnic_front: res.data.data.user.cnic_front,
          });
        }

        dispatch({
          type: SET_CURRENT_USER,
          payload: res.data.data.user,
        });
        localStorage.removeItem("authToken");
        localStorage.setItem("authToken", res.data.data.token);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSetUserProfile = (data) => {
    setUserProfile(data);
  };

  const name =
    userProfile?.first_name.charAt(0).toUpperCase() +
    userProfile?.first_name.slice(1);
  const memberSince = userProfile?.created_at;
  const isMe = currentUser?.id === (userProfile?.id || userId) ? true : false;
  if (loading) {
    return <CircularLoader isLoading={loading} />;
  }
  return (
    <PageContainer>
      <UserProfile
        uploadCnicImage={uploadCnicImage}
        uploadImage={uploadImage}
        isMe={isMe}
        name={name}
        age={getAge(userProfile?.dob)}
        aboutMe={userProfile?.about_me || "I’m chatty when I feel comfortable"}
        rating={userProfile?.rating}
        ratingCount={userProfile?.Feedbacks?.length}
        reviews={"Review"}
        avatar={
          userProfile?.avatar_url || `https://ui-avatars.com/api/?name=${name}`
        }
        phoneVerified={userProfile?.otp_verified || null}
        handleSetUserProfile={handleSetUserProfile}
        loggedInUserId={currentUser?.id}
        memberSince={memberSince}
        ridesPublished={userProfile?.ridesPublished}
        hasProfilePicUploaded={userProfile?.avatar_url ? true : false}
        cnicUploaded={
          userProfile?.cnic_front && userProfile?.cnic_back ? true : false
        }
        cnicFront={userProfile?.cnic_front}
        cnicBack={userProfile?.cnic_back}
        cnicVerified={userProfile?.cnic_verified}
      />
    </PageContainer>
  );
}

export default Profie;
const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
