import React, { useState } from "react";
import styled from "styled-components";
import AdminSider from "../../components/AdminPanel/AdminSider";
import Dashboard from "../../components/AdminPanel/Dashboard";
import Request from "../../components/AdminPanel/adminRequest";
import RidesInfo from "../../components/AdminPanel/ridetab";
import Customers from "../../components/AdminPanel/customers";
import Report from "../../components/AdminPanel/adminReport";
import BannedUsers from "../../components/AdminPanel/bannedUsers";
import WithdrawalRequests from "../../components/AdminPanel/withdrawals";
import Bookings from "../../components/AdminPanel/bookings";
import Route from "../../components/AdminPanel/PopularRoutes";
import Analytics from "../../components/AdminPanel/analytics/Analytics";

function AdminPage() {
  const [currentItem, setCurrentItem] = useState("dashboard");
  const handleItem = (item) => {
    setCurrentItem(item);
  };
  return (
    <AdminWrapper>
      <div className="sider">
        <AdminSider handleItem={handleItem} currentItem={currentItem} />
      </div>
      <div className="content">
        {currentItem === "dashboard" && <Dashboard />}
        {currentItem === "requests" && <Request />}
        {currentItem === "rides" && <RidesInfo />}
        {currentItem === "customers" && <Customers />}
        {currentItem === "reports" && <Report />}
        {currentItem === "banned" && <BannedUsers />}
        {currentItem === "withdrawals" && <WithdrawalRequests />}
        {currentItem === "bookings" && <Bookings />}
        {currentItem === "route" && <Route />}
        {currentItem === "analytics" && <Analytics />}
      </div>
    </AdminWrapper>
  );
}

export default AdminPage;

const AdminWrapper = styled.div`
  display: flex;
  height: 100%;

  .sider {
    width: 100%;
    max-width: 14rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    height: 100vh;
  }

  .content {
    width: 100%;
    padding: 0.5rem;
    height: fit-content;
    background-color: #f4f6fb;
    padding-left: 16rem;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    overflow-x: scroll;
    .content {
      padding: 1rem;
    }
  }
`;
