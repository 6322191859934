import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SearchForm from "../../components/SearchForm";
import { GlobalContext } from "../../contexts/globalContext";
import { COLORS } from "../../assets/colors";
import styled from "styled-components";
import { Clear, History } from "@material-ui/icons";
import mixins from "../../mixins";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexFlow: 1,
  },
  pageHeader: {
    fontWeight: 600,
  },
  searchButton: {
    backgroundColor: "rgb(237, 237, 237)",
    textTransform: "none",
    margin: theme.spacing(1),
    padding: theme.spacing(2, 1),
    textAlign: "left",
    borderRadius: theme.spacing(2),
  },
  searchBottomSection: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
function FindRide() {
  const { searchFields, setSearchFields } = useContext(GlobalContext);
  const [searches, setSearches] = useState();
  const navigate = useNavigate();

  const classes = useStyles();

  useEffect(() => {
    const s = localStorage.getItem("searches");
    if (s) {
      setSearches(JSON.parse(s));
    }
  }, []);
  const handleSearch = (item) => {
    setSearchFields({
      leavingFrom: item.leavingFrom,
      goingTo: item.goingTo,
      seats: item.seats,
    });
    const searchParams = new URLSearchParams({ ...item, offset: 0 });
    const queryString = searchParams.toString();
    navigate(`/ride-sharing?${queryString}`);
  };
  const clearHistory = () => {
    localStorage.removeItem("searches");
    setSearches(null);
  };
  return (
    <div>
      <Helmet>
        <title>{`Find a Ride - GoSawari`}</title>

        {/* Dynamic meta description with a focus on the city */}
        <meta
          name="description"
          content={`Find and book carpool rides with GoSawari.`}
        />

        {/* Canonical link for the specific selectedCity */}
        <link rel="canonical" href={`https://gosawari.com/search-rides`} />
      </Helmet>
      <Container maxWidth="md" className={classes.root}>
        <Box p={4}>
          <Typography
            align="center"
            variant="h4"
            color="secondary"
            className={classes.pageHeader}
          >
            Find a Ride
          </Typography>
        </Box>
        <Box>
          <SearchForm
            searchFields={searchFields}
            setSearchFields={setSearchFields}
          />
        </Box>
        <Wrapper>
          {searches?.length && (
            <div className="top">
              <h3>Recent searches</h3>
              <Tooltip title={"Clear history"}>
                <IconButton size="small" onClick={clearHistory}>
                  <Clear />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {searches?.length &&
            searches.map((item) => {
              return (
                <RecentSearchedItem onClick={(e) => handleSearch(item)}>
                  <History />
                  <p>
                    {item.leavingFrom} to {item.goingTo}, {item.seats} seat(s){" "}
                  </p>
                </RecentSearchedItem>
              );
            })}
        </Wrapper>
      </Container>
    </div>
  );
}

export default FindRide;

const Wrapper = styled.div`
  max-height: 14rem;
  overflow-y: auto;
  width: 100%;
  ${mixins.scrollStyle};
  .top {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    h3 {
      margin-bottom: 0.5rem;
      color: ${COLORS.secondaryColor};
    }
    button {
      margin-top: -4px;
    }
  }
`;
const RecentSearchedItem = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
  align-items: center;

  svg {
    fill: darkgray;
  }
  p {
    color: darkgray;
  }
  :hover {
    font-weight: bold;
  }
`;
