import styled from "styled-components";
import { CloudDownloadOutlined, RefreshRounded } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import RoutesTable from "./RoutesTable";
import { useState } from "react";
import CircularLoader from "../../UI/Loader";

function Route() {
  const [rides, setRides] = useState(null);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [ridesCount, setRidesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageSize = 6;
  const limit = 6;
  const offset = (page - 1) * pageSize;
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Wrapper>
      <div className="customer-nav">
        <Nav>
          <NavContainer>
            <NavLogo>
              <Typography>GoSawari Popular Routes</Typography>
            </NavLogo>
            <NavMenu>
              <NavMenuItem>
                <IconButton>
                  <RefreshRounded style={{ color: "white" }} />
                </IconButton>
              </NavMenuItem>
              <IconButton>
                <CloudDownloadOutlined style={{ color: "white" }} />
              </IconButton>
            </NavMenu>
          </NavContainer>
        </Nav>
      </div>
      <div className="ride-table">
        {loading ? (
          <CircularLoader isLoading={loading} />
        ) : (
          <RoutesTable rides={rides} />
        )}
      </div>

      <div className="pagination">
        <Pagination onChange={handleChange} size="large" color="primary" />
        {/* <RoutesTable /> */}
      </div>
    </Wrapper>
  );
}
export default Route;

const Wrapper = styled.div`
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 4px;
  height: 100%;
  .ride-table {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .pagination {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    padding: 2rem;
    margin-bottom: 4rem;
  }
`;

const Nav = styled.nav`
  // background-color: #f4f6fb;
  color: #fff;
  padding: 1rem;
  color: white;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgb(7 30 28) 0%,
    rgba(5, 71, 82, 1) 60%,
    rgb(23 104 120) 100%
  );
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavLogo = styled.div`
  font-size: 2.5rem;
  color: #054752;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 2rem;
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
`;

const NavMenuItem = styled.li`
  font-size: 1.5rem;
  color: #054752;
`;
const Typography = styled.div`
  font-family: sans-serif;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
`;
