import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import CustomButton from "../../components/UI/Button";
import { UPDATE_CURRENT_RIDE_INFO } from "../../redux/constants";
import api from "../../services/api";
import send from "../../assets/images/send.svg";
import moment from "moment";
import ConfirmModal from "../../components/models/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function RidePublishSuccess({ rideId, handleNext }) {
  const [currentRide, setCurrentRide] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publishRideInfo = useSelector((state) => state.publishRideInfo);
  const {
    id: userId,
    first_name,
    email,
  } = useSelector((state) => state.auth.currentUser);
  const isCompleted = publishRideInfo?.currentRideInfo?.is_completed;

  useEffect(async () => {
    if (rideId) {
      const response = await api.get(`ride/${rideId}`);
      setCurrentRide(response.data.data);
    }
  }, [rideId]);

  const markRideAsComplete = async (rideId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "ride_creation_publish_start", // Custom event name
      category: "Button", // Category of the event
      action: "Click", // Action (what user did)
      label: "Publish created ride start", // Label to identify the element
      rideId: rideId,
      userId: userId,
      userName: first_name,
      userEmail: email,
    });
    const response = await api.patch(`ride/markAsComplete/${userId}/${rideId}`);
    const currentRideInfo = response.data.data;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "successfully published", // Custom event name
      category: "Button", // Category of the event
      action: "Click", // Action (what user did)
      label: "Ride published successfully", // Label to identify the element
      rideId: rideId,
      userId: userId,
      userName: first_name,
      userEmail: email,
    });
    dispatch({
      type: UPDATE_CURRENT_RIDE_INFO,
      payload: { currentRideInfo },
    });
    handleNext();
  };
  const deleteRide = async (rideId) => {
    try {
      const response = await api.post("ride/delete", {
        id: rideId,
      });
      if (response.data.data) {
        localStorage.removeItem("activeStep");
        toast.info("Ride Deleted");
        setShowConfirm(false);
        navigate("/publish");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageContainer>
      {!isCompleted && (
        <div className="publish-ride">
          <h2>Publish your Ride</h2>
          <p>
            Publish your ride to get requests from the people who might want to
            travel with you.
          </p>

          {currentRide && (
            <div className="ride-details">
              <p>
                <span>Leaving From:</span> {currentRide?.pickup_location}
              </p>
              <p>
                <span> Going To:</span> {currentRide?.drop_location}
              </p>
              <p>
                <span>Departure Date:</span>{" "}
                {moment(currentRide?.departure_date).format("MMMM Do, YYYY")}
              </p>
              <p>
                <span>Departure Time:</span>{" "}
                {moment(currentRide?.departure_time).format("hh:mm A")}
              </p>
            </div>
          )}
          <div className="btn-group">
            <div className="delete-ride">
              <button onClick={() => setShowConfirm(true)}>Delete Ride</button>
            </div>

            <div className="publish-ride-btn">
              <CustomButton
                label="Publish Ride"
                onClick={() => markRideAsComplete(rideId)}
              />
            </div>
          </div>
        </div>
      )}
      {showConfirm && (
        <ConfirmModal
          title={"Delete Ride"}
          content={"Are you sure you want to delete this ride?"}
          open={showConfirm}
          handleClose={() => setShowConfirm(false)}
          confirmBtnText={"Confirm"}
          cancelBtnText={"Cancel"}
          onConfirm={() => {
            deleteRide(rideId);
          }}
        />
      )}
    </PageContainer>
  );
}

export default RidePublishSuccess;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  .publish-ride {
    padding: 1rem;
    max-width: 662px;
    margin: 0 auto;
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 185px;
    border-radius: 8px;
    justify-content: space-around;
    h2 {
      color: ${COLORS.primaryColor};
      margin-bottom: 0.5rem;
    }
    .ride-details {
      width: auto;
      height: auto;
      margin-bottom: 1rem;
      border: 1px solid ${COLORS.borderColor};
      border-radius: 8px;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      font-size: 16px;
      letter-spacing: 0.00938em;
      text-align: center;
      color: gray;
      margin-bottom: 1rem;
      text-align: left;
      span {
        font-weight: bold;
        color: #454545;
      }
    }
  }
  .btn-group {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 8rem;
    .delete-ride {
      button {
        padding: 1rem 2rem;
        border: none;
        border: 1px solid orangered;
        border-radius: 8px;
        color: orangered;
        background: transparent;
        cursor: pointer;
      }
    }
    .publish-ride-btn {
      max-width: 40%;
      margin-bottom: 5rem;

      @media (max-width: 546px) {
        margin-bottom: 5rem;
        max-width: 100%;
      }
    }
  }

  .img {
    display: flex;
    justify-content: center;
    img {
      width: 89px;
    }
  }
`;
