import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { CheckCircleRounded } from "@material-ui/icons";
import { COLORS } from "../../assets/colors";

function RideSuccess({ rideId }) {
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      localStorage.removeItem("rideId");
      localStorage.removeItem("activeStep");
    };
  }, []);

  return (
    <Container>
      <Body>
        <AnimatedCheckCircleRounded className="icon" />
        <h4>Ride Published!</h4>
        <Message>
          <Typography
            align="center"
            gutterBottom
            variant="body2"
            color="secondary"
          >
            <span className="congrats">Congratulations!</span> You have
            successfully published your ride. Click the link below to see your
            published ride.
          </Typography>
        </Message>
        <span
          className="see-ride"
          onClick={() => navigate(`/active-ride/${rideId}`)}
        >
          <Typography variant="body1" color="primary">
            See your Ride
          </Typography>
        </span>
      </Body>
    </Container>
  );
}

export default RideSuccess;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 600;
`;

const Body = styled.div`
  padding: 60px;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin-top: 1rem;
    @media (max-width: 546px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 546px) {
    padding: 0;
  }
  :nth-child(1) {
    color: ${COLORS.primaryColor};
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 1rem;
    max-width: 662px;
    @media (max-width: 546px) {
      margin-top: 0.2rem;
    }
    @media (max-width: 320px) {
      margin-top: 0.2rem;
    }
  }
  .icon {
    font-size: 7rem;
    @media (max-width: 546px) {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  .congrats {
    color: orange;
    font-weight: bold;
  }

  .see-ride {
    p {
      color: ${COLORS.primaryTextColor};
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

const Message = styled.p`
  margin-top: 1rem;

  // margin: 0;
  color: #999999;
  text-decoration: none;
  text-transform: none;
  font-weight: 400;
  @media (max-width: 546px) {
    margin-top: 0rem;
  }

  p {
    font-size: 18px;
    @media (max-width: 546px) {
      font-size: 14px;
      padding: 10px;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const AnimatedCheckCircleRounded = styled(CheckCircleRounded)`
  animation: ${spin} 1s linear infinite, ${bounce} 1s ease-in-out infinite;
  .typo {
    margin-top: 2rem;
  }
`;
