import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import femaleRide from "../../images/female.svg";

const FemaleOnly = () => {
  return (
    <StyledWrapper>
      <div className="content">
        <div className="left">
          <div>
            <h1>Are you a female?</h1>
            <p>
              GoSawari offers exclusive, women-only rides where both the driver
              and passengers are female, ensuring a safe and private travel
              experience for women across Pakistan.
            </p>
          </div>
          <Link style={{ textDecoration: "none" }} to="/publish">
            <Button
              style={{
                borderRadius: "5xpx",
                maxWidth: "150px",
                marginTop: "5px",
              }}
              variant="contained"
              color="primary"
            >
              Offer ride
            </Button>
          </Link>
        </div>
        <div className="right">
          <img src={femaleRide} alt="female-rider-pakistan-carpool" />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default FemaleOnly;

const StyledWrapper = styled.div`
  background: whitesmoke;
  padding: 2rem;
  .content {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      h1 {
        color: rgb(5, 71, 82);
        font-weight: 500;
        line-height: 1.06;
        margin-bottom: 1rem;
      }
      p {
        color: rgb(112, 140, 145);
        max-width: 600px;
      }
    }
    .right {
      @media (max-width: 600px) {
        display: none;
      }
      img {
        max-width: 350px;
        width: 100%;
        @media (max-width: 600px) {
          max-width: 300px;
        }
      }
    }
    @media (min-width: 1200px) {
      padding-left: 4rem;
    }
  }
`;
