import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PasswordReset from "./pages/PasswordReset";
import ForgetPassword from "./pages/ForgetPassword";
import Home from "./pages/Home";
import RideSharing from "./pages/RideSharing";
import Trip from "./pages/Trip";
import Otp from "./pages/Otp";
import ProtectedRoute from "./hoc/PrivateRoutes";
import Profile from "./pages/UserProfile";
import Inbox from "./pages/UserInbox";
import Payments from "./pages/Paymentan&Transfer";
import Publish from "./pages/PublishRides";
import Settings from "./pages/Settings";
import Yourrides from "./pages/YourRides";
import Payment from "./pages/Payment";
import BookingRequest from "./pages/BookingRequest";
import Booking from "./pages/MyBookings";
import RideRequests from "./pages/RideRequests";
import Trending from "./pages/Ask";
import TermAndConditions from "./pages/TermAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/AboutUs";
import ReviewsAndRating from "./components/ReviewsAndRatings";
import PaymentConfirmed from "./pages/PayementSent";
import ShowLocation from "./pages/ShowLocation";
import AdminPage from "./pages/Admin";
import { GlobalContext } from "./contexts/globalContext";
import AdminRoute from "./hoc/AdminRoutes";
import UnauthorizedPage from "./pages/UnAuthorized";
import AuthRoute from "./hoc/AuthRoutes";
import AddReview from "./pages/AddReview";
import ActiveRide from "./pages/ActiveRide";
import { socket } from "./socket";
import FindRide from "./pages/FindRide";
import ContactUs from "./pages/ContactUs";
import PaymentCancel from "./pages/PaymentCancel";
import Notifications from "./pages/Notifications";
import ChangePassword from "./pages/ChangePassword";
import NotFound from "./pages/NotFound";
import GetPhoneNumber from "./pages/GetPhoneNumber";
import Dob from "./pages/GetPhoneNumber/Dob";
import MyRefunds from "./pages/MyRefunds";
import CarSawarPolicy from "./pages/CarSawarPolicy";
import MyVehicles from "./pages/MyVehicles";
import UnAuthRoute from "./hoc/UnAuthRoutes";
import GroupTours from "./pages/Toures";
import GroupToursDetail from "./pages/GroupToursDetail";
import GroupTourForm from "./pages/GroupTourForm";
import GroupSuccessPage from "./pages/GroupSuccessPage";
import TourPayment from "./pages/Toures/TourPayment";
import TourPaymentSuccess from "./pages/Toures/TourPaymentSuccess";
import GroupPaymentCancellation from "./pages/Toures/GroupPaymentCancellation";
import License from "./pages/License";

const AppRouter = () => {
  const { notificationCount, setNotificationCount } = useContext(GlobalContext);
  useEffect(() => {
    socket.on("increment", (count) => {
      setNotificationCount(notificationCount + 1);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading... </div>}>
        <Routes>
          <Route
            path="/login"
            exact
            element={
              <UnAuthRoute>
                <Login socket={socket} />
              </UnAuthRoute>
            }
          />
          <Route path="/" exact element={<Home />} />
          <Route
            path="/register"
            exact
            element={
              <UnAuthRoute>
                <Register />
              </UnAuthRoute>
            }
          />
          <Route
            path="/reset-password"
            exact
            element={
              <UnAuthRoute>
                <PasswordReset />
              </UnAuthRoute>
            }
          />
          <Route
            path="/forget-password"
            exact
            element={
              <UnAuthRoute>
                <ForgetPassword />
              </UnAuthRoute>
            }
          />
          <Route path="/ride-sharing" exact element={<RideSharing />} />
          <Route path="/ride/:rideId" exact element={<Trip />} />
          <Route path="/otp" exact element={<Otp />} />
          <Route
            path="/terms-conditions"
            exact
            element={<TermAndConditions />}
          />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="/carpool" element={<Trending />} />

          <Route path="/search-rides" element={<FindRide />} />
          <Route path="/carpool/showOnMap" element={<ShowLocation />} />
          <Route path="/active-ride/:rideId" element={<ActiveRide />} />
          <Route path="/resetPassword/:token" element={<ChangePassword />} />
          <Route path="/un-authorized" element={<UnauthorizedPage />} />
          <Route path="/get-phone-number" element={<GetPhoneNumber />} />
          <Route path="/dob" element={<Dob />} />
          <Route path="/gosawari-refund-policy" element={<CarSawarPolicy />} />
          {/* <Route path="/group-tours" element={<GroupTours />} /> */}
          {/* <Route path="/group-tours/:id" element={<GroupToursDetail />} /> */}
          {/* <Route path="/group-tours/create" element={<GroupTourForm />} /> */}
          {/* <Route path="/group-tour/publish" element={<GroupSuccessPage />} /> */}
          {/* <Route path="/tour-payment" element={<TourPayment />} />{" "} */}
          {/* <Route path="/payment-success" element={<TourPaymentSuccess />} /> */}
          {/* <Route
            path="/payment-cancellation"
            element={<GroupPaymentCancellation />}
          /> */}
          {/* Add TourPayment route */}
          <Route
            path="/admin"
            exact
            element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            }
          />
          <Route
            path="/your-rides"
            element={
              <ProtectedRoute>
                <Yourrides />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-refunds"
            element={
              <ProtectedRoute>
                <MyRefunds />
              </ProtectedRoute>
            }
          />
          <Route
            path="/your-rides/showOnMap"
            element={
              <ProtectedRoute>
                <ShowLocation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/:userId"
            element={
              <AuthRoute>
                <Profile />
              </AuthRoute>
            }
          />
          <Route path="/add-review/:rideId" element={<AddReview />} />
          <Route
            path="/profile/:userId/reviews"
            element={
              <ProtectedRoute>
                <ReviewsAndRating />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <ProtectedRoute>
                <Inbox socket={socket} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthRoute>
                <Settings />
              </AuthRoute>
            }
          />
          <Route
            path="/publish"
            element={
              <ProtectedRoute>
                <Publish />
              </ProtectedRoute>
            }
          />
          <Route
            path="/license"
            element={
              <ProtectedRoute>
                <License />
              </ProtectedRoute>
            }
          />
          <Route
            path="active-ride/:rideId/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path="active-ride/:rideId/payment/confirm/:transactionId"
            element={
              <ProtectedRoute>
                <PaymentConfirmed />
              </ProtectedRoute>
            }
          />
          <Route
            path="active-ride/:rideId/payment/cancel/:transactionId"
            element={
              <ProtectedRoute>
                <PaymentCancel />
              </ProtectedRoute>
            }
          />
          <Route
            path="active-ride/:rideId/showOnMap"
            element={<ShowLocation />}
          />
          <Route path="ride-sharing/showOnMap" element={<ShowLocation />} />
          <Route path="ride/:rideId/showOnMap" element={<ShowLocation />} />
          <Route
            path="/booking"
            element={
              <ProtectedRoute>
                <BookingRequest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-bookings"
            element={
              <ProtectedRoute>
                <Booking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ride-requests"
            element={
              <ProtectedRoute>
                <RideRequests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-vehicles"
            element={
              <ProtectedRoute>
                <MyVehicles />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default AppRouter;
