import React, { useRef, useState } from "react";
import styled from "styled-components";
import { CameraAltRounded, VerifiedUser } from "@material-ui/icons";
import { COLORS } from "../../assets/colors";
import { FaMobile, FaEnvelope, FaStar, FaIdCard } from "react-icons/fa";
import EditButton from "../UI/IconButton";
import AboutModal from "./aboutModal";
import api from "../../services/api";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../models/ConfirmModal";
import ReportUser from "../models/ReportUser";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import CnicModal from "./CnicModal";

const Profile = ({
  uploadCnicImage,
  uploadImage,
  isMe,
  age,
  avatar,
  name,
  aboutMe,
  phoneVerified,
  handleSetUserProfile,
  loggedInUserId,
  memberSince,
  rating,
  ratingCount,
  ridesPublished,
  hasProfilePicUploaded,
  cnicUploaded,
  cnicFront,
  cnicBack,
  cnicVerified,
}) => {
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReportModel, setShowReportModel] = useState(false);
  const [showCnicUploadModal, setShowCnicUploadModal] = useState(false);
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (!fileObj.type.startsWith("image/")) {
      toast.error("Only image files are allowed.");
      return;
    }
    event.target.value = null;
    uploadImage(fileObj);
  };

  const handleUpdateAboutMe = async (aboutMe) => {
    await api
      .patch(`user/updateAboutMe/${loggedInUserId}`, {
        userId: loggedInUserId,
        aboutMe,
      })
      .then((result) => {
        handleSetUserProfile(result.data.data);
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  const handleCNICUpload = () => {
    setShowCnicUploadModal(!showCnicUploadModal);
  };

  const handleCnicChange = (event, type) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (!fileObj.type.startsWith("image/")) {
      toast.error("Only image files are allowed.");
      return;
    }
    event.target.value = null;
    uploadCnicImage(fileObj, type);
  };
  const isVerified = phoneVerified && cnicVerified;
  return (
    <ProfileWrapper>
      <div className="section-content">
        <div>
          <Name>{name}</Name>
          <Age>{age} y/o</Age>
        </div>
        <div className="avatarContainer">
          <Avatar
            style={{
              border: isVerified ? `3px solid ${COLORS.linkColor}` : "none",
            }}
            src={avatar}
            alt="avatar"
          />
          {isVerified && (
            <span className="verifiedBage">
              <VerifiedUser
                style={{ color: COLORS.linkColor }}
                fontSize="large"
              />
            </span>
          )}
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
          />
          {isMe && (
            <UploadBtn onClick={handleClick} isVerified={isVerified}>
              <CameraAltRounded color="secondary" />
            </UploadBtn>
          )}
        </div>
      </div>
      <div className="section-content">
        {isVerified && (
          <VerifiedProfile>{name} has a Verified Profile</VerifiedProfile>
        )}
      </div>
      <div className="section-content">
        <span>
          <span>
            <ListItem>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {phoneVerified && <Text>Confirmed phone number</Text>}
            </ListItem>
          </span>
          <span>
            <ListItem>
              <IconWrapper>
                <FaEnvelope color="beige" />
              </IconWrapper>
              <Text>Confirmed email</Text>
            </ListItem>
          </span>
          {cnicVerified && (
            <span>
              <ListItem>
                <IconWrapper>
                  <FaIdCard color="beige" />
                </IconWrapper>
                <Text>Verified ID Card</Text>
              </ListItem>
            </span>
          )}
          {ratingCount > 0 ? (
            <span className="ratings" onClick={() => navigate("reviews")}>
              <ListItem>
                <IconWrapper>
                  <StarIcon />
                </IconWrapper>
                <Text>
                  {parseFloat(rating).toFixed(1)}/5 - {ratingCount} ratings
                </Text>
              </ListItem>
            </span>
          ) : (
            <span className="ratings" onClick={() => navigate("reviews")}>
              <ListItem>
                <IconWrapper>
                  <StarIcon />
                </IconWrapper>
                <Text>No ratings yet</Text>
              </ListItem>
            </span>
          )}
        </span>
      </div>
      <div className="alert-user">
        {isMe && !hasProfilePicUploaded && (
          <>
            <Alert severity="info">
              Please upload your profile picture. It should be original and your
              face should be clearly visible.
            </Alert>
            <Button
              className="uploading-btn"
              variant="contained"
              color="primary"
              style={{
                borderRadius: "16px",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
              onClick={handleClick}
            >
              Upload Profile Photo
            </Button>
          </>
        )}
        {isMe && !cnicVerified && cnicUploaded && (
          <>
            <Alert severity="info">
              Your CNIC verification is under process.It may take 2 to 3 working
              days to complete.
            </Alert>
          </>
        )}
        {isMe && !cnicUploaded && (
          <>
            <Alert severity="info">
              Make your profile more trust worthy by uploading your CNIC
              (National Identity Card).
            </Alert>
            <div className="upload-btn">
              <Button
                variant="contained"
                color="primary"
                style={{ borderRadius: "16px", marginTop: "0.5rem" }}
                onClick={handleCNICUpload}
              >
                Upload CNIC
              </Button>
            </div>
          </>
        )}
      </div>

      <Divider>
        <hr />
      </Divider>
      <div className="section-content">
        <AboutMeSection
          onMouseLeave={() => setShowEditIcon(false)}
          onMouseEnter={() => setShowEditIcon(true)}
        >
          <h2>About {name}</h2>
          <p>
            {aboutMe}{" "}
            {isMe && (
              <span>
                <EditButton onClick={() => setIsOpen(true)} />
              </span>
            )}
          </p>
        </AboutMeSection>
      </div>

      <Divider>
        <hr />
      </Divider>
      <div className="section-content">
        <AboutMeSection>
          <p>
            {ridesPublished} {ridesPublished > 1 ? "rides" : "ride"} published
          </p>
          <p>Member since {moment(memberSince).format("MMMM YYYY")}</p>
        </AboutMeSection>
      </div>
      <Divider>
        <hr />
      </Divider>
      {!isMe && (
        <div className="section-content">
          <Report>
            <p className="report" onClick={() => setShowConfirm(true)}>
              Report this member
            </p>
          </Report>
        </div>
      )}

      {isOpen && (
        <AboutModal
          isOpen={isOpen}
          handleUpdateAboutMe={handleUpdateAboutMe}
          setIsOpen={setIsOpen}
          defaultValue={aboutMe}
        />
      )}
      {showCnicUploadModal && (
        <CnicModal
          isOpen={showCnicUploadModal}
          setIsOpen={setShowCnicUploadModal}
          handleCnicChange={handleCnicChange}
          cnicFront={cnicFront}
          cnicBack={cnicBack}
        />
      )}
      {showConfirm && (
        <ConfirmModal
          title={"Report this user"}
          content={"Are you sure you want to report this user?"}
          open={showConfirm}
          handleClose={() => setShowConfirm(false)}
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          onConfirm={() => {
            setShowReportModel(true);
            setShowConfirm(false);
          }}
        />
      )}
      {showReportModel && (
        <ReportUser
          title={"Report User"}
          cancelBtnText={"Cancel"}
          handleClose={() => setShowReportModel(false)}
          confirmBtnText={"Submit report"}
          open={showReportModel}
        />
      )}
    </ProfileWrapper>
  );
};

export default Profile;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: ${COLORS.primaryColor};
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const Icon = styled(FaMobile)`
  color: beige;
`;
const StarIcon = styled(FaStar)`
  color: #ffd700;
`;

const Text = styled.p`
  font-size: 16px;
  color: ${COLORS.secondaryTextColor};
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 864px;
  margin: auto;
  @media (max-width: 546px) {
    padding-bottom: 4rem;
  }
  .section-content {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 662px;
    .ratings {
      :hover {
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
  .avatarContainer {
    position: relative;
    .verifiedBage {
      right: -8px;
      border: 1px solid deepskyblue;
      top: 53px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid deepskyblue;
      height: 40px;
      position: absolute;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .upload-btn {
    @media (max-width: 546px) {
      margin-left: 1rem;
    }
  }
`;

const VerifiedProfile = styled.h2`
  color: ${COLORS.primaryTextColor};
  font-size: 22px;
  font-weight: 600;
  /* margin-bottom: 0.5rem; */
`;

const Avatar = styled.img`
  width: 92px;
  height: 92px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 30px;
`;

const Name = styled.span`
  color: ${COLORS.primaryTextColor};
  font-size: 30px;
  line-height: 1.06;
  font-weight: 500;
`;

const AboutMeSection = styled.div`
  h2 {
    color: ${COLORS.primaryTextColor};
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  p {
    color: ${COLORS.secondaryTextColor};
  }
`;
const UploadBtn = styled.div`
  position: absolute;
  top: 10;
  top: 54px;
  right: ${(props) => (props.isVerified ? "unset" : "-4px")};
  background: whitesmoke;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Age = styled.div`
  font-size: 16px;
  color: ${COLORS.secondaryTextColor};
  margin-bottom: 30px;
`;

export const Divider = styled.div`
  padding: 10px 0;
  width: 75%;
  @media (max-width: 600px) {
    width: 100%;
  }
  hr {
    border-top-width: 0px;
    height: 8px;
    border-left-width: 0px;
    border-radius: 16px;
    background-color: rgb(237, 237, 237);
  }
`;
const Report = styled.div`
  p {
    color: red;
    margin-bottom: 2rem;
    cursor: pointer;
    @media (max-width: 546px) {
      margin-bottom: 4rem;
    }
  }
`;
