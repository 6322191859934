import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import AutoComplete from "../UI/AutoComplete";
import { useSelector } from "react-redux";
import CircularLoader from "../UI/Loader";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "1rem",
    "& .MuiSvgIcon-root": {
      marginBlock: "-24px",
    },
  },
  carpoolType: {
    display: "flex",
    // gap: "1rem",
    marginBottom: "0.5rem",
    width: "max-content",
    background: "white",
    color: "black",
    borderRadius: "20px",
  },
  buttonStyle: {
    border: "none",
    background: "none",
    padding: "0.5rem 1rem",
    borderRadius: "16px",
    fontSize: "1.2rem",
  },
  selectedButton: {
    background: `${COLORS.primaryColor}`,
    color: "white",
  },
  BoxStyles: {
    borderRadius: "16px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    minHeight: "48px",
    boxShadow: "0px 1px 8px 0px hsla(0, 0%, 0%, 0.2)",
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px 10px 10px",
    },
  },
  searchBox: {
    height: "100%",
    backgroundColor: "#054752",
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
    },
  },
  searchContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  selectFocused: {
    backgroundColor: "white",
  },
  datepicker: {
    marginTop: "1rem",
    border: "none",
    height: "2rem",
    width: "inherit",
    [theme.breakpoints.down("xs")]: {
      borderBottom: `1px solid ${COLORS.borderColor}`,
      backgroundColor: "transparent",
      fontSize: "1rem",
      color: "black",
    },
  },
  inputLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
      [theme.breakpoints.down("xs")]: {
        color: "gray",
      },
    },
  },
  hardCodedText: {
    color: `${COLORS.secondaryTextColor}`,
  },
  selectMenu: {
    minHeight: "unset",
    maxHeight: 300,
  },
  selectStyles: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  inputStyles: {
    fontSize: "1.2rem",
    border: "none",
    "& label": {
      [theme.breakpoints.down("sm")]: {
        color: "gray",
        paddingLeft: "1rem",
      },
    },
    "& fieldset": {
      border: "none",
      [theme.breakpoints.down("sm")]: {},
    },
  },
  dropdown: {
    width: "250px !important", // Default for desktop
    [theme.breakpoints.down("md")]: {
      maxWidth: "250px",

      width: "80% !important", // Medium devices like tablets (iPads)
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px",

      width: "95% !important", // Small devices like mobile phones
    },
    "& .MuiAutocomplete-listbox": {
      maxWidth: "250px",
      width: "100%", // Ensure the listbox takes the full width of the dropdown
    },
  },
}));

export default function SearchForm({
  searchFields,
  setSearchFields,
  handleCloseModal = () => {},
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: cities } = useSelector((state) => state.cities);
  const [selectedType, setSelectedType] = useState("city-to-city");

  const handleLeavingFrom = (data, locality) => {
    try {
      if (!data) {
        setSearchFields({
          ...searchFields,
          leavingFrom: "",
          originCity: "",
        });
      } else {
        setSearchFields({
          ...searchFields,
          leavingFrom: data.trim(),
          originCity: locality,
        });
      }
    } catch (e) {
      console.log("error leaving", e);
    }
  };

  const handleGoingTo = (data, locality) => {
    try {
      if (!data) {
        setSearchFields({
          ...searchFields,
          goingTo: "",
          destinationCity: "",
        });
      } else {
        setSearchFields({
          ...searchFields,
          goingTo: data.trim(),
          destinationCity: locality,
        });
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleSeats = (event) => {
    setSearchFields({
      ...searchFields,
      seats: parseInt(event.target.value),
    });
  };

  const handleClick = () => {
    const searchParams = new URLSearchParams({
      ...searchFields,
      offset: 0,
      searchType: selectedType,
    });
    const queryString = searchParams.toString();
    if (!searchFields.leavingFrom || !searchFields.goingTo) {
      return;
    }
    try {
      const localSearches = localStorage.getItem("searches");
      if (localSearches) {
        let parsed = JSON.parse(localSearches);
        parsed.push(searchFields);
        localStorage.setItem("searches", JSON.stringify(parsed));
      } else {
        localStorage.setItem("searches", JSON.stringify([searchFields]));
      }
    } catch (e) {
      console.log(e);
    }
    handleCloseModal();
    navigate(`/ride-sharing?${queryString}`);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  if (cities?.isLoading || !isLoaded) {
    return <CircularLoader isLoading={cities.isLoading || isLoaded} />;
  }
  return (
    <Container className={classes.root} maxWidth="md">
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.BoxStyles}>
            <Grid container>
              <Grid item xs={12} sm={3}>
                <Box p={1}>
                  <FormControl fullWidth>
                    <AutoComplete
                      data={cities}
                      selectedType={selectedType}
                      label={"Leaving from"}
                      placeholder="Leaving from"
                      handleOnChange={handleLeavingFrom}
                      value={searchFields.leavingFrom}
                      page="homeSearch"
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Box p={1}>
                  <FormControl fullWidth>
                    <AutoComplete
                      selectedType={selectedType}
                      data={cities}
                      label={"Going To"}
                      placeholder="Going To"
                      handleOnChange={handleGoingTo}
                      value={searchFields.goingTo}
                      page="homeSearch"
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Box p={1}>
                  <FormControl fullWidth>
                    <InputLabel shrink="false" className={classes.inputLabel}>
                      Departure Date
                    </InputLabel>
                    <DatePicker
                      className={classes.datepicker}
                      minDate={new Date()}
                      selected={
                        searchFields.date
                          ? new Date(searchFields.date)
                          : new Date()
                      }
                      onSelect={(date) => {
                        setSearchFields({
                          ...searchFields,
                          date: date,
                        });
                      }}
                      placeholderText={"Departure Date"}
                      todayButton="Today"
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box p={1}>
                  <FormControl fullWidth>
                    <InputLabel shrink="false" className={classes.inputLabel}>
                      Passenger
                    </InputLabel>
                    <Select
                      fullWidth
                      value={searchFields.seats}
                      onChange={handleSeats}
                      placeholder="Seats required"
                      displayEmpty
                      className={classes.selectStyles}
                      classes={{ focused: classes.inputFocused }}
                      inputProps={{ "aria-label": "Without label" }}
                      disableUnderline={window.innerWidth < 600 ? false : true}
                    >
                      <MenuItem>Passenger</MenuItem>
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <MenuItem key={index + item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                onClick={handleClick}
                className={classes.searchContainer}
              >
                <Box className={classes.searchBox}>
                  <IconButton
                    color="secondary"
                    aria-label="upload picture"
                    component="span"
                    fullWidth
                  >
                    {window.innerWidth < 746 ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <span style={{ color: "white", fontSize: "14px" }}>
                          Search Ride
                        </span>
                        <SearchIcon
                          color="primary"
                          style={{ color: "white", marginRight: "5px" }}
                        />
                      </span>
                    ) : (
                      <SearchIcon
                        color="primary"
                        style={{
                          color: "white",
                          marginRight: "5px",
                          fontSize: "2rem",
                        }}
                      />
                    )}
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
