import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DatePicker = ({
  required,
  label,
  date,
  handleDateChange,
  departureDate,
  error,
}) => {
  const [open, setOpen] = useState(false); // Control the open state of the DatePicker

  const handleOpen = () => {
    setOpen(true); // Open the date picker
  };

  const handleClose = () => {
    setOpen(false); // Close the date picker
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        format="dd/MM/yyyy"
        label={label}
        value={date}
        // onChange={handleDateChange}
        onChange={(newDate) => {
          handleDateChange(newDate);
          handleClose(); // Close the picker after selecting a date
        }}
        open={open} // Control open state
        onClose={handleClose} // Handle closing when clicking outside
        onClick={handleOpen} // Open the date picker when clicking the text field
        autoOk
        disablePast
        required={required}
        inputVariant="outlined"
        fullWidth
        error={error}
        helperText={error ? "Enter a valid date" : ""}
        InputProps={{
          onClick: handleOpen, // Ensure clicking the input also opens the date picker
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
