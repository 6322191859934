import React, { useEffect, useState } from "react";
import api from "../services/api";

const useChatRides = (currentUserId, chatUserId, limit, offset) => {
  const [chatRides, setChatRides] = useState(null);

  useEffect(() => {
    if (currentUserId && chatUserId) {
      getChatRides(chatUserId);
    }
  }, [currentUserId, chatUserId]);

  const getChatRides = async (chatUserId) => {
    try {
      const response = await api.get("rideRequests/getChatRides", {
        params: { limit, offset, chatUserId },
      });
      if (response.status == 200) {
        setChatRides(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return chatRides;
};

export default useChatRides;
