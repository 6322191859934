import { createGlobalStyle } from "styled-components";
import { COLORS } from "./assets/colors";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, Helvetica, Sans-Serif;
    line-height:1.2rem;
  }
  .link {
    color : ${COLORS.linkColor};    
  }
  .vert-divider {
    height: 31px;
    width: 1px;
    background: gainsboro;
  }
  .datepicker{
    width:100%;
    height: 3rem;
    padding: 0 8px;
    font-size: 16px;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  .react-datepicker-wrapper {
    width:100%;
  }
  .react-datepicker__navigation--next {
    background-color: #054752;
    color: #054752;
  }
  .react-datepicker__navigation--previous {
    background-color: #054752;
    color: #054752;
  }
  .react-datepicker__year-read-view--down-arrow {
    color: #054752;
  }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #054752;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: #054752;
    color: #fff;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: #054752;
    color: #fff;
  }
   /* Increase width of PlacesAutocomplete dropdown */
   .pac-container {
    /* width: ${(props) =>
      props.selectedType === "local" ? "300px" : "250px"} !important; */
      width:"600px" !important;
    max-width: 100%;
    z-index: 1050; /* Ensure it appears on top of other elements */
  }
  .autocomplete-cs {
    width:"600px" !important;

  }
  
  :root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: ${COLORS.primaryColor};
  --toastify-color-success: ${COLORS.primaryColor};
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

 
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
`;

export default GlobalStyle;
