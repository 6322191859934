import React from "react";
import { Button } from "../../pages/BookingRequest/styles";
import { Container, Message, Title } from "./styles";
import noData from "../../assets/images/noData.svg";

const NoData = ({
  title,
  message,
  button,
  buttonText,
  buttonLink,
  showImage = true,
  imgSrc = noData,
}) => (
  <Container>
    {showImage && <img src={imgSrc} height={"auto"} width={"150px"} />}
    {title && <Title>{title}</Title>}
    {message && <Message>{message}</Message>}
    {button && (
      <Button animation={true} to={`${buttonLink}`}>
        {buttonText}
      </Button>
    )}
  </Container>
);

export default NoData;
