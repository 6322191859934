export default {
  api: {
    url: "https://starfish-app-33kyc.ondigitalocean.app/api/v1/",
    baseUrl: "https://starfish-app-33kyc.ondigitalocean.app",
  },
  featureFlags: {
    freeLaunch: true,
  },
  env: "staging",
  log: "debug",
  recaptchaKey: process.env.REACT_APP_RECAPTCHA,
};
