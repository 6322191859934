import { IconButton } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import Filters from "../../Filters";
import { COLORS } from "../../../assets/colors";

const FilterModal = ({
  isOpen,
  setIsOpen,
  handleSort,
  sortBy,
  handleDeparturetime,
  departureTime,
  amenity,
  handleAmenity,
  clearAll,
}) => {
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper onClick={handleCloseModal}>
          <ModalContent onClick={(event) => event.stopPropagation()}>
            <div className="header">
              <ModalTitle>Filter</ModalTitle>
              <IconButton
                className="clost-btn"
                onClick={handleCloseModal}
                size="small"
              >
                <Close />
              </IconButton>
            </div>
            <div>
              <Filters
                handleSort={handleSort}
                sortBy={sortBy}
                handleDeparturetime={handleDeparturetime}
                departureTime={departureTime}
                handleAmenity={handleAmenity}
                amenity={amenity}
                clearAll={clearAll}
              />
            </div>
            <div>
              <button className="filter-btn" onClick={handleCloseModal}>
                Filter
              </button>
            </div>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};
export default FilterModal;
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #f4f4f4;
  padding: 2rem;
  border-radius: 5px;
  animation: modal 0.3s forwards;
  width: 100%;
  margin: 1rem;
  max-width: 874px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .clost-btn {
      position: absolute;
      top: 4%;
      right: 4%;
      border: 1px solid gray;
    }
  }
  .filter-btn {
    width: 100%;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.8rem;
    background: ${COLORS.primaryColor};
    outline: none;
    border: none;
    border-radius: 16px;
    color: white;
  }

  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
