/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import CustomButton from "../UI/Button";
import styled from "styled-components";
import {
  TextField,
  Typography,
  MenuItem,
  Box,
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { device } from "../../Utils";
import DatePicker from "../UI/DatePicker";
import TimePicker from "../UI/TimePicker";
import moment from "moment";
import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  useLoadScript,
} from "@react-google-maps/api";
import CircularLoader from "../UI/Loader";
import { COLORS } from "../../assets/colors";
import { Alert } from "@material-ui/lab";
import configMerged from "../../config";
import { FaUpload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const libraries = ["places"];

function Publish({
  ridesSubmitFrom,
  isLoading,
  submitting,
  currentUser,
  licenseStatus,
  checkLicense,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const center = { lat: 31.582045, lng: 74.329376 };
  const [map, setMap] = useState(/** @type google.types.Map*/ null);
  const [directionResponse, setDirectionResponse] = useState(null);
  const [timeError, setTimeError] = useState("");
  const [dateError, setDateError] = useState("");

  /** @type React.mutableRefObject<HTMLInputElement>*/
  const originRef = useRef();
  /** @type React.mutableRefObject<HTMLInputElement>*/
  const destinationRef = useRef();
  /** @type React.mutableRefObject<HTMLInputElement>*/
  const startCityRef = useRef();

  const navigate = useNavigate();

  const [state, setState] = useState({
    leavingFrom: "",
    headingTo: "",
    pickupLocation: "",
    dropLocation: "",
    date: null,
    time: null,
    arrivalDate: null,
    arrivalTime: null,
    vehicleName: "",
    vehicleMdel: "",
    vehicleType: "car",
    route: "",
    ticket: "",
    totalSeats: 4,
    error: false,
    duration: "",
    distance: "",
    start_lat: null,
    start_lng: null,
    end_lat: null,
    end_lng: null,
    smoking: false,
    automatic_approval: false,
    female_only: false,
  });
  const [invalidTicket, setInvalidTicket] = useState(false);

  useEffect(() => {
    if (currentUser?.gender == "female") {
      setState({
        female_only: true,
      });
    }
  }, [currentUser?.gender]);

  const onSubmit = (e) => {
    e.preventDefault();
    const depTime = moment(state.time);
    const isPositiveNumber =
      /^\d*\.?\d*$/.test(state.ticket) &&
      parseFloat(state.ticket) > 0 &&
      parseFloat(state.ticket) <= 99999;

    if (!isPositiveNumber) {
      setInvalidTicket(true);
      return;
    }
    ridesSubmitFrom({ ...state, time: depTime });
  };

  const handleInputChange = (event) => {
    setState({
      ...state,
      ticket: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    const initialDate = moment(date);
    const durationToAdd = moment.duration(state.duration);
    const updatedDate = initialDate.add(durationToAdd).toDate();
    setDateError("");
    setState({
      ...state,
      date: date,
      time: null,
      arrivalDate: updatedDate,
    });
  };

  const handleTimeChange = (time) => {
    if (!state.date) {
      setDateError("Select date");
      return;
    }
    const date = new Date(state.date).getDate();
    if (date == new Date().getDate()) {
      if (new Date(time).getTime() < new Date().getTime()) {
        setTimeError("Can not select a past time");
        setState({
          ...state,
          time: null,
        });
        return;
      }
    }
    setTimeError("");
    const initialTime = moment(time);

    // Updated regex to handle optional hours and only minutes
    const regex = /(?:(\d+)\s*hour[s]?)?\s*(\d+)?\s*(min[s]?|minute[s]?)/;
    const match = state.duration.match(regex);

    if (match) {
      const hours = match[1] ? parseInt(match[1], 10) : 0;
      if (hours) {
        initialTime.add(hours, "hours");
      }

      // The minute group is in `match[2]` when hours are present, or `match[1]` when only minutes are present
      const minutes = match[2]
        ? parseInt(match[2], 10)
        : match[1] && !hours
        ? parseInt(match[1], 10)
        : 0;
      if (minutes) {
        initialTime.add(minutes, "minutes");
      }
    }
    setState({
      ...state,
      time: time,
      arrivalTime: initialTime.format("h:mm A"),
    });
  };
  const onMapLoad = (map) => {
    setMap(map);
  };
  const handleOriginChange = () => {
    const details = originRef.current.getPlace();

    try {
      if (details) {
        const addressComponents = details?.address_components;
        const cityObject = addressComponents.find((component) => {
          return component.types.includes("locality");
        });
        if (!cityObject) {
          setState({
            ...state,
            leavingFrom: details.name,
          });
        } else {
          const cityData = cityObject?.long_name;
          setState({
            ...state,
            leavingFrom: cityData,
          });
        }
      }
    } catch (error) {}
  };
  const handleDestinationChange = async () => {
    const details = destinationRef.current.getPlace();

    try {
      if (details) {
        const addressComponents = details?.address_components;
        const cityObject = addressComponents.find((component) => {
          return component.types.includes("locality");
        });

        let cityData;
        if (cityObject) {
          cityData = cityObject?.long_name;
        } else {
          cityData = details.name;
        }

        await calculateRoute(cityData);
      }
    } catch (error) {}
  };

  const calculateRoute = async (cityData) => {
    if (!originRef.current || !destinationRef.current) {
      return;
    }

    const directionService = new google.maps.DirectionsService();
    const results = await directionService.route({
      origin: originRef.current.getPlace().formatted_address,
      destination: destinationRef.current.getPlace().formatted_address,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    const startLocationLatLng = {
      lat: results.routes[0].legs[0].start_location.lat(),
      lng: results.routes[0].legs[0].start_location.lng(),
    };
    const endLocationLatLng = {
      lat: results.routes[0].legs[0].end_location.lat(),
      lng: results.routes[0].legs[0].end_location.lng(),
    };
    const route = results.routes[0].summary;

    setDirectionResponse(results);

    setState({
      ...state,
      duration: results.routes[0].legs[0].duration.text,
      distance: results.routes[0].legs[0].distance.text,
      pickupLocation: originRef.current.getPlace().formatted_address,
      dropLocation: destinationRef.current.getPlace().formatted_address,
      start_lat: startLocationLatLng.lat,
      start_lng: startLocationLatLng.lng,
      end_lat: endLocationLatLng.lat,
      end_lng: endLocationLatLng.lng,
      route: route,
      headingTo: cityData,
    });
  };
  const handleSmokingControl = (e) => {
    setState({
      ...state,
      smoking: currentUser?.gender == "female" ? false : e.target.checked,
    });
  };
  const handleAutomaticApproval = (e) => {
    setState({
      ...state,
      automatic_approval: e.target.checked,
      // female_only: false,
    });
  };
  const handleFemaleOnly = (e) => {
    setState({
      ...state,
      female_only: e.target.checked,
      // automatic_approval: false,
    });
  };

  const getSeatsByVehicleType = (type) => {
    switch (type) {
      case "car":
        return [1, 2, 3, 4];
      case "van":
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
      case "mpv":
        return [1, 2, 3, 4, 5, 6];
      case "suv":
        return [1, 2, 3, 4];
      default:
        return [1, 2, 3, 4];
    }
  };

  const handleLicense = () => {
    navigate("/license");
  };

  if (!isLoaded) return <CircularLoader isLoading={isLoaded} />;
  return (
    <MainContainer className="mainContainer">
      <ChildContainer className="childContainer">
        <div className="header">
          <Typography variant="h4" color="primary">
            Create Your Ride
          </Typography>

          <button className="upload-btn" onClick={handleLicense}>
            <FaUpload style={{ marginRight: "8px" }} />
            Upload License
          </button>
        </div>

        <FormContainer>
          <form onSubmit={onSubmit}>
            <Autocomplete
              className="autocomplete-cs"
              options={{
                componentRestrictions: { country: "pk" },
              }}
              onLoad={(autocomplete) => {
                originRef.current = autocomplete;
              }}
              onPlaceChanged={handleOriginChange}
            >
              <input
                className="inputStyle"
                name="address"
                required
                placeholder="F8 Markaz Islamabad"
                type="text"
                ref={originRef}
              />
            </Autocomplete>
            <Autocomplete
              options={{
                componentRestrictions: { country: "pk" },
              }}
              onLoad={(autocomplete) => {
                destinationRef.current = autocomplete;
              }}
              onPlaceChanged={handleDestinationChange}
            >
              <input
                className="inputStyle"
                required
                placeholder="Bahria town, Lahore"
                ref={destinationRef}
              />
            </Autocomplete>

            <Box mt={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    required={true}
                    label={"Departure date"}
                    date={state.date}
                    handleDateChange={handleDateChange}
                    error={dateError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePicker
                    required={true}
                    label={"Departure time"}
                    time={state.time}
                    handleTimeChange={handleTimeChange}
                    date={state.date}
                    error={timeError}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{ min: 200 }}
                    placeholder="per passenger ticket e.g 4000"
                    label="Ticket"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required
                    value={state.ticket}
                    onChange={handleInputChange}
                    error={invalidTicket}
                    helperText={
                      invalidTicket ? "Please enter a valid amount" : ""
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <TextField
              style={{ marginTop: "10px" }}
              className="Seats for passengers"
              select
              label="Vehicle Type"
              fullWidth
              variant="outlined"
              value={state.vehicleType}
              onChange={(e) =>
                setState({
                  ...state,
                  vehicleType: e.target.value,
                })
              }
            >
              {["car", "van", "mpv", "suv", "pickup"].map((item, index) => {
                return (
                  <MenuItem key={index + item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              style={{ marginTop: "10px" }}
              className="Seats for passengers"
              select
              label="Seats for passengers"
              fullWidth
              variant="outlined"
              value={state.totalSeats}
              onChange={(e) =>
                setState({
                  ...state,
                  totalSeats: parseInt(e.target.value),
                })
              }
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Adjust the height as needed
                    },
                  },
                },
              }}
            >
              {getSeatsByVehicleType(state.vehicleType).map((item, index) => {
                return (
                  <MenuItem key={index + item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
            <AmenitiesStyled>
              {currentUser?.gender != "female" && (
                <span>
                  <FormControlLabel
                    value={"start"}
                    checked={state.smoking}
                    control={<Switch color="primary" />}
                    label="Allow smoking?"
                    labelPlacement="start"
                    onChange={handleSmokingControl}
                  />
                </span>
              )}
              {!configMerged.featureFlags.freeLaunch && (
                <span className="auto-approval">
                  <FormControlLabel
                    value={"start"}
                    checked={state.automatic_approval}
                    control={<Switch color="primary" />}
                    label="Automaticaly approve requests?"
                    labelPlacement="start"
                    onChange={handleAutomaticApproval}
                  />
                </span>
              )}

              {currentUser?.gender == "female" && (
                <span className="auto-approval">
                  <FormControlLabel
                    value={"start"}
                    checked={state.female_only}
                    control={<Switch color="primary" />}
                    label="Female only?"
                    labelPlacement="start"
                    onChange={handleFemaleOnly}
                  />
                </span>
              )}
            </AmenitiesStyled>

            {state.female_only && currentUser?.gender == "female" && (
              <Alert
                severity="info"
                style={{ margin: "0.5rem", textAlign: "left" }}
              >
                By creating a female-only ride, you acknowledge that you are a
                female driver and will only accept female passengers. You also
                take on the responsibility of providing a safe and secure travel
                environment for your female passengers. Your contact number will
                be shared with the passengers once they have paid the service
                charges for the ride.
              </Alert>
            )}
            {currentUser?.gender == "female" && state.female_only == false && (
              <Alert severity="info" style={{ margin: "0.5rem" }}>
                Female drivers are recommended to turn on the female only
                option.So that only the female passengers can book seats.
              </Alert>
            )}

            {state.automatic_approval && (
              <Alert severity="info" style={{ margin: "0.5rem" }}>
                You acknowledge that your booking requests will be automatically
                confirmed without your approval for this ride.
              </Alert>
            )}
            {state.headingTo && (
              <StyleMapContainer>
                <div className="map">
                  <GoogleMap
                    onLoad={onMapLoad}
                    center={center}
                    zoom={18}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                    }}
                    mapContainerStyle={{ height: "250px", width: "100%" }}
                  >
                    {directionResponse && (
                      <DirectionsRenderer
                        directions={directionResponse}
                        options={{
                          polylineOptions: {
                            strokeWeight: 5, // Set the stroke weight (thickness)
                            strokeColor: COLORS.primaryColor, // Change the route color to red
                          },
                        }}
                      />
                    )}
                  </GoogleMap>
                </div>
              </StyleMapContainer>
            )}

            <BtnContainer>
              <CustomButton
                label={submitting ? "PLEASE WAIT" : "NEXT"}
                type="submit"
              />{" "}
            </BtnContainer>
          </form>
        </FormContainer>
      </ChildContainer>
    </MainContainer>
  );
}

export default Publish;
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const AmenitiesStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  @media (max-width: 546px) {
    font-size: small;
  }
  .auto-approval {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
const ChildContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
  padding-right: 60px;
  align-items: center;
  padding: 1rem;
  @media (max-width: 546px) {
    padding: 1rem 1rem 3rem 1rem;
  }
  .header {
    width: 100%;
    max-width: 546px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      @media (max-width: 546px) {
        font-size: 1.5rem;
      }
      @media (max-width: 400px) {
        font-size: 1.2rem;
      }
    }
    .upload-btn {
      border: none;
      padding: 1rem;
      border-radius: 0.5rem;
      background: transparent;
      color: deepskyblue;
      font-weight: 600;
      font-size: 1rem;
      cursor: pointer;
      :hover {
        background: rgb(250, 250, 250);
      }
      @media (max-width: 546px) {
        font-size: 0.8rem;
      }
    }
  }
`;
const StyleMapContainer = styled.div`
  height: 250px;
  width: 100%;
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  max-width: 768px;

  > form > .MuiTextField-root {
    text-align: center;
    color: white;
    margin-bottom: 5px;
    margin-top: 7px;
    text-align: start;
  }

  > form > .Going {
    text-align: start;
  }
  > form > .datepicker {
    margin-top: 1rem;
    border: "none";
    height: 2rem;
  }
  .inputStyle {
    width: 100%;
    margin: 8px 0;
    padding: 16px 12px;
    font-size: 1rem;
    border: 1px solid lightgray;
    border-radius: 4px;
    :focus {
      outline: 2px solid ${COLORS.primaryColor};
    }
  }
  
}
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 8rem;
  max-width: 350px;
`;
