import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  AccessTime,
  AccessTime as AccessTimeIcon,
  TimeToLeave,
} from "@material-ui/icons";

const TimePicker = ({ required, label, time, handleTimeChange, error }) => {
  const KeyboardButtonProps = {
    keyboardIcon: <AccessTimeIcon />,
  };
  const [open, setOpen] = useState(false); // Control the open state of the DatePicker

  const handleOpen = () => {
    setOpen(true); // Open the date picker
  };

  const handleClose = () => {
    setOpen(false); // Close the date picker
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        ampm={true}
        label={label}
        value={time}
        onChange={(newDate) => {
          handleTimeChange(newDate);
          handleClose(); // Close the picker after selecting a date
        }}
        open={open} // Control open state
        onClose={handleClose} // Handle closing when clicking outside
        onClick={handleOpen} // Open the date picker when clicking the text fi
        minutesStep={5}
        okLabel="Select"
        variant="dialog"
        inputVariant="outlined"
        required={required}
        fullWidth
        KeyboardButtonProps={KeyboardButtonProps}
        error={error}
        helperText={error ? "Select a valid time" : ""}
        InputProps={{
          onClick: handleOpen, // Ensure clicking the input also opens the date picker
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
