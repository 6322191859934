import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmModal from "../../components/models/ConfirmModal";
import NoData from "../../components/NoData";
import { getMyBookings } from "../../redux/actions";
import { CANCEL_BOOKING_REQUEST } from "../../redux/constants";
import api from "../../services/api";
import {
  BOOKING_REQUEST_STATUS,
  capitalizeFirstLetter,
  renderStatusText,
  rideRequestStatus,
} from "../../Utils";
import {
  Card,
  CardButton,
  CardDate,
  CardStatus,
  CardWrapper,
  ContentWrapper,
  PageWrapper,
  Content,
} from "./styles";
import noBooking from "../../assets/images/noBooking.png";
import CircularLoader from "../../components/UI/Loader";
import moment from "moment";
import CarOutline from "../../assets/images/carOutline.png";
import { Pagination } from "@material-ui/lab";
import configMerged from "../../config";

const Booking = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { isLoading, data, count } = useSelector((state) => state.myBookings);
  const [cancelling, setCancelling] = useState(false);
  const [currentRide, setCurrentRide] = useState({});
  const [currentBooking, setCurrentBooking] = useState({});
  const [activeTab, setActiveTab] = useState("all");
  const [pageSize, setPageSize] = useState(4);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);

  const limit = 4;
  const offset = (page - 1) * pageSize;

  const { currentUser } = useSelector((state) => {
    return state.auth;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyBookings(activeTab, limit, offset));
  }, [activeTab, page]);

  useEffect(() => {
    if (count > 0) {
      setPages(Math.ceil(count / pageSize));
    }
  }, [count]);
  const handleTabs = (activeTab) => {
    setPage(1);
    setActiveTab(activeTab);
  };

  const renderBookingHeader = (bookingStatus) => {
    if (!bookingStatus) {
      return "All";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.PENDING) {
      return "Pending";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.ACCEPTED) {
      return "Accepted";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.REJECTED) {
      return "Rejected";
    } else if (bookingStatus === BOOKING_REQUEST_STATUS.CANCELLED) {
      return "Cancelled";
    } else {
      return "All";
    }
  };

  const onCancelBookingRequest = async (cancelReasonSlug) => {
    try {
      setCancelling(true);
      const payload = {
        requestedBy: currentUser?.id,
        requestedTo: currentRide?.UserId,
        rideId: currentRide.id,
        cancel_reason: cancelReasonSlug,
      };
      await api
        .post(`rideRequests/cancel`, payload)
        .then((response) => {
          if (response.status === 200) {
            setCancelling(false);
            setShowCancelModal(false);
            toast.success("Booking Request Cancelled");
            dispatch({
              type: CANCEL_BOOKING_REQUEST,
              payload: {
                id: currentBooking.id,
              },
            });
          }
        })
        .catch((error) => {
          setCancelling(false);
          setShowCancelModal(false);
          toast.error(error.response.data.msg);
        });
    } catch (err) {
      setCancelling(false);
      setShowCancelModal(false);
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <PageWrapper>
      <Content>
        <div className="headerText">
          <h1>Your Bookings as a Passanger</h1>
          <div className="tabs">
            <div
              className={activeTab == "all" && "active"}
              onClick={() => handleTabs("all")}
            >
              All
            </div>
            <div
              className={
                activeTab == BOOKING_REQUEST_STATUS.PENDING && "active"
              }
              onClick={() => handleTabs(BOOKING_REQUEST_STATUS.PENDING)}
            >
              Pending
            </div>
            <div
              className={
                activeTab == BOOKING_REQUEST_STATUS.ACCEPTED && "active"
              }
              onClick={() => handleTabs(BOOKING_REQUEST_STATUS.ACCEPTED)}
            >
              Accepted
            </div>
            <div
              className={
                activeTab == BOOKING_REQUEST_STATUS.REJECTED && "active"
              }
              onClick={() => handleTabs(BOOKING_REQUEST_STATUS.REJECTED)}
            >
              Rejected
            </div>
            <div
              className={
                activeTab == BOOKING_REQUEST_STATUS.CANCELLED && "active"
              }
              onClick={() => handleTabs(BOOKING_REQUEST_STATUS.CANCELLED)}
            >
              Cancelled
            </div>
          </div>
        </div>
        <div className="container" style={{ display: "flex" }}>
          <ContentWrapper>
            {isLoading && <CircularLoader isLoading={isLoading} />}
            {!isLoading && data.length === 0 && activeTab !== "all" && (
              <div className="no-data">
                <NoData
                  showImage={true}
                  button={false}
                  message={"No records found"}
                />
              </div>
            )}
            {!isLoading && data.length === 0 && activeTab == "all" && (
              <NoData
                showImage={true}
                imgSrc={noBooking}
                marginTop={"0"}
                button={true}
                buttonLink="/ride-sharing"
                buttonText={"Search a Ride"}
                message={
                  "The details of your reserved rides will be accessible here"
                }
                title={"Currently, you do not have any bookings"}
              />
            )}
            {!isLoading && data?.length > 0 && (
              <h1 style={{ margin: "1rem", fontSize: "x-large" }}>
                {renderBookingHeader(activeTab)} Bookings
              </h1>
            )}
            <CardWrapper>
              {!isLoading &&
                data?.length > 0 &&
                data.map((card) => (
                  <Card key={card.id}>
                    <div className="header">
                      <div className="title-container">
                        <div>
                          <h3>{card.Ride.leaving_from}&nbsp;</h3>
                          <p>
                            {moment(card.Ride?.departure_time).format("h:mm A")}
                          </p>
                        </div>
                        <img
                          src={CarOutline}
                          height={64}
                          width={64}
                          alt="car"
                        />
                        &nbsp;
                        <div>
                          <h3>{card.Ride.heading_to}</h3>
                          <p>{card.Ride?.arrival_time}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div>
                        <CardDate>
                          <span>
                            <small>Departure Date</small>
                          </span>{" "}
                          <strong>
                            {moment(card.Ride.departure_date).format(
                              "MMM Do, YYYY"
                            )}
                          </strong>
                          <span>
                            <small>Departure time</small>
                          </span>{" "}
                          <strong>
                            {moment(card.Ride.departure_time).format("h:mm A")}
                          </strong>
                        </CardDate>
                        <CardStatus>
                          <span>
                            <small>Booking Request Status</small>
                          </span>{" "}
                          <strong>
                            {renderStatusText(parseInt(card.status))}
                          </strong>
                          {configMerged.featureFlags.freeLaunch &&
                            card.status == "6" && (
                              <Link to={"/my-refunds"}>Check Refunds</Link>
                            )}
                        </CardStatus>
                        {card.Ride.status === "completed" &&
                          card.status == "2" && (
                            <CardStatus isComplete={true}>
                              You have taken this ride
                            </CardStatus>
                          )}
                      </div>
                      <div>
                        <div className="link-container">
                          <Link
                            to={`/active-ride/${card.Ride.id}`}
                            className="link"
                          >
                            Ride Details
                          </Link>

                          <Link
                            to={`/inbox/?user=${card.Ride.user_id}`}
                            className="link"
                          >
                            Message{" "}
                            {capitalizeFirstLetter(
                              card.requestedTo?.first_name
                            )}
                          </Link>
                          <Link
                            to={`/inbox/?user=${card.Ride.user_id}`}
                            className="link"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {card.requestedTo?.mobile_number}
                          </Link>
                          {window.innerWidth < 546 && (
                            <a
                              style={{
                                border: "1px solid",
                                padding: "0.5rem 1rem",
                                color: "white",
                                borderRadius: "16px",
                                background: "#00b5ff",
                                textDecoration: "none",
                              }}
                              href={`tel:${card.requestedTo?.mobile_number}`}
                            >
                              Call{" "}
                              {capitalizeFirstLetter(
                                card.requestedTo?.first_name
                              )}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>

                    {parseInt(card.status) != rideRequestStatus.REJECTED &&
                      parseInt(card.status) != rideRequestStatus.CANCELLED &&
                      parseInt(card.status) != rideRequestStatus.EXPIRED &&
                      parseInt(card.status) !=
                        rideRequestStatus.RIDE_CANCELLED &&
                      !card.expired_cancellation &&
                      card.Ride.status !== "cancelled" &&
                      card.Ride.status !== "completed" &&
                      card.Ride.status !== "active" && (
                        <CardButton
                          onClick={() => {
                            setShowCancelModal(true);
                            setCurrentRide(card.Ride);
                            setCurrentBooking(card);
                          }}
                        >
                          {" "}
                          Cancel Booking
                        </CardButton>
                      )}
                  </Card>
                ))}
            </CardWrapper>
            <div className="pagination">
              {!isLoading && pages >= 1 && data?.length >= 1 && (
                <Pagination
                  onChange={handleChange}
                  size="large"
                  page={page}
                  count={pages}
                  color="primary"
                />
              )}
            </div>
          </ContentWrapper>
        </div>
      </Content>
      {showCancelModal && (
        <ConfirmModal
          type="bookingCancellation"
          open={showCancelModal}
          setOpen={setShowCancelModal}
          handleClose={() => setShowCancelModal(false)}
          cancelBtnText={"Cancel"}
          onConfirm={onCancelBookingRequest}
          confirmBtnText={cancelling ? "cancelling..." : "Confirm"}
          title={"Cancel booking"}
          cancelBookingModal={true}
          content={
            "Can you please tell us why are you cancelling your booking? It will help us to make the platform better."
          }
        />
      )}
    </PageWrapper>
  );
};

export default Booking;
