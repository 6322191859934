import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";

import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER } from "./redux/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./globalStyles";
import MainLayout from "./components/Layouts/MainLayout";
import AppRouter from "./AppRouter";
import GlobalContextProvider from "./contexts/globalContext";
import { socket } from "./socket";
import ErrorBoundary from "./ErrorBoundary";

import TagManager from "react-gtm-module";
import configMerged from "./config";
import GTMPageViewTracker from "./services/pageViewTracker";
const tagManagerArgs = {
  gtmId: configMerged.gtmId,
};

function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    // dispatch(getCities());
    if (token) {
      const decoded = jwt_decode(token);
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      socket.connect();
      socket.emit("userInfo", decoded);
    }
  }, []);
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <GlobalContextProvider>
        {/* <p style={{ textAlign: "center", background: "orange" }}>
          website is under maintainence
        </p> */}
        <Router>
          <MainLayout>
            <AppRouter />
          </MainLayout>
          <GTMPageViewTracker />
        </Router>
        <ToastContainer position="bottom-right" />
      </GlobalContextProvider>
    </ErrorBoundary>
  );
}

export default App;
