export const Honda = [
  { type: "sedan", name: "Honda Civic", passengerCapacity: 4 },
  { type: "sedan", name: "Honda City", passengerCapacity: 4 },
  { type: "sedan", name: "Honda Accord", passengerCapacity: 4 },
  { type: "sedan", name: "Other", passengerCapacity: 4 },
  { type: "suv", name: "Honda BR-V", passengerCapacity: 6 },
  { type: "suv", name: "Honda HR-V", passengerCapacity: 4 },
  { type: "suv", name: "Other", passengerCapacity: 4 },
];

export const Suzuki = [
  { type: "hatchback", name: "Alto VXR", passengerCapacity: 3 },
  { type: "hatchback", name: "Alto AGS", passengerCapacity: 3 },
  { type: "hatchback", name: "Suzuki Cultus", passengerCapacity: 4 },
  { type: "hatchback", name: "Cultus VXR", passengerCapacity: 4 },
  { type: "hatchback", name: "Cultus VXL", passengerCapacity: 4 },
  { type: "hatchback", name: "Cultus AGS", passengerCapacity: 4 },
  { type: "hatchback", name: "Suzuki Swift", passengerCapacity: 4 },
  { type: "hatchback", name: "Swift DLX", passengerCapacity: 4 },
  { type: "hatchback", name: "Swift AT", passengerCapacity: 4 },
  { type: "hatchback", name: "Suzuki Wagon R", passengerCapacity: 4 },
  { type: "hatchback", name: "Wagon R VXR", passengerCapacity: 4 },
  { type: "hatchback", name: "Wagon R VXL", passengerCapacity: 4 },
  { type: "hatchback", name: "Wagon R AGS", passengerCapacity: 4 },
  { type: "hatchback", name: "Other", passengerCapacity: 4 },
  { type: "van", name: "Suzuki Bolan", passengerCapacity: 7 },
  { type: "van", name: "Bolan VX", passengerCapacity: 7 },
  { type: "van", name: "Bolan Cargo Van", passengerCapacity: 1 },
  { type: "van", name: "Other", passengerCapacity: 1 },
  { type: "pickup", name: "Suzuki Ravi", passengerCapacity: 1 },
  { type: "pickup", name: "Ravi VX", passengerCapacity: 1 },
  { type: "pickup", name: "Ravi Euro II", passengerCapacity: 1 },
  { type: "pickup", name: "Other", passengerCapacity: 1 },
  { type: "suv", name: "Suzuki Jimny", passengerCapacity: 3 },
  { type: "suv", name: "Jimny GA", passengerCapacity: 3 },
  { type: "other", name: "others", passengerCapacity: 0 },
];

export const Toyota = [
  { type: "hatchback", name: "Vitz", passengerCapacity: 4 },
  { type: "hatchback", name: "Aqua", passengerCapacity: 4 },
  { type: "hatchback", name: "Other", passengerCapacity: 4 },
  { type: "sedan", name: "Toyota Corolla", passengerCapacity: 4 },
  { type: "sedan", name: "Corolla XLi", passengerCapacity: 4 },
  { type: "sedan", name: "Corolla GLi", passengerCapacity: 4 },
  { type: "sedan", name: "Corolla Altis 1.6", passengerCapacity: 4 },
  { type: "sedan", name: "Corolla Altis 1.8", passengerCapacity: 4 },
  { type: "sedan", name: "Toyota Yaris", passengerCapacity: 4 },
  { type: "sedan", name: "Other", passengerCapacity: 4 },
  { type: "suv", name: "Toyota Fortuner", passengerCapacity: 6 },
  { type: "pickup", name: "Toyota Hilux", passengerCapacity: 4 },
  { type: "sedan", name: "Toyota Camry", passengerCapacity: 4 },
  { type: "sedan", name: "Toyota G", passengerCapacity: 4 },
  { type: "sedan", name: "Premio", passengerCapacity: 4 },
  { type: "sedan", name: "Wagai", passengerCapacity: 4 },
  { type: "pickup", name: "Other", passengerCapacity: 4 },
  { type: "suv", name: "Toyota Prado", passengerCapacity: 6 },
  { type: "suv", name: "Toyota Land Cruiser", passengerCapacity: 6 },
  { type: "van", name: "Toyota Hiace Standard Roof", passengerCapacity: 14 },
  { type: "suv", name: "Other", passengerCapacity: 6 },

  { type: "van", name: "Toyota Hiace High Roof", passengerCapacity: 14 },
  { type: "van", name: "Toyota Hiace Super GL", passengerCapacity: 14 },
  { type: "van", name: "Other", passengerCapacity: 14 },
];
export const Alsvin = [{ type: "sedan", name: "Alsvin", passengerCapacity: 4 }];

export const commercialVehicles = [
  { type: "van", name: "Toyota Hiace Standard Roof", passengerCapacity: 14 },
  { type: "van", name: "Toyota Hiace High Roof", passengerCapacity: 14 },
  { type: "van", name: "Toyota Hiace Super GL", passengerCapacity: 14 },
  { type: "van", name: "Other", passengerCapacity: 14 },
];

export const Kia = [
  { type: "hatchback", name: "KIA Picanto", passengerCapacity: 4 },
  { type: "hatchback", name: "KIA Rio", passengerCapacity: 4 },
  { type: "hatchback", name: "Other", passengerCapacity: 4 },
  { type: "sedan", name: "KIA Cerato", passengerCapacity: 4 },
  { type: "sedan", name: "Other", passengerCapacity: 4 },
  { type: "suv", name: "KIA Sportage", passengerCapacity: 4 },
  { type: "suv", name: "KIA Sorento", passengerCapacity: 6 },
  { type: "suv", name: "Other", passengerCapacity: 6 },
  { type: "mpv", name: "KIA Carnival", passengerCapacity: 6 },
  { type: "mpv", name: "Other", passengerCapacity: 6 },
];

export const Changan = [
  { type: "commercial", name: "Changan Karvaan", passengerCapacity: 7 },
  { type: "commercial", name: "Changan M9", passengerCapacity: 1 },
  { type: "suv", name: "Changan CX70T", passengerCapacity: 6 },
  { type: "sedan", name: "Changan Alsvin", passengerCapacity: 4 },
];

export const Hyundai = [
  { type: "hatchback", name: "Hyundai Santro", passengerCapacity: 4 },
  { type: "hatchback", name: "Hyundai Grand i10", passengerCapacity: 4 },
  { type: "hatchback", name: "Other", passengerCapacity: 4 },
  { type: "suv", name: "Hyundai Tucson", passengerCapacity: 6 },
  { type: "suv", name: "Other", passengerCapacity: 6 },
  { type: "sedan", name: "Hyundai Elantra", passengerCapacity: 4 },
  { type: "sedan", name: "Hyundai Sonata", passengerCapacity: 4 },
  { type: "sedan", name: "Other", passengerCapacity: 4 },
];

export const FAW = [
  { type: "hatchback", name: "FAW V2", passengerCapacity: 4 },
  { type: "hatchback", name: "Other", passengerCapacity: 4 },
  { type: "van", name: "FAW XPV", passengerCapacity: 7 },
  { type: "van", name: "Other", passengerCapacity: 7 },
  { type: "pickup", name: "FAW Carrier", passengerCapacity: 4 },
  { type: "pickup", name: "Other", passengerCapacity: 4 },
  { type: "mpv", name: "FAW Sirius S80", passengerCapacity: 6 },
  { type: "mpv", name: "Other", passengerCapacity: 6 },
  { type: "suv", name: "FAW D60", passengerCapacity: 5 },
  { type: "suv", name: "FAW Besturn X40", passengerCapacity: 5 },
  { type: "suv", name: "Other", passengerCapacity: 5 },
  { type: "commercial", name: "FAW Tiger V", passengerCapacity: 0 },
  { type: "suv", name: "FAW R7", passengerCapacity: 0 },
];
export const Renault = [
  { type: "suv", name: "Renault Duster", passengerCapacity: 5 },
  { type: "hatchback", name: "Renault Kwid", passengerCapacity: 4 },
  { type: "electric", name: "Renault Zoe", passengerCapacity: 0 },
  { type: "mpv", name: "Renault Triber", passengerCapacity: 7 },
];

export const Prince = [
  { type: "hatchback", name: "Prince Pearl", passengerCapacity: 4 },
  { type: "hatchback", name: "Other", passengerCapacity: 4 },
  { type: "suv", name: "Prince DFSK Glory 580", passengerCapacity: 7 },
  { type: "suv", name: "Other", passengerCapacity: 7 },
  { type: "van", name: "Prince K07", passengerCapacity: 7 },
];

export const United = [
  { type: "hatchback", name: "United Bravo", passengerCapacity: 4 },
  { type: "hatchback", name: "United Alpha", passengerCapacity: 4 },
];
export const MG = [
  { type: "suv", name: "MG HS", passengerCapacity: 5 },
  { type: "suv", name: "MG ZS", passengerCapacity: 5 },
  { type: "electric", name: "MG ZS EV", passengerCapacity: 5 },
  { type: "suv", name: "MG RX8", passengerCapacity: 7 },
];
