import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextField, MenuItem, makeStyles, Button } from "@material-ui/core";
import { carCompanies, vehicleColors, VEHICLE_MODEL } from "../../data/vehicle";
import {
  Changan,
  FAW,
  Suzuki,
  MG,
  Honda,
  Hyundai,
  Kia,
  Prince,
  Renault,
  Toyota,
  United,
} from "../../data/carNames";
import { COLORS } from "../../assets/colors";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    minHeight: "unset",
    maxHeight: 200,
  },
}));

function VehicleInformation({ submitAddVehicleForm, setIsOpen }) {
  const [state, setState] = useState({
    vehicleCompany: "",
    type: "",
    vehicleName: "",
    vehicleModel: "",
    vehicleCapacity: "",
    color: "",
    vehicleImage: "",
    fuelType: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (localStorage.getItem("carImgUrl")) {
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    // const capacity = loadCarNames(state.vehicleCompany).find(
    //   (item) => item.name == state.vehicleName
    // ).passengerCapacity;
    let data = {
      ...state,
      // vehicleCapacity: capacity,
    };
    submitAddVehicleForm(data);
  };

  function loadCarNames(carName) {
    switch (carName) {
      case "Toyota":
        return Toyota;

      case "Honda":
        return Honda;

      case "FAW":
        return FAW;

      case "Suzuki":
        return Suzuki;

      case "Renault":
        return Renault;

      case "Changan":
        return Changan;

      case "MG":
        return MG;

      case "Prince":
        return Prince;

      case "Hyundai":
        return Hyundai;

      case "United":
        return United;

      case "Kia":
        return Kia;

      default:
        return [];
    }
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    uploadVehicleImage(file);
  };
  const uploadVehicleImage = (image) => {
    setDisableSubmit(true);
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "silkcabs");
    data.append("cloud_name", "dqmvr7nnr");

    fetch("https://api.cloudinary.com/v1_1/dqmvr7nnr/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then(async (data) => {
        const { url, public_id } = data;
        localStorage.setItem("carImgUrl", url);
        localStorage.setItem("carImgPublicId", public_id);
        setState({
          ...state,
          vehicleImage: url,
        });
        setDisableSubmit(false);
      })
      .catch((err) => {
        setDisableSubmit(false);
      });
  };

  const handleDelete = async () => {
    localStorage.removeItem("carImgUrl");
    localStorage.removeItem("carImgPublicId");
    setState({
      ...state,
      vehicleImage: "",
    });
  };
  const renderVehicleTypes = (companyName) => {
    if (!companyName) return null;
    const vehicelTypes = [
      ...new Set(loadCarNames(companyName).map((item) => item.type)),
    ];
    return vehicelTypes.map((type) => <MenuItem value={type}>{type}</MenuItem>);
  };

  return (
    <MainContainer>
      <FormContainer>
        <form onSubmit={onSubmit}>
          <TextField
            label="Car Company"
            type="text"
            fullWidth
            required
            select
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.selectMenu,
                },
              },
            }}
            variant="outlined"
            value={state.vehicleCompany}
            onChange={(e) => {
              setState({
                ...state,
                vehicleCompany: e.target.value,
              });
            }}
          >
            {carCompanies.map((model) => (
              <MenuItem value={model}>{model}</MenuItem>
            ))}
          </TextField>
          <TextField
            label="Select your vehicle type"
            type="text"
            fullWidth
            variant="outlined"
            value={state.type}
            select
            required
            onChange={(e) =>
              setState({
                ...state,
                type: e.target.value,
              })
            }
          >
            {renderVehicleTypes(state.vehicleCompany)}
          </TextField>
          <TextField
            label="Vehicle Name"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={state.vehicleName}
            // select
            onChange={(e) => {
              const inputValue = e.target.value;
              setState({
                ...state,
                vehicleName: inputValue,
              });
            }}
          >
            {/* {renderCarNames(state.vehicleCompany)} */}
          </TextField>
          <TextField
            label="Vehicle Model"
            type="text"
            fullWidth
            variant="outlined"
            required
            select
            value={state.vehicleModel}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.selectMenu,
                },
              },
            }}
            onChange={(e) =>
              setState({
                ...state,
                vehicleModel: e.target.value,
              })
            }
          >
            {VEHICLE_MODEL.slice(0)
              .reverse()
              .map((model) => (
                <MenuItem value={model}>{model}</MenuItem>
              ))}
          </TextField>
          {/* <TextField label="Number Plate" fullWidth variant="outlined" /> */}
          {/* <TextField
            label="Fuel Type"
            type="text"
            fullWidth
            variant="outlined"
            required
            value={state.fuelType}
            select
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.selectMenu,
                },
              },
            }}
            onChange={(e) =>
              setState({
                ...state,
                fuelType: e.target.value,
              })
            }
          >
            {["Petrol", "Diesel", "CNG", "Electric"].map((fuelType) => (
              <MenuItem value={fuelType}>{fuelType}</MenuItem>
            ))}
          </TextField> */}

          <TextField
            label="Color"
            type="text"
            fullWidth
            variant="outlined"
            required
            value={state.color}
            select
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.selectMenu,
                },
              },
            }}
            onChange={(e) =>
              setState({
                ...state,
                color: e.target.value,
              })
            }
          >
            {vehicleColors.map((color) => (
              <MenuItem value={color}>
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    background: color,
                    marginRight: "1rem",
                    borderRadius: "1rem",
                  }}
                />
                <span>{color}</span>
              </MenuItem>
            ))}
          </TextField>

          {/* <TextField
            label="Upload car image (optional)"
            InputLabelProps={{
              shrink: true,
            }}
            type="file"
            fullWidth
            variant="outlined"
            accept="image/*"
            onChange={handleImageChange}
            inputProps={{
              accept: "image/*",
              id: "vehicle-image-upload",
            }}
          /> */}
          {state.vehicleImage && (
            <ImageWrapper>
              <img src={state.vehicleImage} height={"150px"} width={"auto"} />

              <Button
                variant="text"
                style={{
                  color: "red",
                  borderRadius: "16px",
                  marginTop: "0.5rem",
                }}
                onClick={handleDelete}
              >
                Delete Image
              </Button>
            </ImageWrapper>
          )}
          <ModalButtonContainer>
            <ModalButton disabled={disableSubmit} type="submit" label="ADD CAR">
              Submit
            </ModalButton>
            <ModalCancelButton
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              Cancel
            </ModalCancelButton>
          </ModalButtonContainer>
        </form>
      </FormContainer>
    </MainContainer>
  );
}

export default VehicleInformation;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const ImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  margin: 5px 0;

  img {
    border-radius: 4px;
    object-fit: contain;
  }
`;

const FormContainer = styled.div`
  border-radius: 6px;
  text-align: center;
  display: flex;
  outline: 0;
  max-height: 90vh;
  max-width: 546px;
  @media (max-width: 768px) {
    max-height: 70vh;
  }
  overflow-y: auto;
  form {
    @media (min-width: 768px) {
      min-width: 540px;
    }
  }
  form > .MuiTextField-root {
    text-align: center;
    color: white;
    margin-bottom: 5px;
    margin-top: 7px;
  }
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  // padding: 8px;
  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const ModalButton = styled.button`
  background-color: ${COLORS.primaryColor};
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #063d49;
  }
  :disabled {
    background-color: gray;
  }
`;

const ModalCancelButton = styled.button`
  background-color: #ddd;
  color: #333;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: ;
`;
